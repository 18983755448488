import { Icon } from '@blueprintjs/core';
import React from 'react';
import { sendRequest } from '../Request.js';
import Decycler from '@insight/common/decycler/decycler.js';
import setClassName from '../../helpers/setClassName.js';
import { CaseFilterType } from './CaseFilterType.js';
import { CaseFilter } from './CaseFilter.js';
import { CaseFilterSelectFieldDefinition, FieldType } from './CaseFilterFieldDefinition.js';
import { CaseFilterField } from './CaseFilterField.js';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';

export class CaseFilterEventTypes extends CaseFilter {
    data!: CaseFilter['data'] & {
        commonValues: string[] | undefined
    }

    constructor(name: string,
        description: string | undefined,
        type: CaseFilterType,
    ) {
        super(name, description, type);
    }

    initData() {
        super.initData();
        this.data = {
            ...this.data,
            commonValues: []
        }
        this.configureFields();
    }

    onGraphAssigned(): void {
        this.configureFields();
    }

    execute(eventsfile: string, graph: DotGraph) {
        const query = new URLSearchParams({
            eventsfile,
        });
        if (Array.isArray(this.fields['eventTypeNames'].value)) {
            const eventTypeIds = this.fields['eventTypeNames'].value.reduce<number[]>((ids, typeName) => {
                if (typeof typeName === "string") {
                    const tmp = graph.eventTypes.array.filter(et => et.name.includes(typeName)).map(et => et.id);
                    ids = ids.concat(tmp);
                }
                return ids;
            }, [])
            return sendRequest<number[], { [key: string]: unknown }>("case_event_types_filter?" + query.toString(), {
                method: "post",
                data: { eventTypeIds }
            })
        }
        else {
            return super.execute(eventsfile, graph);
        }
    }

    private configureFields() {
        Object.values(this.fields).forEach(field => {
            switch (field.fieldDefinitionCopy.id) {
                case "eventTypeNames": {
                    if (this.data.graph) {
                        const eventTypeNames = this.data.graph.eventTypes.array.map(et => et.name).filter(et => et.indexOf("&") < 0);
                        this.data.commonValues = eventTypeNames;
                    }
                    break;
                }
            }
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(field: CaseFilterField, value: string) {
        this.configureFields();
    }

    getOptions(field: CaseFilterField): string[] | undefined {
        let result;
        switch (field.fieldDefinitionCopy.id) {
            case "eventTypeNames":
                return this.data.commonValues?.sort();
                break;
        }
        return result;
    }

    label() {
        let msg = "!!Error in filter configuration";
        if (this.fields.eventTypeNames.value) {
            msg = `${this.fields.eventTypeNames}.`
        }
        return msg;
    }
}
setClassName(CaseFilterEventTypes, CaseFilterEventTypes.name); // for minifying purposes when using constructor.name
Decycler.registerSerializableType(CaseFilterEventTypes);

new CaseFilterType({
    id: "eventtypes",
    name: intl => intl.formatMessage({
        id: "filter.def.event-types.name",
        defaultMessage: "Eventtypes",
        description: "Name of of event types filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.event-types.description",
        defaultMessage: 'Cases anzeigen, die bestimmte Ereignisse enthalten',
        description: "Description of event types filter",
    }),
    fieldDefinitions: [
        new CaseFilterSelectFieldDefinition({
            id: "eventTypeNames",
            name: intl => intl.formatMessage({
                id: "filter.def.event-types.value",
                defaultMessage: "Selektion",
                description: "Label for set of values to select from",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: true,
            vector: true,
        }),
    ],
    childrenAllowed: false,
    icon: <Icon icon="filter" />,
    class: CaseFilterEventTypes
})