import {
    createMachine,
    interpret,
    assign,
} from 'xstate';
import log from '../components/Logger.js'
import numeral from "numeral";

import de from '../assets/lang/de.json';
import en from '../assets/lang/en.json';

export const messages = {
    'de': de,
    'en': en,
}

const defaultLocale: "de" | "en" = navigator.language === "de-DE" ? "de" : "en"

const machine = createMachine(
    {
        predictableActionArguments: true,
        id: 'localization',
        initial: 'selected',
        context: { locale: defaultLocale },
        states: {
            selecting: {
                invoke: {
                    src: 'switchLanguage',
                    onDone: {
                        target: 'selected',
                        cond: 'checkChange',
                        actions: assign({
                            locale: (_, event) => {
                                numeral.locale(event.data.locale as string)
                                return event.data.locale;
                            }
                        })
                    },
                }
            },
            selected: {
                on: {
                    select: 'selecting'
                }
            }
        },
    },
    {
        guards: {
            checkChange: (context, event) => {
                return event.data.locale !== context.locale;
            }
        },
        services: {
            /** @todo: switch language on server as well */
            switchLanguage: (context, event) => {
                return Promise.resolve({ locale: event.locale });
            }
        }
    }
)

export const localizationService = interpret(machine).onTransition(state => {
    log.debug(`+++ localization: ${state.value}`);
}).start();
