import SortedArray from "collections/sorted-array.js";
import assert from "assert";
import moment from "moment";

import { EdgeCases } from "@insight/common/edge_cases/edge_cases.js";
import { RunTime } from "@insight/common/edge_cases/run_time.js";
import { EventGraph, EventGraphData } from "@insight/common/event_graph/eventgraph.js";
import { CaseInfo, CaseInfoData } from "@insight/common/CaseInfo/CaseInfo.js";
import { EdgeCase } from "@insight/common/edge_cases/edge_case.js";

import DotVertex from "./dotvertex.js";
import DotEdge from "./dotedge.js";
import { AttributeDefinition, AttributeDefinitionSerializableData } from "../CaseInfo/AttributeDefinition.js";
import { ISETypedCaseEvent } from "../ISECase/ISETypedCaseEvent.js";
import { ISEEventType } from "../ISECase/ISEEventType.js";
import { ISEEventCategory } from "../ISECase/ISEEventCategory.js";
import { ISEAttributes } from "../ISECase/ISEAttributes.js";
import { NonFunctionProperties } from "../@types/utilityTypes.js";
import { FINISH_TYPE_NAME, START_TYPE_NAME } from "../ISECase/ISEEventNames.js";

export interface SimplificationParameters {
    minVertexTraversals: number;
    minEdgeTraversals: number;
    singleFinish: boolean;
    removeEmptyElements: boolean;
}

interface PartialSimplificationParameters extends Partial<SimplificationParameters> { }

export type DotAttributes = {
    shape?: string;
    style?: string;
    fillcolor?: string;
    label?: string;
    color?: string;
    fontname?: string;
    penwidth?: number;
    tooltip?: string;
    weight?: number;
    URL?: string;
    fontsize?: number;
    fontcolor?: string,
};

export type Color = { [key in "r" | "g" | "b"]: number };
interface DotGraphParameters {
    minWeight: number;
    maxWeight: number;
    maxPenWidth: number;
    minPenWidth: number;
    redColor: Color;
    yellowColor: Color;
    greenColor: Color;
}

export interface PartialDotGraphParameters extends Partial<DotGraphParameters> { }

export interface DotGraphProperties {
    minEdgeTraversals: number;
    maxEdgeTraversals: number;
    minCaseDuration: number;
    maxCaseDuration: number;
    totCaseDuration: number;
    firstTimeStamp: number;
    lastTimeStamp: number;
    eventCount: number;
    minStarts: number; // the minimal number of outgoing edges from a start node
    maxStarts: number; // the maximal ...
    minFinish: number; // the ... if incoming edges to a finish node
    maxFinish: number; // the maximal ...
    minEdgeDurationBoundaries: number[]; // min/max of minEdgeDuration of all cases over all edges
    maxEdgeDurationBoundaries: number[];
    avgEdgeDurationBoundaries: number[];
}

export type AttributeDefinitions = Map<string, AttributeDefinition>;

export type DotGraphData = EventGraphData & {
    parameters: PartialDotGraphParameters,
    eventTypes: NonFunctionProperties<ISEEventType>[],
    casesInfo: CaseInfoData[]
    caseAttributeDefinitions: Record<string, AttributeDefinitionSerializableData>;
    eventAttributeDefinitions: Record<string, AttributeDefinitionSerializableData>;
};

function reportVertex(vertex: DotVertex) {
    return `${vertex.__id}/${vertex.eventId} => [${vertex.outgoing.array.map(e => e.to).map(v => `${v.__id}/${v.eventId}`).join(", ")}]`;
}
function reportCluster(cluster: DotVertex[]) {
    const msg = `*** ${reportVertex(cluster[0])}\n` +
        cluster.slice(1).map(v => "     " + reportVertex(v)).join("\n") + "\n\n";
    console.log(msg);
}

class DotGraph extends EventGraph<DotVertex, DotEdge> {

    static edgesEqual = DotEdge.equals;
    static compareEdges = DotEdge.compare;
    static createEdge = DotEdge.createFromData;

    static verticesEqual = DotVertex.equals;
    static compareVertices = DotVertex.compare;
    static createVertex = DotVertex.createFromData;

    static createFromData(data: unknown) {
        const graph: DotGraph = Object.create(DotGraph.prototype);
        graph.initFromData(data);
        return graph;
    }

    static validate(data: unknown): data is DotGraphData {
        return true; /** @todo: validate type */
    }

    parameters!: DotGraphParameters;
    eventTypes!: SortedArray<NonFunctionProperties<ISEEventType>>;
    casesInfo!: SortedArray<CaseInfo>;
    caseAttributeDefinitions!: AttributeDefinitions;
    eventAttributeDefinitions!: AttributeDefinitions;

    propsValid!: boolean;
    properties!: DotGraphProperties;
    showEdgeData!: boolean;
    showVertexData!: boolean;
    source: DotGraph | undefined;

    constructor(parameters: PartialDotGraphParameters = {}, centerIndex: number = 0) {
        super(centerIndex);
        this._initDotGraph(parameters); // call init of DotGraph, not of any class inherited from DotGraph
    }

    _initDotGraph(
        parameters: PartialDotGraphParameters,
        casesInfo: Array<CaseInfo> = [],
        eventTypes: Array<NonFunctionProperties<ISEEventType>> = [],
        caseAttributeDefinitions: AttributeDefinitions = new Map(),
        eventAttributeDefinitions: AttributeDefinitions = new Map()
    ) {
        this.parameters = {
            minWeight: 1,
            maxWeight: 10,
            maxPenWidth: 10,
            minPenWidth: 1,
            redColor: { r: 215, g: 0, b: 34 },
            yellowColor: { r: 239, g: 183, b: 0 },
            greenColor: { r: 0, g: 137, b: 113 },
            ...parameters, // important to _copy_ the parameters
        };

        this.propsValid = false;
        this.properties = this.getProperties();

        this.casesInfo = new SortedArray<CaseInfo>(
            casesInfo,
            (c1, c2) => c1.id === c2.id,
            (c1, c2) => c1.id - c2.id
        );
        this.eventTypes = new SortedArray<NonFunctionProperties<ISEEventType>>(
            eventTypes,
            (t1, t2) => {
                return t1.id === t2.id;
            },
            (t1, t2) => {
                return t1.id - t2.id;
            }
        );
        this.caseAttributeDefinitions = caseAttributeDefinitions;
        this.eventAttributeDefinitions = eventAttributeDefinitions;

        this.updateTraversals();
        this.updateProperties();
        this.showEdgeData = false;
        this.showVertexData = false;
    }

    __serializableObject(): DotGraphData {
        this.updateProperties();
        return {
            ...super.__serializableObject(),
            parameters: this.parameters,
            casesInfo: this.casesInfo.map((c) => c.__serializableObject()),
            eventTypes: this.eventTypes.array,
            caseAttributeDefinitions: Array.from(this.caseAttributeDefinitions)
                .reduce<Record<string, AttributeDefinitionSerializableData>>((obj, [key, value]) => {
                    obj[key] = value.__serializableObject();
                    return obj;
                }, {}),
            eventAttributeDefinitions: Array.from(this.eventAttributeDefinitions)
                .reduce<Record<string, AttributeDefinitionSerializableData>>((obj, [key, value]) => {
                    obj[key] = value.__serializableObject();
                    return obj;
                }, {})
        }
    }

    initFromData(data: unknown): data is DotGraphData {
        if (super.initFromData(data) && DotGraph.validate(data)) {
            const cases = data.casesInfo.map((jc) => CaseInfo.createFromData(jc));

            const caseAttributeDefinitions = new Map<string, AttributeDefinition>();
            Object.entries(data.caseAttributeDefinitions)
                .forEach(([name, cpd]: [string, unknown]) => caseAttributeDefinitions.set(name, AttributeDefinition.createFromData(cpd)));

            const eventAttributeDefinitions = new Map<string, AttributeDefinition>();
            Object.entries(data.eventAttributeDefinitions)
                .forEach(([name, cpd]: [string, unknown]) => eventAttributeDefinitions.set(name, AttributeDefinition.createFromData(cpd)));

            this._initDotGraph(data.parameters, cases, [...data.eventTypes], caseAttributeDefinitions, eventAttributeDefinitions);

            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    copy(copy: DotGraph | null = null) {
        const debug = false;
        let start: number;
        let time1: number;
        let time2: number;

        if (debug) {
            start = performance.now();
        }
        const parameters: DotGraphParameters = {
            ...this.parameters,
            // make sure to deep copy
            redColor: { ...this.parameters.redColor },
            yellowColor: { ...this.parameters.yellowColor },
            greenColor: { ...this.parameters.greenColor },
        };

        if (debug) time1 = performance.now();
        if (copy === null) copy = Object.create(this.constructor.prototype) as DotGraph;
        super.copy(copy);
        if (debug) time2 = performance.now();
        copy._initDotGraph(
            parameters,
            [...this.casesInfo.array],
            [...this.eventTypes.array],
            this.caseAttributeDefinitions,
            this.eventAttributeDefinitions
        ); // TODO deep clone parameter definitions
        if (debug) console.log(`\ninit: ${time1! - start!}\ncopy vertices & edges: ${time2! - time1!}`);
        copy.source = this;
        return copy;
    }

    addEventTypes(eventTypes: NonFunctionProperties<ISEEventType> | Array<NonFunctionProperties<ISEEventType>>) {
        if (!(eventTypes instanceof Array)) {
            eventTypes = [eventTypes];
        }
        eventTypes.forEach((et) => {
            this.eventTypes.add(et);
        });
    }

    addCaseInfo(caseInfo: CaseInfo) {
        this.casesInfo.add(caseInfo);
    }

    updateCaseAttributeDefinitions(caseAttributes: ISEAttributes): void {
        for (const [attributeName, attributeValue] of Object.entries(caseAttributes)) {
            if (!this.caseAttributeDefinitions.has(attributeName)) {
                this.caseAttributeDefinitions.set(attributeName, new AttributeDefinition(attributeValue));
            }
            const existingDefinition = this.caseAttributeDefinitions.get(attributeName)!;
            existingDefinition.update(attributeValue);
        }
    }

    updateEventAttributeDefinitions(_events: ISETypedCaseEvent[]): void {
        for (const _event of _events) {
            for (const [attributeName, attributeValue] of Object.entries(_event.attributes ?? {})) {
                if (!this.eventAttributeDefinitions.has(attributeName)) {
                    this.eventAttributeDefinitions.set(attributeName, new AttributeDefinition(attributeValue));
                }

                const existingDefinition = this.eventAttributeDefinitions.get(attributeName)!;
                existingDefinition.update(attributeValue);
            }
        }
    }

    removeCase(_case: CaseInfo) {
        this.casesInfo.delete(_case);
        /** @todo: check with Timo what to do with attribute definitions here */
    }

    getEdges(): SortedArray<DotEdge> {
        return this.edges as SortedArray<DotEdge>;
    }

    getVertices(): SortedArray<DotVertex> {
        return this.vertices as SortedArray<DotVertex>;
    }

    eventOccurances(eventId:number) {
        return this.vertices.array.filter(v => v.eventId === eventId).reduce((sum, v) => sum + v.traversals, 0);
    }

    toDot(attrs: DotAttributes = {}, options = {}) {
        let dot = "";
        this.getVertices().forEach((vertex) => {
            dot += vertex.toDot();
        });
        this.getEdges().forEach((edge) => {
            dot += edge.toDot(options);
        });
        type R = keyof DotAttributes;
        const attrLines =
            `${(Object.keys(attrs) as Array<R>).map<string>((key: R) => `${key}="${attrs[key]}";`).join("\n")}` + "\n";
        return "digraph G {\n" + attrLines + dot + "}\n";
    }

    /**
     * Transfer case load from edge1 to edge2 and remove edge1. Now, if any vertex of edge1
     * has no incoming and no outgoing edges any more, remove respective vertex.
     * @param edge1
     * @param edge2
     * @param step
     * @param debug
     */
    private transferLoad(edge1: DotEdge, edge2: DotEdge, step: number, debug = false) {
        if (debug)
            console.log(
                `*** ${step} transfer load ${edge1.cases
                    .map((c) => c.id)
                    .join(",")} of ${edge1.dotId()} to existing ${edge2.dotId()}`
            );

        edge1.cases.forEach((_case) => {
            edge2.cases.addCase(_case.id, _case.runtimes);
        });

        const to = edge1.to as DotVertex;
        const from = edge1.from as DotVertex;
        this.removeEdge(edge1);

        if (to.incoming.length === 0 && to.outgoing.length === 0) {
            if (debug) console.log(`  * remove vertex ${to.dotId()}`);
            this.removeVertex(to);
        }
        if (from.incoming.length === 0 && from.outgoing.length === 0) {
            if (debug) console.log(`  * remove vertex ${from.dotId()}`);
            this.removeVertex(from);
        }
    }

    /**
     * Set a new destination for an edge. If the new destination already has an incoming
     * edge from the source of the processed edge, transfer the case load of the edge to
     * that existing edge. Else, simply set the new destination on the edge. Then remove the
     * destination of the processed edge, if it does not have any incoming or outgoing
     * edges any more.
     * @param dest The new destination
     * @param edge The edge to be rerouted
     * @param step debugging
     * @param debug debugging
     */
    private setNewDestination(dest: DotVertex, edge: DotEdge, step: number, debug = false) {
        const existingEdge: DotEdge | undefined = dest.incoming.get({ from: edge.from, to: dest });
        if (existingEdge) {
            this.transferLoad(edge, existingEdge, step, debug);
        } else {
            if (debug) console.log(`*** ${step} set destination of ${edge.dotId()} to ${dest.dotId()}`);
            const to = edge.to;
            edge.setDestination(dest);
            if (to.incoming.length === 0 && to.outgoing.length === 0) {
                if (debug) console.log(`  * remove vertex ${to.dotId()}`);
                this.removeVertex(to);
            }
        }
    }

    /**
     * Set a new source for an edge. If the new source alreday has an outgoing edge
     * to the destination of the passed edge, transfer the case load of the edge to
     * the existing edge. Else, simply set the new source on the edge. Then remove the
     * source of the passed edge, if it does not have any incomfing or outgoing
     * edges any more.
     * @param source
     * @param edge
     * @param step
     * @param debug
     */
    private setNewSource(source: DotVertex, edge: DotEdge, step: number, debug = false) {
        const existingEdge: DotEdge | undefined = source.outgoing.get({ from: source, to: edge.to });
        if (existingEdge !== undefined) {
            this.transferLoad(edge, existingEdge, step, debug);
        } else {
            if (debug) console.log(`*** ${step} set source of ${edge.dotId()} to ${source.dotId()}`);
            const from = edge.from as DotVertex;
            edge.setSource(source);
            if (from.incoming.length === 0 && from.outgoing.length === 0) {
                if (debug) console.log(`  * remove vertex ${from.dotId()}`);
                this.removeVertex(from);
            }
        }
    }

    /**
     * Integrates vertices which have the same sucessors
     */
    integrate(parameters = { singleFinish: false }) {
        const debug = this.debug

        /**
         * 1a. Find vertices with only one outgoing edge pointing to a vertex of the same event
         * type but not to itself.
         */
        const selfReferencingVertices = this.getVertices().filter((vertex) => {
            let result;
            const outgoing = vertex.outgoing;
            if (outgoing.length === 1) {
                const target = outgoing.array[0].to;
                result = target.eventId === vertex.eventId && target !== vertex;
            } else {
                result = false;
            }
            return result;
        });

        /**
         * 1b. Transfer the incoming edges of such vertices to the target of the
         * single outgoing edge and add a self reference to that target.
         */
        while (selfReferencingVertices.length > 0) {
            const selfReferencingVertex = selfReferencingVertices.shift();

            /***
             * re-route incoming edges to the new vertex
             */
            const outgoing = selfReferencingVertex!.outgoing.array[0];
            const newDestination = outgoing.to as DotVertex;
            while (selfReferencingVertex!.incoming.length > 0) {
                const incomingToSelfReference = selfReferencingVertex!.incoming.array[0];
                this.setNewDestination(newDestination, incomingToSelfReference, 1, debug);
            }

            /**
             * if new vertex already has a self-reference,
             * add our payload to it. If not, create a self-
             * reference
             */
            this.setNewSource(newDestination, outgoing, 2, debug);
            if (debug) console.log(`*** removing self referencing vertex ${selfReferencingVertex!.dotId()}`);

            assert(selfReferencingVertex!.graph === null, "not yet removed from graph");
        }

        /**
         * 2. Get a list of unique vertex event ids
         */
        const vertexTypeIds = this.getVertices().reduce((ids, vertex) => {
            if (!ids.has(vertex.eventId)) {
                ids.add(vertex.eventId);
            }
            return ids;
        }, new SortedArray<number>());

        /**
         * 3. loop over all event vertex ids. Each id represents an event type. Build a list of vertices
         * of the same event type and sort them according to their number of outgoing edges, events with
         * higher number of outgoing edges come first.
         *
         * Take the first ("reference") vertex in an event type list and find vertices whose target vertices are all
         * included in the reference vertex. Put all these into a cluster until no more clusters can be found.
         */
        const clusters: Array<Array<DotVertex>> = [];
        vertexTypeIds.forEach((vertexTypeId) => {

            /**
             * get all vertices of this event type
             */
            const eventVerticesOfType = this.getVertices()
                .filter((vertex) => {
                    return vertex.eventId === vertexTypeId && (vertex.outgoing.length > 0 || parameters.singleFinish);
                })
                .slice(); // get a copy(!) of vertices of event type

            if (eventVerticesOfType.length) {

                /**
                 * Sort such that vertices with smaller amount of outgoing edges are
                 * processed at the end. This way, we assure to get cluster reference nodes
                 * with a maximum of outgoing edges.
                 */
                eventVerticesOfType.sort((v1, v2) => v2.outgoing.length - v1.outgoing.length);

                /**
                 * Now, take the first event of type as a reference and find others with the
                 * same destinations (of different type than themselves, i.e. ignoring self-
                 * references to the same type)
                 */
                do {
                    const referenceVertex = eventVerticesOfType.shift();
                    const cluster: Array<DotVertex> = [referenceVertex!];

                    /**
                     * get the event ids of all destinations of the reference vertex except
                     * self self references
                     */
                    const referenceDestinations = referenceVertex!.outgoing
                        .map((edge) => edge.to.__id)
                        .filter((id) => id !== referenceVertex?.__id) // filter out self references
                        .sort(); // sort destination ids in order for easy comparison, see below
                    const referenceFanout = referenceDestinations.length;

                    /**
                     * Now find vertices in the list whose targets (again, except self references)
                     * are all included in the targets of the reference vertex
                     */
                    let idx = 0;
                    while (idx < eventVerticesOfType.length) {
                        const vertex = eventVerticesOfType[idx];

                        /**
                         * get destinations like above, i.e. which are not of the same event type
                         */
                        const vertexDestinations = vertex.outgoing
                            .map((edge) => edge.to.__id)
                            .filter((id) => id !== vertex?.__id) // filter out self references
                            .sort(); // sort destination ids in order for easy comparison, see below

                        /**
                         * check if destinations are equal. compare lengths, then the ids
                         */
                        let equal = false;
                        let included = false;
                        const vertexFanout = vertexDestinations.length;
                        if (vertexFanout == referenceFanout) {

                            /**
                             * check if referenceVertex and this vertex have the same destinations.
                             * for this to work, the destinations need to be sorted - see above
                             */
                            equal = referenceDestinations.every((id, i) => vertexDestinations[i] === id);

                        } else if (vertexFanout <= referenceFanout) {
                            /**
                             * check if the vertex is the only descendant of a start node and if all
                             * its destinations are included in the reference vertex's destinations.
                             * So first get the real incoming sources, i.e. all incoming vertices
                             * except self references.
                             */
                            const vertexSources = vertex.incoming
                                .map((edge) => edge.from)
                                .filter((s) => s.__id !== vertex.__id) // ignore self loops

                            /**
                             * check if it's just one incoming vertex, wether that is a start event
                             * and wether all destinations are included in the refererence vertex's
                             * destinations
                             */
                            included = vertexSources.length === 1
                                && this.eventTypes.get({ id: vertexSources[0].eventId })!.name === START_TYPE_NAME
                                && vertexDestinations.every((vDstId) => referenceDestinations.includes(vDstId))
                        }

                        if (equal || included) {

                            if (debug) console.log(`Integrate [${vertex.eventContext.join(', ')}] into [${referenceVertex?.eventContext.join(', ')}]`)

                            /** *move* vertex to cluster, so it is not assigend to any other cluster */
                            cluster.push(eventVerticesOfType.splice(idx, 1)[0]);
                            // idx++;

                            // the check described below has been made above, so not necessary here (I think) AH, 9.5.24

                            // /**
                            //  * check the outgoing edges are not the only incoming to their destinations.
                            //  * removing such edges would leave their target node alone.
                            // */
                            // const singleIncoming = !vertex.outgoing
                            //     .map(e => e.to) // destination nodes
                            //     .filter(v => v.__id !== vertex.__id) // no self loop
                            //     .every(v => {
                            //         return v.incoming.array.length > 1;
                            //     })

                            // if (!singleIncoming) {
                            //     console.log(`Integrate [${vertex.eventContext.join(', ')}] into [${referenceVertex?.eventContext.join(', ')}]`)
                            //     /** move vertex to cluster */
                            //     cluster.push(eventVerticesOfType.splice(idx, 1)[0]);
                            // }
                            // else {
                            //     idx++;
                            // }
                        }
                        else {
                            idx++;
                        }

                        // don't think the folling else is needed, need to loop around all event
                        // vertices of this type. This seems to be an old reminescent. AH, 9.5.24

                        // else {
                        //     /**  may come here if the reference vertex has self references */
                        //     break;
                        // }
                    }

                    /** store the cluster if there is more than just the reference node in int */
                    if (cluster.length > 1) {
                        clusters.push(cluster);
                    }

                } while (eventVerticesOfType.length > 0);
            }
        });

        /**
         * For each cluster, integrate all cluster vertices onto the master vertex,
         * except those which have destination vertices with only have one
         * incoming edge. We don't to mix-up those vertices with those, which have multiple
         * incoming edges.
         */
        clusters.forEach((cluster) => {
            if (debug) reportCluster(cluster);
            const newVertex: DotVertex = cluster.shift()!;
            cluster.forEach((vertex) => {

                /**
                 * check if there is a destination vertex which has this vertex
                 * as the only incoming and is not a finish node. In this case we won't
                 * integrate the cluster.
                 */
                const hasSingleEntryDestination = vertex.outgoing.array.find((e) => {
                    return e.to.incoming.length === 1 // vertex only incoming?
                        && e.to.outgoing.length > 0; // not a finish node
                });

                if (!hasSingleEntryDestination) {

                    /**
                     * transfer self referencing edges
                     */
                    vertex.outgoing.forEach(e => {
                        if (e.to.__id === vertex.__id) {
                            this.setNewSource(newVertex, e, 1, debug);
                            this.setNewDestination(newVertex, e, 2, debug);
                        }
                    })

                    /***
                     * re-route incoming edges to the new vertex. if an edge with the
                     * same source exists on the new vertex, transfer the edge load
                     * to that one. if not, simply change the destination of the edge.
                     */
                    while (vertex.incoming.length > 0) {
                        const e = vertex.incoming.array[0];
                        this.setNewDestination(newVertex, e, 3, debug);
                    }

                    /**
                     * transfer outgoing cases to outgoing edges of new vertex and
                     * remove outgoing edges. remember: the outgoing edges of the old
                     * vertex are a subset of / the same than the ones of the new vertex
                     * (that was the principle of clustering above - search nodes
                     * with same base id and same outgoing edges)
                     */
                    while (vertex.outgoing.length > 0) {
                        const e = vertex.outgoing.array[0];
                        /**
                         * if edge is a self referencing edge, add it as a self reference to the
                         * destination vertex
                         */
                        if (e.to.__id === vertex.__id) {
                            this.setNewSource(newVertex, e, 1, debug);
                            this.setNewDestination(newVertex, e, 2, debug);
                        }
                        else {
                            /**
                             * search linearly in the outgoing edges of the new vertex as the normal
                             * comparison function would not work for finish nodes as destination -
                             * their source id is special and is not the id of their predecessor in the flow.
                             */
                            const existingEdge = newVertex.outgoing.array.find((edge) => edge.to.__id === e.to.__id);
                            if (existingEdge) {
                                this.transferLoad(e, existingEdge!, 4, debug); // affects vertex.outgoing.length as the outgoing edges are transferred
                            }
                            else {
                                console.log(`Warning: non-existant edge in cluster`);
                                break;
                            }
                        }
                    }
                    assert(vertex.graph === null, `*** integrated vertex __id=${vertex.__id} not removed from graph.`);
                }
                else {
                    console.log(`Unexpected single entry.`)
                }
            });
        });
    }

    hideVertices(verticesToHide: Array<DotVertex>, removeEmptyElements = true) {
        const debug = false;
        while (verticesToHide.length) {

            const vertexToDelete = verticesToHide.shift()!;
            if (debug) console.log(`\n*** removing vertex ${vertexToDelete.dotId()}`);

            /**
             * remove any self-referencing edges
             */
            const selfrefs: Array<DotEdge> = [];
            vertexToDelete!.outgoing.forEach((out_edge) => {
                if (out_edge.from === out_edge.to) {
                    selfrefs.push(out_edge);
                }
            });
            selfrefs.forEach((selfref) => this.removeEdge(selfref));

            /**
             * now for each case of each incoming vertex, find the outflow of that case and
             * transfer both to an existing or a new edge, which connects the source of the
             * incoming edge and the target of the outgoing edge.
             */
            while (vertexToDelete.traversals > 0) {
                const in_edge = vertexToDelete.incoming.array.find(e => e.cases.length > 0);
                if (in_edge !== undefined) {
                    while (in_edge.cases.length > 0) {
                        const in_case: EdgeCase = in_edge.cases.array[0];
                        while (in_case.runtimes.length > 0) {

                            const inflow_run = in_case.runtimes[0];

                            /**
                             * Find the outgoing edge with the smallest run number for this case
                             * which is larger than the run number on the inflow edge. This will
                             * be the outflow to be assigned to a new edge.
                             */
                            let out_edge: DotEdge | undefined;
                            let out_case: EdgeCase | undefined;
                            let outflow_run_idx: number | undefined;
                            let minNo = 100000;

                            vertexToDelete.outgoing.forEach(
                                (out_edge_candidate: DotEdge) => {
                                    const out_flow_case_candidate = out_edge_candidate.cases.get(in_case);
                                    if (out_flow_case_candidate) {
                                        out_flow_case_candidate.runtimes.forEach(
                                            (outflow_run_candidate: RunTime, out_idx: number) => {
                                                if (
                                                    outflow_run_candidate.no > inflow_run.no &&
                                                    outflow_run_candidate.no < minNo
                                                ) {
                                                    out_edge = out_edge_candidate;
                                                    out_case = out_flow_case_candidate!;
                                                    outflow_run_idx = out_idx;
                                                    minNo = outflow_run_candidate.no;
                                                }
                                            }
                                        );
                                    }
                                }
                            );

                            if (out_edge !== undefined && out_case !== undefined && outflow_run_idx !== undefined) {
                                const outflow_run = out_case.runtimes[outflow_run_idx];

                                /**
                                 * Find an edge in existing edges to transfer the flow to. If that does not exist,
                                 * create a new edge
                                 */
                                let edge = this.getEdges().get({ from: in_edge.from, to: out_edge.to });
                                if (!edge) {
                                    edge = new DotEdge(in_edge.from as DotVertex, out_edge.to as DotVertex, {});
                                    edge.setGraph(in_edge.graph);
                                    this.addEdge(edge);
                                    if (debug)
                                        console.log(
                                            `  * replacing edges ${in_edge.dotId()} / ${out_edge.dotId()} with new edge ${edge.dotId()}`
                                        );
                                } else {
                                    if (debug)
                                        console.log(
                                            `  * integrating edges ${in_edge.dotId()} / ${out_edge.dotId()} into ${edge.dotId()}`
                                        );
                                }
                                const no = inflow_run.no;
                                const time = inflow_run.ms + outflow_run.ms;
                                edge.cases.addCase(in_case.id, [new RunTime(no, time)]);

                                /**
                                 * remove run from old outgoing edge, edge itself will be delete just before vertex deletion (see below)
                                 */
                                out_edge.cases.removeRun(out_case, outflow_run_idx);
                            } else {
                                if (vertexToDelete.outgoing.length > 0) {
                                    throw new Error(
                                        `Inflowing case ${in_case.id} on ${in_edge.dotId()} does not come out.`
                                    );
                                }
                            }

                            in_edge.cases.removeRun(in_case, 0); // remove first run from old incoming edge
                        }
                    }
                    assert(in_edge.cases.traversals === 0, `Incoming traverals where not removed from ${in_edge.dotId()}`);
                    if (removeEmptyElements) this.removeEdge(in_edge);
                    vertexToDelete.updateTraversals();
                }
                else {
                    throw new Error("in_edge expeected.")
                }
            }

            if (removeEmptyElements) {
                while (vertexToDelete.outgoing.length > 0) {
                    /** @ptype {DotEdge} */
                    const out_edge = vertexToDelete.outgoing.array[0];
                    assert(
                        out_edge.cases.traversals === 0,
                        `Outgoing traversals where not removed from ${out_edge.dotId()}`
                    );
                    this.removeEdge(out_edge);
                }
                this.removeVertex(vertexToDelete);
            }
        }
        this.updateTraversals();
    }

    /**
     * simplify: elminate nodes below a certain traversal threshold but keep their edge loads
     * @param {*} parameters
     */
    simplify(partialParameters: PartialSimplificationParameters = {}) {
        // const debug = false;

        const parameters: SimplificationParameters = {
            minVertexTraversals: 1,
            minEdgeTraversals: 1,
            singleFinish: false,
            removeEmptyElements: true,
            ...partialParameters,
        };

        /*-----------------------------------------------------------------------------------------
          1. Remove vertices which do not reach the minimal number of traversals. The incoming and
          outgoing case flows will be integrated and either added to existing edges or new edges
          will be created.
          ----------------------------------------------------------------------------------------- */

        /**
         * Find vertices below traversal threshold
         */
        this.updateTraversals();
        const verticesToBeDeleted = [...this.getVertices().array].filter(
            (v1) => v1.traversals < parameters.minVertexTraversals && v1.incoming.length > 0 && v1.outgoing.length > 0
        );
        this.hideVertices(verticesToBeDeleted, parameters.removeEmptyElements);

        /* ----------------------------------------------------------------------------------------
           2. Find edges below traversal threshold. Remove all cases of those edges from the graph.
           Remove edges which have no cases anymore
           ---------------------------------------------------------------------------------------- */

        let lowTrafficEdges = [...this.getEdges().array].filter((e) => {
            return e.cases.traversals < parameters.minEdgeTraversals;
        });

        while (lowTrafficEdges.length > 0) {
            const caseIds = lowTrafficEdges.reduce((ids: Set<number>, e) => {
                e.cases.forEach((c) => ids.add(c.id));
                return ids;
            }, new Set<number>());

            /**
             * loop over all edges of the graph and delete the cases which are
             * on low traffic edges
             */
            const edgesToDelete: Array<DotEdge> = [];
            this.getEdges().forEach((edge) => {
                /**
                 * loop cases of edge, collect those which sould be deleted
                 * and then delete them
                 */
                const casesToDelete: Array<EdgeCase> = [];
                edge.cases.forEach((c) => {
                    if (caseIds.has(c.id)) {
                        casesToDelete.push(c);
                    }
                });
                while (casesToDelete.length > 0) {
                    const edgeCase = casesToDelete.shift();
                    edge.cases.removeCase(edgeCase!);
                }

                /**
                 * mark edge for deletion, if no traversals any more.
                 */
                if (edge.cases.traversals == 0) {
                    edgesToDelete.push(edge);
                }
            });

            /** remove the cases from the graph */
            caseIds.forEach(caseId => {
                const _case = this.casesInfo.get({ id: caseId })
                if (_case) {
                    this.removeCase(_case)
                }
            })

            if (parameters.removeEmptyElements) {
                while (edgesToDelete.length > 0) {
                    const edge = edgesToDelete.shift();
                    this.removeEdge(edge!);
                }
            }

            lowTrafficEdges = [];
            // lowTrafficEdges = [...this.getEdges().array].filter((e) => {
            //     return e.cases.traversals < parameters.minEdgeTraversals;
            // });
        }

        if (parameters.removeEmptyElements) {
            const verticesToDelete: Array<DotVertex> = [];
            this.getVertices().forEach((vertex) => {
                if (vertex.incoming.length + vertex.outgoing.length === 0) {
                    verticesToDelete.push(vertex);
                }
            });
            verticesToDelete.forEach((vertex) => this.removeVertex(vertex));
        }

        return;
    }

    filterToCategory(categories: ISEEventCategory[]) {

        const verticesToHide = [...this.getVertices().array].filter((v) => {
            const eventType = this.eventTypes.get({ id: v.eventId });
            if (!eventType) throw new Error(`Undefined EventType`);
            return !categories.includes(eventType.cat) && eventType.name !== START_TYPE_NAME && eventType.name !== FINISH_TYPE_NAME;
        });
        this.hideVertices(verticesToHide);
        // this.integrate();
    }

    /**
     * Filter graph to contain only certain cases which are passed in an array.
     * @param {Number[]|SortedArray} caseIds
     */
    filterToCases(caseIds: Array<number> | SortedArray<number>) {
        if (!(caseIds instanceof SortedArray)) {
            assert(caseIds instanceof Array, "caseIds must be array if they are not a SortedArray");
            caseIds = new SortedArray<number>(
                caseIds,
                (id1, id2) => id1 === id2,
                (id1, id2) => id1 - id2
            );
        }

        const edgeRemovals: Array<DotEdge> = [];
        this.getEdges().forEach((edge) => {
            let i = 0;
            const cids = (caseIds as SortedArray<number>).array;
            let j = 0;
            const ecids = edge.cases.array;
            const ct = new EdgeCases();

            /**
             * o(m+n) algorithm instead of searching in binary tree each time
             */
            while (i < cids.length && j < ecids.length) {
                if (cids[i] < ecids[j].id) {
                    i++;
                } else if (cids[i] > ecids[j].id) {
                    j++;
                } else {
                    ct.add(ecids[j]);
                    i++;
                    j++;
                }
            }
            edge.cases = ct;
            if (edge.cases.traversals === 0) {
                edgeRemovals.push(edge);
            }
        });
        edgeRemovals.forEach((edge) => this.removeEdge(edge));

        const vertexRemovals: Array<DotVertex> = [];
        this.getVertices().forEach((vertex) => {
            if (vertex.incoming.length + vertex.outgoing.length === 0) {
                vertexRemovals.push(vertex);
            }
        });
        vertexRemovals.forEach((vertex) => this.removeVertex(vertex));

        /**
         * @todo: handle situations where a case id is not found (== undefined)
         * better and show a system error with reason
         */
        const cases: Array<CaseInfo> = caseIds.array.reduce<CaseInfo[]>((casesInfo, id) => {
            const c = this.casesInfo.get({ id });
            if (c !== undefined) casesInfo.push(c);
            return casesInfo;
        }, [])
        this.casesInfo = new SortedArray<CaseInfo>(
            cases,
            (c1, c2) => c1.id === c2.id,
            (c1, c2) => c1.id - c2.id
        );

        this.updateTraversals();
        this.updateProperties();
    }

    /**
     * calculate the number of traversals of each vertex
     */
    updateTraversals() {
        this.getVertices().forEach((vertex) => vertex.updateTraversals());
    }

    updateProperties() {
        /**
         * The minimum number and the maximum number of traversals of all edges in the graph
         */
        let minEdgeTraversals = 1000000;
        let maxEdgeTraversals = 0;

        /**
         * The minimum, maximum run time of cases and the total runtime of all cases
         */
        let minCaseDuration = Math.pow(10.0, 20);
        let maxCaseDuration = 0;
        let totCaseDuration = 0;

        const minEdgeDurationBoundaries = [Infinity, -Infinity];
        const maxEdgeDurationBoundaries = [Infinity, -Infinity];
        const avgEdgeDurationBoundaries = [Infinity, -Infinity];

        /**
         * the earliest and latest event time of this graph
         */
        let firstTimeStamp = moment("8.8.2054 23:59:59", "DD.MM.YYYY HH.mm.ss").toDate().getTime();
        let lastTimeStamp = moment("01.01.1970 00:00:00", "DD.MM.YYYY HH.mm.ss").toDate().getTime();

        /**
         * calculate min / max boundaries of this graph for traversals per edge, duration per edge and
         * average travel time per edge.
         */
        this.getEdges().forEach((edge) => {
            minEdgeTraversals = Math.min(minEdgeTraversals, edge.cases.traversals);
            maxEdgeTraversals = Math.max(maxEdgeTraversals, edge.cases.traversals);

            if (minEdgeDurationBoundaries[0] > edge.cases.minRunTime) {
                minEdgeDurationBoundaries[0] = edge.cases.minRunTime;
            }
            if (minEdgeDurationBoundaries[1] < edge.cases.minRunTime) {
                minEdgeDurationBoundaries[1] = edge.cases.minRunTime;
            }

            if (maxEdgeDurationBoundaries[0] > edge.cases.maxRunTime) {
                maxEdgeDurationBoundaries[0] = edge.cases.maxRunTime;
            }
            if (maxEdgeDurationBoundaries[1] < edge.cases.maxRunTime) {
                maxEdgeDurationBoundaries[1] = edge.cases.maxRunTime;
            }

            if (avgEdgeDurationBoundaries[0] > edge.cases.avgRunTime) {
                avgEdgeDurationBoundaries[0] = edge.cases.minRunTime;
            }
            if (avgEdgeDurationBoundaries[1] < edge.cases.avgRunTime) {
                avgEdgeDurationBoundaries[1] = edge.cases.avgRunTime;
            }
        });

        this.casesInfo.forEach((_case) => {
            const caseDuration = _case.finishTime - _case.startTime;
            minCaseDuration = Math.min(minCaseDuration, caseDuration);
            maxCaseDuration = Math.max(maxCaseDuration, caseDuration);
            totCaseDuration += caseDuration;
            firstTimeStamp = Math.min(firstTimeStamp, _case.startTime);
            lastTimeStamp = Math.max(lastTimeStamp, _case.finishTime);
        });

        let eventCount = 0;
        let minStarts = Infinity;
        let maxStarts = 0;
        let minFinish = Infinity;
        let maxFinish = 0;
        this.getVertices().forEach((vtx) => {
            const eventType = this.eventTypes.get({ id: vtx.eventId });
            if (eventType!.name === START_TYPE_NAME) {
                const outgoing = vtx.outgoing.array.reduce((sum, e) => (sum += e.cases.traversals), 0);
                // const outgoing = vtx.outgoing.length;
                if (outgoing < minStarts) minStarts = outgoing;
                if (outgoing > maxStarts) maxStarts = outgoing;
            }
            else if (eventType!.name === FINISH_TYPE_NAME) {
                // const incoming = vtx.incoming.length;
                const incoming = vtx.incoming.array.reduce<number>((sum: number, e: DotEdge) => (sum += e.cases.traversals), 0);
                if (incoming < minFinish) minFinish = incoming;
                if (incoming > maxFinish) maxFinish = incoming;
            }

            if (vtx.outgoing.length !== 0 && vtx.incoming.length !== 0) {
                eventCount += vtx.incoming.reduce((sum, edge) => {
                    sum += edge.cases.traversals;
                    return sum;
                }, 0);
            }
        });

        /**
         * assign properties to the graph
         */
        this.properties = {
            minEdgeTraversals,
            maxEdgeTraversals,
            minCaseDuration,
            maxCaseDuration,
            totCaseDuration,
            firstTimeStamp,
            lastTimeStamp,
            eventCount,
            minStarts,
            maxStarts,
            minFinish,
            maxFinish,
            minEdgeDurationBoundaries,
            maxEdgeDurationBoundaries,
            avgEdgeDurationBoundaries
        };
        this.propsValid = true;
    }

    getProperties() {
        let result: DotGraphProperties;
        if (this.propsValid) {
            result = this.properties;
        } else {
            result = {
                minEdgeTraversals: -1,
                maxEdgeTraversals: -1,
                minCaseDuration: -1,
                maxCaseDuration: -1,
                totCaseDuration: -1,
                firstTimeStamp: -1,
                lastTimeStamp: -1,
                eventCount: -1,
                minStarts: -1,
                maxStarts: -1,
                minFinish: -1,
                maxFinish: -1,
                minEdgeDurationBoundaries: [Math.pow(10, 20), 0],
                maxEdgeDurationBoundaries: [Math.pow(10, 20), 0],
                avgEdgeDurationBoundaries: [Math.pow(10, 20), 0]
            };
        }
        return result;
    }
}
export { DotVertex, DotEdge, DotGraph };
