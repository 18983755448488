import React from 'react'

import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom";

import { AuthProvider, Protector } from '../../components/authentication/json/AuthenticationProvider.js';
import Homepage, { HomePageProps } from '../../components/Homepage.js';
import MainEntrance from '../../components/MainEntrance.js';
import Close from '../../components/Base/Close.js';
import { IntlShape, useIntl } from 'react-intl';
import DataProvider from '../../classes/data_provision/DataProvider.js';
import authProviderService from '../../authentication/strategy/AuthProviderService.js';
import AzureAuthentificator from './azure/AzureAuthentificator.js';
import { AuthMethode } from '../../authentication/strategy/AuthMethode.js';


export interface BaseplateAuthStrategyComponentProviderProps extends React.JSX.IntrinsicAttributes {
    intl: IntlShape;
    dataProvider: DataProvider
}
export const BaseplateAuthStrategyComponentProvider = (props: BaseplateAuthStrategyComponentProviderProps) => {

    const authStrategy = authProviderService.getAuthStrategy();
    const intl = props.intl;
    const dataProvider = props.dataProvider;


    return (<>
        {authProviderService.isAzureStrategy() ?
        (
            <AzureAuthentificator>
                <Homepage intl={intl} dataProvider={dataProvider} />
            </AzureAuthentificator>
        ) :  (
            <AuthProvider loginComponent={MainEntrance}>
                <Router >
                    <Routes>
                        <Route path="/close" element={<Close intl={intl} />} />
                        <Route path="/" element={<Protector<HomePageProps> component={Homepage} props={{ intl, dataProvider }} />} />
                        <Route element={<Navigate to="/" />} />
                    </Routes>
                </Router>
            </AuthProvider> 
        )}
    </>)
}
