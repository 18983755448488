import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Card, H5, RadioGroup, Radio, Position, Button, Classes, Checkbox, Popover } from '@blueprintjs/core';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';
import format_duration from '@insight/common/format_duration.js';

export interface GraphInfoProps {
    graph: DotGraph;
    handleModeChange: (event: React.FormEvent<HTMLInputElement>) => void;
    handleEdgeDataDisplay: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleVertexDataDisplay: (event: React.ChangeEvent<HTMLInputElement>) => void;
    mode: 'avg' | 'min' | 'max' | 'none';
}

export function GraphInfo(props: GraphInfoProps) {
    const intl = useIntl();

    function makeRadioLabel(label: string, duration: number | null = null) {
        return <><span className="radio-lbl">{label}{duration !== null? ": "+format_duration(duration):""}</span></>
    }
    const graph = props.graph;

    const minLabel = makeRadioLabel(
        intl.formatMessage({
            id: "graphinfo.min",
            defaultMessage: "min",
            description: "Title for an option lable to activate the display of minimum process durations for each process activity edge."
        }),
        graph.properties.minCaseDuration);

    const avgLabel = makeRadioLabel(
        intl.formatMessage({
            id: "graphinfo.avg",
            defaultMessage: "avg",
            description: "Title for an option lable to activate the display of average process durations for each process activity edge."
        }),
        graph.properties.totCaseDuration / graph.casesInfo.length);

    const maxLabel = makeRadioLabel(
        intl.formatMessage({
            id: "graphinfo.max",
            defaultMessage: "max",
            description: "Title for an option lable to activate the display of maximum process durations for each process activity edge."
        }),
        graph.properties.maxCaseDuration);

    const noLabel = makeRadioLabel(
        intl.formatMessage({
            id: "graphinfo.none",
            defaultMessage: "none",
            description: "Title for an option label to show now time information."
        }));

    const fmt = new Intl.DateTimeFormat('de-DE', {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
    });

    return <Popover
        className="graph-info-pop"
        hasBackdrop={true} // needed because clicks on the SVG to close the dialog do not get bubbled to the HTML
        content={<Card className="graph-info" elevation={3}>
            {props.graph
                ? <>
                    <H5><FormattedMessage
                        id="graphinfo.title"
                        defaultMessage="Processes Overview"
                        description="Title for a popover which shows some basic data for the processes loaded."
                    /></H5>

                    <p><FormattedMessage
                        id="graphinfo.cases"
                        defaultMessage="Cases"
                        description="Title for number of process instances (aka 'cases')."
                    />: {graph.casesInfo.length}</p>

                    <p><FormattedMessage
                        id="graphinfo.nodes"
                        defaultMessage="Event nodes"
                        description="Title for number of event nodes."
                    />: {graph.vertices.length}</p>

                    <p><FormattedMessage
                        id="graphinfo.activities"
                        defaultMessage="Activity edges"
                        description="Title for number of activity edges."
                    />: {graph.edges.length}</p>

                    <p><span className="time-lbl">Start:</span>{fmt.format(new Date(graph.properties.firstTimeStamp))}</p>
                    <p><span className="time-lbl">End:</span>{fmt.format(new Date(graph.properties.lastTimeStamp))}</p>

                    <H5 style={{ marginTop: "15px" }}><FormattedMessage
                        id="graphinfo.options"
                        defaultMessage="Options"
                        description="Title for a display of options."
                    /></H5>

                    <Checkbox
                        checked={props.graph.showEdgeData}
                        onChange={props.handleEdgeDataDisplay}
                        label={intl.formatMessage({
                            id: "graphinfo.edge_data",
                            defaultMessage: "Show data of activities.",
                            description: "Option label to show quantitative data on graph."
                        })}
                    />

                    <RadioGroup
                        className="bp5-radio-group"
                        label={intl.formatMessage({
                            id: "graphinfo.duration",
                            defaultMessage: "Duration",
                            description: "Title for a radio group to select min/max/avg duration display."
                        }) + ":"}
                        onChange={props.handleModeChange}
                        selectedValue={props.mode}
                        disabled={!props.graph.showEdgeData}
                    >
                        <Radio labelElement={minLabel} value="min" />
                        <Radio labelElement={avgLabel} value="avg" />
                        <Radio labelElement={maxLabel} value="max" />
                        <Radio labelElement={noLabel} value="none" />
                    </RadioGroup>

                    <Checkbox
                        checked={props.graph.showVertexData}
                        onChange={props.handleVertexDataDisplay}
                        label={intl.formatMessage({
                            id: "graphinfo.vertex_data",
                            defaultMessage: "Show data of events.",
                            description: "Option label to show quantitative data on graph."
                        })}
                    />
                </>
                : <FormattedMessage
                    id="graphinfo.nodata"
                    defaultMessage="No data availabele."
                    description="A message that says that no data is available because no process graph was loaded."
                />
            }
        </Card>}
        modifiers={{ arrow: { enabled: false } }}
        position={Position.TOP_LEFT}
    >
        <Button className={Classes.MINIMAL}
            icon="info-sign"
            text={intl.formatMessage({
                id: "graphinfo.menu-button",
                defaultMessage: "Info",
                description: "Menu label for a popover which shows informations about loaded processes."
            })} />
    </Popover>
}
