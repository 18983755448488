import { AbstractAuthStrategy } from "./AbstractAuthStrategy.js";

export class NoneAuthStrategy extends AbstractAuthStrategy {


    login = () => {
    }

    logout = () => {
    }

    isAuthenticated(): boolean {
        return false;
    }
}