import { Icon } from '@blueprintjs/core';
import React from 'react';
import { sendRequest } from '../Request.js';
import Decycler from '@insight/common/decycler/decycler.js';
import setClassName from '../../helpers/setClassName.js';
import { CaseFilterType } from './CaseFilterType.js';
import { CaseFilter } from './CaseFilter.js';
import { CaseFilterChoicesFieldDefinition, CaseFilterSelectFieldDefinition, FieldType } from './CaseFilterFieldDefinition.js';
import { CaseFilterField } from './CaseFilterField.js';

export class CaseFilterCaseAttribute extends CaseFilter {
    data!: CaseFilter['data'] & {
        operators: string[] | undefined;
        commonValues: string[] | undefined;
    }

    constructor(name: string,
        description: string | undefined,
        type: CaseFilterType,
    ) {
        super(name, description, type);
    }

    initData() {
        super.initData();
        this.data = {
            ...this.data,
            operators: [],
            commonValues: []
        }
        this.configureFields();
    }

    onGraphAssigned(): void {
        this.configureFields();
    }

    execute(eventsfile: string) {
        const query = new URLSearchParams({
            eventsfile,
        });
        return sendRequest<number[], { [key: string]: unknown }>("case_attribute_filter?" + query.toString(), {
            method: "post",
            data: this.fieldValues()
        })
    }

    private configureFields() {
        Object.values(this.fields).forEach(field => {
            switch (field.fieldDefinitionCopy.id) {
                case "attributeName": {
                    if (field.value !== undefined && typeof field.value === "string" && this.data.graph) {
                        const selectedAttribute = this.data.graph.caseAttributeDefinitions.get(field.value)
                        if (selectedAttribute) {
                            switch (selectedAttribute.data.datatype) {
                                case "string":
                                    this.data.operators = ["=", "in"];
                                    this.fields["operator"].fieldDefinitionCopy.visible = true;
                                    this.data.commonValues = selectedAttribute?.stringValues();
                                    this.fields["attributeValue"].fieldDefinitionCopy.type = FieldType.STRING;
                                    break;
                                case "number":
                                    this.data.operators = ["<", "=", ">"];
                                    this.fields["operator"].fieldDefinitionCopy.visible = true;
                                    this.data.commonValues = []
                                    this.fields["attributeValue"].fieldDefinitionCopy.type = FieldType.FLOAT;
                                    break;
                                default:
                                    this.fields["operator"].fieldDefinitionCopy.visible = false;
                                    this.data.operators = []
                                    this.fields["attributeValue"].fieldDefinitionCopy.type = FieldType.STRING;
                            }
                        }
                    }
                    break;
                }
                case "operator": {
                    if (field.value === "in") {
                        this.fields["attributeValue"].fieldDefinitionCopy.required = false;
                        this.fields["attributeValue"].fieldDefinitionCopy.visible = false;
                        this.fields["attributeArrayValue"].fieldDefinitionCopy.visible = true;
                        this.fields["attributeArrayValue"].fieldDefinitionCopy.required = true;
                    }
                    else {
                        this.fields["attributeValue"].fieldDefinitionCopy.visible = true;
                        this.fields["attributeValue"].fieldDefinitionCopy.required = true;
                        this.fields["attributeArrayValue"].fieldDefinitionCopy.visible = false;
                        this.fields["attributeArrayValue"].fieldDefinitionCopy.required = false;
                    }
                }
            }

        })
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(field: CaseFilterField, value: string) {
        this.configureFields();
    }

    getOptions(field: CaseFilterField): string[] | undefined {
        let result;
        switch (field.fieldDefinitionCopy.id) {

            case "attributeName":
                return this.data.graph !== undefined ? [...this.data.graph.caseAttributeDefinitions.keys()].sort() : []
                break;

            case "operator":
                return this.data.operators;
                break;

            case "attributeValue":
            case "attributeArrayValue":
                return this.data.commonValues?.sort();
                break;
        }
        return result;
    }

    label() {
        let msg = "!!Error in filter configuration";
        if (this.fields.attributeName && this.fields.operator) {
            if (this.fields.attributeValue.value) {
                msg = `${this.fields.attributeName} ${this.fields.operator} "${this.fields.attributeValue}"`
            }
            else if (this.fields.attributeArrayValue.value) {
                msg = `${this.fields.attributeName.value} ${this.fields.operator} ${this.fields.attributeArrayValue}.`
            }
        }
        return msg;
    }
}
setClassName(CaseFilterCaseAttribute, CaseFilterCaseAttribute.name); // for minifying purposes when using constructor.name
Decycler.registerSerializableType(CaseFilterCaseAttribute);

new CaseFilterType({
    id: "caseattribute",
    name: intl => intl.formatMessage({
        id: "filter.def.case-attribute.name",
        defaultMessage: "Case attributes",
        description: "Name of of case attributes filter.",
    }),
    description: intl => intl.formatMessage({
        id: "filter.def.case-attribute.description",
        defaultMessage: 'Cases anzeigen, auf die der Vergleich zutrifft',
        description: "Description of case filter",
    }),
    fieldDefinitions: [
        new CaseFilterChoicesFieldDefinition({
            id: "attributeName",
            name: intl => intl.formatMessage({
                id: "filter.def.case-attribute.attribute",
                defaultMessage: "Attribut",
                description: "Description select label for case attribute",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: true,
            vector: false,
        }),

        new CaseFilterChoicesFieldDefinition({
            id: "operator",
            name: intl => intl.formatMessage({
                id: "filter.def.case-attribute.operator",
                defaultMessage: "Operator",
                description: "Name of \"operator\" parameter",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: false,
            vector: false,
        }),

        new CaseFilterChoicesFieldDefinition({
            id: "attributeValue",
            name: intl => intl.formatMessage({
                id: "filter.def.case-attribute.value",
                defaultMessage: "Vergleichswert",
                description: "Name of reference value to compare to",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: false,
            vector: false,
        }),

        new CaseFilterSelectFieldDefinition({
            id: "attributeArrayValue",
            name: intl => intl.formatMessage({
                id: "filter.def.case-attribute-array.value",
                defaultMessage: "Selektion",
                description: "Label for set of values to select from",
            }),
            description: () => "",
            placeholder: () => "",
            type: FieldType.STRING,
            required: true,
            visible: false,
            vector: true,
        }),
    ],
    childrenAllowed: false,
    icon: <Icon icon="filter" />,
    class: CaseFilterCaseAttribute,
})