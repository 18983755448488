import { authService } from "../../components/authentication/json/AuthenticationStateMachine.js";
import { AbstractAuthStrategy } from "./AbstractAuthStrategy.js";

export class JsonAuthStrategy extends AbstractAuthStrategy {


    login = () => {
        // Not need for implemented because login is implemented in strategy specific component: MainEntrance.tsx
    }

    logout = () => {
        authService.send('LOG_OUT');
    }

    isAuthenticated(): boolean {
        return true;
    }
}