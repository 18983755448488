import { protectedResources } from "../../components/authentication/azure/AzureAuthConfig.js";
import { AzureService } from "../../components/authentication/azure/AzureService.js";
import { AbstractAuthStrategy } from "./AbstractAuthStrategy.js";

export class AzureAuthStrategy extends AbstractAuthStrategy {

    private azureService = new AzureService({
        scopes: protectedResources.apiPMT.scopes.write
    });

    constructor() {
        super();
        this.azureService.addAzureAuthRequestModifier();
    }


    login = () => {
        this.azureService.handleLoginRedirect();
    }

    logout = () => {
        this.azureService.handleLogoutRedirect();
    }

    isAuthenticated(): boolean {
        return this.azureService.isAuthenticated();
    }
}