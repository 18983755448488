import AttributedGraphElement, { GraphElementAttributes, AttributedGraphElementData } from "./attributedgraphelement.js";
import Vertex from "./vertex.js";
import Graph from "./graph.js";
import assert from "assert";

export type EdgeData = AttributedGraphElementData & { from: number, to: number }

export default class Edge extends AttributedGraphElement {

    /**
     * Two edges are equal if their sources and destinations are equal
     * @param {*} e1
     * @param {*} e2
     */
    static equals = (e1: Edge, e2: Edge) => {
        return e1.from.constructor.equals(e1.from, e2.from) && e1.from.constructor.equals(e1.to, e2.to);
    };

    static compare = (e1: Edge, e2: Edge) => {
        if (!e1.from.constructor.equals(e1.from, e2.from)) {
            return e1.from.constructor.compare(e1.from, e2.from);
        } else {
            return e1.from.constructor.compare(e1.to, e2.to);
        }
    };

    static createFromData(data: unknown, graph: Graph): Edge {
        const edge: Edge = Object.create(Edge.prototype);
        edge.setGraph(graph);
        edge.initFromData(data);
        return edge;
    }

    static validate(data: unknown): data is EdgeData {
        return AttributedGraphElement.validate(data) &&
            "from" in data &&
            "to" in data &&
            Number.isInteger(data.from) && Number.isInteger(data.to);
    }

    from!: Vertex;
    to!: Vertex;

    constructor(from: Vertex, to: Vertex, attributes: GraphElementAttributes = {}) {
        super(attributes);
        this._initEdge(from, to); // call #__init of Edge, not of any derived class;
    }

    _initEdge(from: Vertex, to: Vertex) {
        this.from = from;
        this.to = to;
    }

    initFromData(data: unknown): data is EdgeData {
        if (super.initFromData(data) &&
            Edge.validate(data)) {
            assert(this.graph, "Edge is missing an assigned graph.");
            const from: Vertex | undefined = this.graph.verticesById.get({ __id: data.from });
            if (!from) throw new Error(`Vertex with id ${data.from} not in vertices data.`);
            const to: Vertex | undefined = this.graph.verticesById.get({ __id: data.to });
            if (!to) throw new Error(`Vertex with id ${data.to} not in vertices data.`);
            this._initEdge(from, to);
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject(): EdgeData {
        return {
            ...super.__serializableObject(),
            from: this.from.getId(),
            to: this.to.getId(),
            attributes: this.attributes
        }
    }

    copy(copy: Edge) {
        assert(copy.graph, "Edge copy is missing an assigned graph.");

        super.copy(copy as AttributedGraphElement);

        const from = copy.graph.vertices.get(this.from);
        assert(from, `Vertex with id ${this.from.__id} not found in assigned graph.`)

        const to = copy.graph.vertices.get(this.to);
        assert(to, `Vertex with id ${this.to.__id} not found in assigned graph.`)

        copy._initEdge(from, to); // call #__init of Edge, not any derived class
        return copy;
    }

    setSource(vertex: Vertex) {
        assert(this.graph, `Edge ${this.__id} has no graph assigned.`);
        const graph = this.graph; /* copy graph, as it will be deleted from edge */
        graph.removeEdge(this);
        this.from = vertex;
        graph.addEdge(this);
    }

    setDestination(vertex: Vertex) {
        assert(this.graph, `Edge ${this.__id} has no graph assigned.`);
        const graph = this.graph; /* copy graph, as it will be deleted from edge */
        graph.removeEdge(this);
        this.to = vertex;
        graph.addEdge(this);
    }

}
