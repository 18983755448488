import React from "react";
import { FileInput, Button } from "@blueprintjs/core";
import classNames from "classnames";
import { sendRequest } from "../../classes/Request.js";

// drag drop file component
export function DragDropFile() {
    const defaultText = "...or drop one here";
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    const [inputText, setInputText] = React.useState(defaultText);

    // ref
    const inputRef = React.useRef<HTMLInputElement>(null);

    // handle drag events
    const handleDrag = function (e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        if ((e.type === "dragenter" || e.type === "dragover")
            && e.dataTransfer.items && e.dataTransfer.items.length === 1
            && e.dataTransfer.types && e.dataTransfer.types.length === 1 && e.dataTransfer.types[0] == "Files") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        handleFiles(e.dataTransfer.files);
    };

    // triggers when file is selected with click
    const handleChange = function (e: React.FormEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target instanceof HTMLInputElement) {
            handleFiles(e.target.files);
        }
    };

    const handleFiles = function (fl: FileList | null) {
        if (fl &&
            fl.length === 1 &&
            fl[0]) {
            setInputText(fl[0].name);
            if (inputRef.current !== null) {
                inputRef.current.files = fl;
            }
        }
        else {
            setInputText(defaultText)
        }
    }

    return (
        <div
            onDragEnter={handleDrag}
            onDragOver={handleDrag}
            onDragLeave={handleDrag}
            onDrop={handleDrop}
        >
            <FileInput
                className={classNames({
                    hoover: dragActive,
                    set: inputRef.current && inputRef.current?.files && inputRef.current?.files.length > 0
                })}
                inputProps={{ ref: inputRef, onChange: handleChange  }}
                fill={true}
                large={true}
                buttonText="Datei wählen"
                style={{ backgroundColor: dragActive ? "red" : "white" }}
                text={inputText}
            />
            <Button
                disabled={!inputRef || !inputRef.current || !inputRef.current?.files || inputRef.current?.files.length === 0}
                onClick={() => {
                    const data = new FormData();
                    if (inputRef.current?.files?.length) {
                        data.append('file', inputRef.current.files[0]);
                    }
                    sendRequest<unknown,FormData>('upload', {
                        method: "post",
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        data
                    }).then(res => {
                        console.log(res);
                    }).catch(err => {
                        console.log(err);
                    })
                }}
            >Absenden</Button>
        </div>
    );
}