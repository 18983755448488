import React, { PropsWithChildren } from 'react';
import { Overlay2, Classes, ProgressBar } from "@blueprintjs/core";
import classNames from "classnames";
import log from "./Logger.js";
import "@blueprintjs/core/lib/css/blueprint.css";

interface iProgressContext {
    progress: number;
    isOpen: boolean;
    label: string;
}

interface iSetProgessContext {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setProgress: React.Dispatch<React.SetStateAction<number>>;
    setLabel: React.Dispatch<React.SetStateAction<string>>;
}

export const ProgressContext = React.createContext<iProgressContext | null>(null);
export const SetProgressContext = React.createContext<iSetProgessContext | null>(null);

export const OverlayProvider: React.FC<PropsWithChildren<object>> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [label, setLabel] = React.useState("***");

    /**
     * memoize the SetProgressContext value so that the components subscribing to the progess setting
     * mehods don't need to redraw on each context update.
     */
    const value = React.useMemo(() => ({ setIsOpen, setProgress, setLabel }), [setIsOpen, setProgress, setLabel]);
    return (
        <SetProgressContext.Provider value={value}>
            <ProgressContext.Provider value={{ isOpen, progress, label, }}>
                {props.children}
            </ProgressContext.Provider>
        </SetProgressContext.Provider>
    );
};

const ProgressBarOverlay: React.FC = () => {
    const overlay = React.useContext(ProgressContext);

    if (!overlay) {
        return null;
    }

    const OVERLAY_EXAMPLE_CLASS = "progress-overlay";
    const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        OVERLAY_EXAMPLE_CLASS,
    );
    return (
        <Overlay2
            className={Classes.OVERLAY_SCROLL_CONTAINER}
            hasBackdrop={true}
            usePortal={true}
            isOpen={overlay.isOpen}
        >
            <div className={classes}>
                <div>{overlay.label}...</div>
                <ProgressBar intent="primary" value={overlay.progress} />
            </div>
        </Overlay2>
    );
}

export default ProgressBarOverlay;