import Graph from "./graph.js";
// type Primitive = string | number | boolean;
// export type Data = { [key: string]: (Primitive | Primitive[] | Data) };
export type GraphElementData = /* Data &*/ { ["__id"]: number };

export default class GraphElement {
    declare ["constructor"]: typeof GraphElement;

    static nextId: number = 2;

    static equals(e1: GraphElement, e2: GraphElement) {
        return e1 === null ? e2 === null : e2 === null ? false : e1.__id === e2.__id;
    }

    static compare(e1: GraphElement, e2: GraphElement): number {
        return e1 === null
            ? e2 === null
                ? 0 // both null
                : -1 // v2 not null
            : e2 === null
                ? 1 // v1 not null, v2 null
                : e1.__id - e2.__id; // both not null
    }

    static getNextId() {
        return GraphElement.nextId++;
    }

    static validate(data: unknown): data is GraphElementData {
        return typeof data === "object" &&
            data !== null &&
            "__id" in data &&
            typeof data.__id === "number";
    }

    __id!: number;
    graph: Graph | null = null;

    constructor() {
        this._initGraphElement(GraphElement.getNextId());
    }

    _initGraphElement(id: number) {
        this.__id = id;
    }

    __serializableObject(): GraphElementData {
        return {
            __id: this.__id,
        };
    }

    initFromData(data: unknown): data is GraphElementData {
        if (GraphElement.validate(data)) {
            this._initGraphElement(data.__id);
            GraphElement.nextId = Math.max(data.__id + 1, GraphElement.nextId);
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`)
    }

    copy(copy: GraphElement) {
        copy.__id = this.__id;
        return copy;
    }

    getId() {
        return this.__id;
    }

    setGraph(graph: Graph) {
        this.graph = graph;
    }

    removeGraph() {
        this.graph = null;
    }
}
