import EventVertex, { EventContext, EventVertexData } from "@insight/common/event_graph/eventvertex.js";
import { GraphElementAttributes } from "@insight/common/graph/attributedgraphelement.js";
import stringBreak from "@insight/common/stringbreak/stringbreak.js";

import DotEdge from "./dotedge.js";
import { DotGraph, DotAttributes } from "./dotgraph.js";
import SortedArraySet from "collections/sorted-array-set.js";
import { FINISH_TYPE_NAME, START_TYPE_NAME } from "../ISECase/ISEEventNames.js";
import { colord, extend, Plugin } from "colord";
import mixPlugin from "colord/plugins/mix";
extend([mixPlugin as unknown as Plugin]);

type DotVertexData = EventVertexData & { traversals: number };

const maxPointSize = 26;
const minPointSize = 10;

export default class DotVertex extends EventVertex {
    static Edge = DotEdge;

    static createFromData(data: unknown, graph: DotGraph) {
        const vertex = Object.create(DotVertex.prototype);
        vertex.setGraph(graph);
        vertex.initFromData(data);
        return vertex;
    }

    static validate(data: unknown): data is DotVertexData {
        return true;
    }

    traversals!: number;
    _dotid!: string | null;

    declare outgoing: SortedArraySet<DotEdge>;
    declare incoming: SortedArraySet<DotEdge>;
    declare graph: DotGraph;

    constructor(eventTypeAncestry: EventContext, attributes: GraphElementAttributes = {}) {
        super(eventTypeAncestry, attributes);
        this._initDotVertex(0);
    }

    _initDotVertex(traversals: number) {
        this.traversals = traversals; // the original amount of traversals, without any edges removed
        this._dotid = null;
        return this;
    }

    initFromData(data: unknown): data is DotVertexData {
        if (super.initFromData(data) &&
            DotVertex.validate(data)) {
            this._initDotVertex(data.traversals);
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject() {
        return { ...super.__serializableObject(), traversals: this.traversals }
    }

    copy(copy: DotVertex) {
        super.copy(copy as EventVertex);
        copy._initDotVertex(this.traversals); // call #__init of DotVertex, not any derived class
        return copy;
    }

    dotId() {
        if (this._dotid === null) {
            this._dotid = "n" + this.eventId.toString() + "_" + this.__id;
        }
        return this._dotid;
    }

    updateTraversals() {
        const edges = (<SortedArraySet<DotEdge>>this.incoming).length ? this.incoming : this.outgoing;
        this.traversals = edges.reduce((sum, e: DotEdge) => (sum += e.cases.traversals), 0);
    }

    toDot() {
        const parameters = this.graph.parameters;

        /***
         * Edges might have been eliminated for simplification of the display after the
         * last update of traversals. Then here we have the opportunity to show the
         * exact differences.
         */
        const eventType = (this.graph! as DotGraph).eventTypes.get({ id: this.eventId });
        if (eventType === undefined) {
            throw new Error(`Didn't find Event Type`);
        }
        const traversals = this.traversals;
        const incoming = this.incoming.array.reduce<number>((sum: number, e: DotEdge) => (sum += e.cases.traversals), 0);
        const outgoing = this.outgoing.array.reduce((sum, e) => (sum += e.cases.traversals), 0);
        if (incoming !== 0 && outgoing !== 0) {
            console.assert(
                incoming === outgoing,
                `Incoming ${incoming} not equal to outgoing ${outgoing} traversals on vertex ${this.dotId()}`
            );
        }
        const label = eventType.name
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
        let attrs: DotAttributes;
        switch (label) {
            case START_TYPE_NAME: {
                /* determin font size by the load of the start node */
                const min = Math.min(this.graph.properties.minStarts, this.graph.properties.minFinish); // min begin/finish load in graph
                const max = Math.max(this.graph.properties.maxStarts, this.graph.properties.maxFinish); // max begin/finish load in graph
                const pointSize = max !== min // determine font size relative to min/max with own load
                    ? minPointSize + (maxPointSize - minPointSize) * (outgoing - min) / (max - min)
                    : minPointSize;
                const color = eventType.ft === "b" ? colord(parameters.greenColor).toHex() : colord(parameters.greenColor).desaturate(.7).lighten(.2).toHex()
                this.graph!.debug
                    ? attrs = {
                        shape: "box",
                        label: `<${stringBreak(label, 20, "<br/>")}` +
                            `<font point-size="${pointSize}"><br/>${incoming}<br/>[${this.eventContext
                                .map(
                                    (id, idx) => {
                                        return id.toString() + (idx === this.graph.contextCenterIndex ? "*" : '')
                                    }
                                ).join(', ')}]</font>>`,
                        style: "filled, rounded",
                        fillcolor: color,
                        URL: `/?__id=${this.__id}`,
                        tooltip: this.attributes['_info'] ? `Info: ${this.attributes['_info']}` : `${incoming}`
                    } :
                    attrs = {
                        label: `<${outgoing}>`,
                        fontcolor: `white`,
                        shape: eventType.ft === "b" ? "doublecircle" : "invhouse",
                        style: "filled",
                        fontsize: pointSize,
                        fillcolor: color,
                        color: color,
                    };
                break;
            }

            case FINISH_TYPE_NAME: {
                const min = Math.min(this.graph.properties.minStarts, this.graph.properties.minFinish); // min begin/finish load in graph
                const max = Math.max(this.graph.properties.maxStarts, this.graph.properties.maxFinish); // max begin/finish load in graph
                const pointSize = max !== min
                    ? minPointSize + (maxPointSize - minPointSize) * (incoming - min) / (max - min)
                    : minPointSize;
                    const color = eventType.ft === "f" ? colord(parameters.redColor).toHex() : colord(parameters.redColor).desaturate(.6).lighten(.1).toHex()
                    this.graph!.debug
                    ? attrs = {
                        shape: "box",
                        label: `<${stringBreak(label, 20, "<br/>")}` +
                            `<font point-size="10"><br/>${incoming}<br/>[${this.eventContext
                                .map(
                                    (id, idx) => {
                                        return id.toString() + (idx === this.graph.contextCenterIndex ? "*" : '')
                                    }
                                ).join(', ')}]</font>>`,
                        style: "filled, rounded",
                        fillcolor: color,
                        URL: `/?__id=${this.__id}`,
                        tooltip: this.attributes['_info'] ? `Info: ${this.attributes['_info']}` : `${incoming}`
                    } :
                    attrs = {
                        label: `<${incoming}>`,
                        fontcolor: `white`,
                        shape: eventType.ft === "f" ? "doublecircle" : "house",
                        style: "filled",
                        fontsize: pointSize,
                        fillcolor: color,
                        color: color
                    };
                break;
            }

            default: {
                const inchange = incoming - traversals;
                const outchange = outgoing - traversals;
                let fillcolor;
                switch (eventType.cat) {
                    case "m":
                        fillcolor = "#97cfff";
                        break;
                    case "i":
                        fillcolor = "#E0E0E0";
                        break;
                    case "meta":
                    case "aux":
                        fillcolor = "#FFBF97";
                        break;
                }
                attrs = {
                    color: "#E0E0E0",
                    shape: "box",
                    label: `<${stringBreak(label, 20, "<br/>")}` +
                        (this.graph!.debug
                            ? `<font point-size="10"><br/>${eventType.cat} / ${eventType.ft}<br/>${inchange} / ${incoming} / ${outchange}<br/>[${this.eventContext
                                .map(
                                    (id, idx) => {
                                        return id.toString() + (idx === this.graph.contextCenterIndex ? "*" : '')
                                    }
                                ).join(', ')}]</font>`
                            : (this.graph!.showVertexData && !this.graph!.debug)
                                ? `<br/><font point-size="10">(${incoming})</font>`
                                : ""
                        ) + ">",
                    style: "filled, rounded",
                    fillcolor: fillcolor || "white",
                    tooltip: this.attributes['_info'] ? `Info: ${this.attributes['_info']}` : `${incoming}`
                };
                break;
            }
        }
        attrs.URL = `/?__id=${this.__id}`;

        // don't show vertices with no load
        if (incoming === 0 && outgoing === 0) {
            attrs.style += ", invis";
        }

        // attrs.color = "#E0E0E0"; // border color
        attrs.fontname = "Arial";

        return `${this.dotId()} [${(Object.keys(attrs) as Array<keyof DotAttributes>)
            .map<string>((key) => {
                const value = attrs[key];
                if (typeof value === "string" && value.startsWith("<")) {
                    return `${key}=${value}`;
                }
                else {
                    return `${key}="${attrs[key]}"`;
                }
            })
            .join(",")}]\n`;
    }
}
DotVertex.Edge = DotEdge;
