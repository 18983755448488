import React, { ReactNode } from 'react';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import AzureMsalErrorComponent from './../azure/AzureMsalErrorComponent.js';
import { loginRequest } from './AzureAuthConfig.js';

interface Props {
    children: ReactNode
}

const AzureAuthentificator = ({children}: Props) => {

    const authRequest = {
        ...loginRequest,
    };

    return (
        <>
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest}
                errorComponent={AzureMsalErrorComponent} >
                {children}
            </MsalAuthenticationTemplate>
            <UnauthenticatedTemplate>Bitte anmelden</UnauthenticatedTemplate>
        </>
    )
  }
  
  export default AzureAuthentificator