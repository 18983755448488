import { createMachine, interpret } from "xstate";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DisplayModeContext {}
export type DisplayModeEvent = { type: "SINGLE" } | { type: "DUAL" } | { type: "TOGGLE" };
export type DisplayModeState =
    | { value: "single"; context: DisplayModeContext }
    | { value: "dual"; context: DisplayModeContext };

const displayModeMachine = createMachine<DisplayModeContext,DisplayModeEvent, DisplayModeState>({
    predictableActionArguments: true,
    id: "displayModeMachine",
    initial: "single",
    context: {},
    states: {
        single: {
            on: {
                TOGGLE: "dual",
                DUAL: "dual",
            },
        },
        dual: {
            on: { TOGGLE: "single", SINGLE: "single" },
        },
    },
});

const displayModeService = interpret(displayModeMachine).onTransition((state) => {
    console.log(`+++ displayMode: ${state.value}`);
}).start();
export default displayModeService;
