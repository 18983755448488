import { ISEAttribute } from "../ISECase/ISEAttribute.js";

export type AttributeDefinitionSerializableData = {
    datatype: "string",
    range: Array<string>;
} | {
    datatype: "number"
    range: { min: number, max: number }
} | {
    datatype: "boolean"
} | {
    datatype: "Date"
    range: { min: Date, max: Date }
}

export type AttributeDefinitionData = {
    datatype: "string",
    range: Set<string>;
} | {
    datatype: "number"
    range: { min: number, max: number }
} | {
    datatype: "boolean"
} | {
    datatype: "Date"
    range: { min: Date, max: Date }
}

type DataTypes = "string" | "number" | "boolean" | "Date" | "none";

/**
 * defines a string our a number attribute and their value range
 */
export class AttributeDefinition {

    static createFromData(data: unknown) {
        const _attributeDefinition: AttributeDefinition = Object.create(AttributeDefinition.prototype);
        _attributeDefinition.initFromData(data);
        return _attributeDefinition;
    }

    static validate(data: unknown): data is AttributeDefinitionData {
        return (typeof data === "object" &&
            data !== null &&
            "datatype" in data &&
            typeof data.datatype === "string") &&
            (
                (data.datatype === "string" &&
                    "range" in data &&
                    Array.isArray(data.range) &&
                    (data.range.every(item => typeof item === "string")))
                || (data.datatype === "number" &&
                    "range" in data &&
                    typeof data.range === "object" &&
                    data.range !== null &&
                    "min" in data.range &&
                    typeof data.range.min === "number" &&
                    ("max" in data.range) &&
                    typeof data.range.max === "number"
                )
                || (data.datatype === "Date" &&
                    "range" in data &&
                    typeof data.range === "object" &&
                    data.range !== null &&
                    "min" in data.range &&
                    data.range.min instanceof Date &&
                    "max" in data.range &&
                    data.range.max instanceof Date
                )
                || (data.datatype === "boolean")
            );
    }

    static validateType(data: unknown): data is DataTypes {
        return typeof data === "string" && ["string", "number", "boolean", "Date"].includes(data);
    }

    data: AttributeDefinitionData;

    constructor(value: ISEAttribute) {
        const type = value instanceof Date
            ? "Date"
            : value === null
                ? "string"
                : typeof value;
        if (AttributeDefinition.validateType(type)) {
            switch (type) {
                case "string":
                    this.data = { datatype: "string", range: new Set<string>() }
                    break;
                case "number":
                    this.data = { datatype: "number", range: { min: 1E20, max: -1E20 } }
                    break;
                case "Date":
                    this.data = { datatype: "Date", range: { min: new Date(-1e15), max: new Date(1e15) } }
                    break;
                default:
                    this.data = { datatype: "boolean" }
            }
            if (value !== null) {
                this.update(value);
            }
        }
        else {
            throw new Error(`Unknown data type "${type}" of value "${value}"`);
        }
        return
    }

    __serializableObject(): AttributeDefinitionSerializableData {
        let result: AttributeDefinitionSerializableData
        switch (this.data.datatype) {
            case "string":
                result = { datatype: "string", range: Array.from(this.data.range.values()) }
                break;
            case "number":
            case "Date":
                result = this.data;
                break;
            default:
                result = { datatype: "boolean" }

        }
        return result;
    }

    initFromData(data: unknown): data is AttributeDefinitionData {
        if (AttributeDefinition.validate(data)) {
            this.data = data;
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    update(attributeValue: ISEAttribute) {
        if (typeof attributeValue === "string" &&
            this.data.datatype === "string") {
            this.data.range.add(attributeValue);
        }
        else if (typeof attributeValue === "number" &&
            this.data.datatype === "number") {
            if (attributeValue < this.data.range.min) {
                this.data.range.min = attributeValue;
            }
            else if (attributeValue > this.data.range.max) {
                this.data.range.max = attributeValue;
            }
        }
    }

    stringValues() {
        if (this.data.datatype === "string") {
            return Array.from(this.data.range.values())
        }
        else {
            return ["Not a string list"];
        }
    }
}