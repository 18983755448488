import { createMachine, interpret, assign } from "xstate";

import CaseFiltersController from "./CaseFiltersController.js";
import GraphController from "./GraphController.js";
import VariantsController from "./VariantsController.js";
import Project from "@insight/common/interface/Project.js";
import { dataSelectActor } from "../../components/View/DataSelectionStateMachine.js";

interface DataControllers {
    graphController: GraphController;
    variantsController: VariantsController;
    caseFiltersController: CaseFiltersController
}

/**
 * The data provision context provides access to the data controllers
 * for the graph, the variants and the case filters as well as the
 * loading progress, the project name and any error occured during
 * loading.
 */
export interface DataProvisionContext {
    dataControllers: DataControllers;
    progress: number;
    project?: Project;
    error?: Error;
}

export type DataProvisionEvent =
    | { type: "error", error: Error }
    | { type: "load", project: Project, setProgress: (progress: number) => void }
    | { type: "got_sizes" }
    | { type: "loaded", data: DataControllers }
    | { type: "close" }
    | { type: "confirm_error" }
    ;

/**
 * A state machine to store core data used by the client and
 * used by data controllers to receive and save data to the
 * server and to update local changes in the store.
 *
 * In hintsight, the implementation to update data seems a
 * bit "durch's Knie in die Brust", but what should I say. AH, 10.5.24
 */
export default class DataProvider {
    dataControllers: DataControllers
    totalSize: number;
    machine;
    service;

    /**
     * @param {DataController[]} dataControllers
     */
    constructor(dataControllers: DataControllers) {
        // register the data provider with the data controllers
        this.dataControllers = dataControllers;
        let key: keyof DataControllers;
        for (key in dataControllers) {
            const dc = dataControllers[key];
            dc.setDataProvider(this);
        }

        // create the state machine
        this.totalSize = 0;
        this.machine = createMachine(
            {
                /** @xstate-layout N4IgpgJg5mDOIC5QQIYBcUAUBOB7AbgJYRjYDEpe2A2gAwC6ioADrrIWobgHZMgAeiACwAmADQgAnogDMIgIwA6WkPkA2AKy0RM2vJEB2AJwGAvqYmoMOAsVKLuuAPpWUZADa4UEOoyQhWdk4ePkEEDQAOJQiDISMItQMIlS0DCWkEESMjRS1afKEhDWyImXlzS3QsPCISbEVPb0JuKCd2AC84MihcNDbCTthfPkCOLl5-MKN8xTkomRk1UTUZAzV0xCjc-IKdbKXaGQqQVxta+0aIZqgyZjwobDghhhG2MZDJ4SEZRXkDeRiQn+MiMuiMGwQ8nkGkUxj+Cg0IiE2hURwsJyqZzs9Uu1w8XhIPhe-lGwQmoDCkWisXiiWSRVoaSkiBE2kUSOy2QMtGS0MOx1ONWxDQJkDIAFdmFYwMMSW8yaFEAYtLkhGoYhFpmo1ComRlRLRFHEuasjADlQsBZihXURd4xbAUPgZcSWPLxoqEEsRLCojpYiIRBp9BDERFFNrtUZgxEgxE1VbrDaLqKIPjvLK3UEPZ8ENNDXN5AtvSs1qHA+y8n9oZEEmpE9VbLbLmKAMaeWAuvxZ97kgSIFbh6sRBLqf5RCEyOKKTnZDQgnURRENrG2x1EFq3e6PWDPbsBd0fCnCBbKaFqQNCZJT+Tg5kIZE-VSRgwyXnx+vowVN+zrvHryBMwPbMj37cItkBWkkhSRkIW+JRnzUaZvloNR1A0Fdk3qP9N0uIDSRzY9wOpOIEmghk9QHb5lB2fQLxBVZP0qJMf3qShcHIVseAAM0IbAAFsnHYmhXWA3tPUMH0r2+JJ9FUTlJ0KI11E0YFkh1IwhHMdFHBIeB-G-c5sFeEC+zCABadZ73MmFZzs2djEw1iHGcVwTPE3MiiUDRCiRDRIlLBZFKEGcVNWUpWRBQonKMu0rhafpBnchVc3kFQQrWDRNFoEwspKUNQUUJcdlBRFaC0LSv2tZzcRaZLCLAoocm0KcylZeIZI0CEFHDFR4QvZV4njCIYuFFsIHq0CwgMf4Z1WLRElWQwhHLH0aRMJdvnjS0qpY2KcKgSazOEQwiuKVZtDVC1Q3KiMXwUORoREbVRttYSjs9dCjW24wVGjcrSmC0Kow0ZU9D67TTCAA */
                predictableActionArguments: true,
                id: "dataProvider",
                tsTypes: {} as import("./DataProvider.typegen.d.ts").Typegen0,
                schema: {
                    context: {} as DataProvisionContext,
                    events: {} as DataProvisionEvent
                },
                context: {
                    dataControllers,
                    progress: -1,
                },
                initial: "no_data",
                on: {
                    error: {
                        target: "error",
                        actions: "setError",
                    },
                },
                states: {
                    no_data: {
                        on: {
                            load: {
                                target: "loading_sizes",
                            },
                        },
                    },
                    loading_sizes: {
                        entry: "load",
                        on: {
                            got_sizes: { target: "loading" },
                        },
                    },
                    loading: {
                        on: {
                            loaded: {
                                target: "loaded",
                                actions: "setData",
                            },
                        },
                    },
                    loaded: {
                        on: {
                            load: { target: "loading_sizes" },
                            close: { target: "no_data" },
                        },
                    },
                    error: {
                        on: {
                            /** resume operation after error confirmation */
                            confirm_error: "no_data",
                        },
                    },
                },
            },
            {
                guards: {},
                actions: {
                    load: assign((context, event) => {
                        this.load(event.project, event.setProgress); // @todo: get error handling right
                        return { project: event.project }
                    }),

                    setData: assign((context, event) => {
                        return { dataControllers: event.data };
                    }),

                    setError: assign({
                        error: (context, event) => event.error,
                    }),
                },
                services: {},
            }
        );

        this.service = interpret(this.machine).onTransition((state) => {
            console.log(`+++ dataProvider: ${state.value}`);
        });
        this.service.start();
    }

    async load(project: Project, setProgress: (progress: number) => void) {
        try {
            /** store filename to load for each data controller */
            this.dataControllers.caseFiltersController.setFilename(project.caseFiltersFilePath);
            this.dataControllers.variantsController.setFilename(project.variantsFilePath);
            this.dataControllers.graphController.setFilename(project.processFilePath);

            let filtersExist = false;
            let caseFiltersSize = 0;
            try {
                caseFiltersSize = await this.dataControllers.caseFiltersController.getLoadSize();
                filtersExist = true;
            } catch (err) {
                // filters are optional, ignore
                // console.log(err);
            }

            const variantsSize = await this.dataControllers.variantsController.getLoadSize();
            const graphSize = await this.dataControllers.graphController.getLoadSize();

            /** calculate total size to be expected */
            const totalSize = caseFiltersSize + variantsSize + graphSize;
            this.service.send("got_sizes");

            /** setup progress reporting */

            let totalReceived = 0;
            const progressCallback = (length: number) => {
                totalReceived += length;
                console.log(`Size: ${totalSize} Received: ${totalReceived}`);
                const progress = totalReceived / totalSize;
                setProgress(progress);
            };

            /** load all files in parallel */
            const promises = [
                this.dataControllers.variantsController.load(progressCallback),
                this.dataControllers.graphController.load(progressCallback),
            ]
            if (filtersExist) {
                promises.push(this.dataControllers.caseFiltersController.load(progressCallback))
            }
            else {
                this.dataControllers.caseFiltersController.data = [];
            }
            const results = await Promise.allSettled(promises)
            results.forEach(r => {
                if (r.status === "rejected") {
                    throw r.reason;
                }
            })

            dataSelectActor.send("update_graph", { graph: this.dataControllers.graphController.data });
            this.service.send("loaded", { data: this.dataControllers });
        } catch (error) {
            this.service.send("error", { error });
        }
    }
}
