import {
    createMachine,
    assign,
} from 'xstate';

/**
 * State machine for Grid component. As Grid is a class component, the interpretation and
 * start/stop management of the machine is part of the component
 */
export const maximizeWindowMachine = createMachine({
    predictableActionArguments: true,
    id: 'maximizeWindowMachine',
    tsTypes: {} as import("./GridStateMachine.typegen.d.ts").Typegen0,
    schema: {
        context: {} as {
            maximizedId: string | null;
        },
        events: {} as
            | { type: "toggle", cellId: string }
    },
    initial: 'normal',
    context: {
        maximizedId: null
    },
    states: {
        maximized: {
            on: {
                toggle: {
                    target: "normal",
                    actions: assign({ maximizedId: () => null })
                }
            }
        },
        normal: {
            on: {
                toggle: {
                    target: "maximized",
                    actions: assign({ maximizedId: (context, event) => event.cellId })
                }
            }
        }
    },
});