export default function stringBreak(str: string, length: number = 20, lineSeparator: string = "\n") {
    const strings: string[] = str.split(" ");
    const lines: string[] = [];
    let line: string = strings.splice(0,1)[0];
    for (const s of strings) {
        if ((line + s).length > length) {
            lines.push(line);
            line = s;
        }
        else {
            line += " " + s;
        }
    }
    lines.push(line);
    return lines.join(lineSeparator);
}
