import React, { useEffect, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useForm, SubmitHandler } from "react-hook-form"

import {
    InputGroup,
    Button,
    FormGroup,
    Intent,
    H2,
} from '@blueprintjs/core';
import { AuthenticationEvent, authService } from './authentication/json/AuthenticationStateMachine.js';
import { InvalidInput } from '@insight/common/interface/authentication.js';
import { useActor } from '@xstate/react';
import { LoginResponse } from '@insight/common/enums/LoginResponse.js';

export interface MainEntranceProps {
    intl: IntlShape;
    send: (event: AuthenticationEvent) => void;
}

interface ILoginFormInput {
    userid: string;
    password: string;
}

enum InputFieldError {
    NONE = "",
    USERNAME = "Please insert a username.", // TODO i18n localisation
    PASSWORD = "Please insert a password.",
    BOTH = "Username or password is wrong."
}

export default function MainEntrance(props: MainEntranceProps) {

    const intl = useIntl();
    const [state, ] = useActor(authService);
    const [inputFieldError, setInputFieldError] = useState(InputFieldError.NONE);

    useEffect(() => {
        let errorText = state.context.response?.data?.invalidInput._msg;
        if (Object.values(LoginResponse).includes(errorText as LoginResponse)) {
            switch (errorText as LoginResponse) {
                case LoginResponse.USERNAME_EMPTY:
                    setInputFieldError(InputFieldError.USERNAME);
                    break;
                case LoginResponse.PASSWORD_EMPTY:
                    setInputFieldError(InputFieldError.PASSWORD);
                    break;
                case LoginResponse.USERNAME_PASSWORD_WRONG:
                    setInputFieldError(InputFieldError.BOTH);
                    break;
                default:
                    setInputFieldError(InputFieldError.NONE);
                    break;
            }
        }
    }, [state]);

    console.log('render Mainentrance');

    const { register, handleSubmit } = useForm<ILoginFormInput>();
    const onSubmit: SubmitHandler<ILoginFormInput> = values => {
        props.send({
            type: 'CREDENTIALS_PROVIDED',
            userid: values.userid,
            pw: values.password,
        });
    }

    /**
     * register of react-hook-form delivers a ref, but for blueprintjs's
     * FormGroup we need the ref prop renamed to inputRef
     * @param s
     * @returns
     */
    const registerWithInputRef = (s: "userid" | "password") => {
        const { onChange, onBlur, name, ref: inputRef } = register(s);
        return { onChange, onBlur, name, inputRef }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <H2>
                <FormattedMessage
                    id="entrance.title"
                    defaultMessage="Anmelden"
                    description="Title for login box."
                    />
            </H2>

            <FormGroup
                helperText={inputFieldError === InputFieldError.USERNAME ? inputFieldError : ""}
                label={intl.formatMessage({
                    defaultMessage: "Emailaddress",
                    description: "Form label for email address field",
                })}
                intent={inputFieldError === InputFieldError.USERNAME ? Intent.DANGER : Intent.NONE}
                labelFor="userid"
                labelInfo={intl.formatMessage({
                    defaultMessage: "(required)",
                    description: "Info message for email address field"
                })}
            >
                <InputGroup
                    {...registerWithInputRef("userid")}
                    id="userid"
                    name="userid"
                    type="userid"
                    placeholder={intl.formatMessage({
                        defaultMessage: "Enter your userid",
                        description: "Placecolder in input for userid"
                    })}
                    intent={inputFieldError === InputFieldError.USERNAME ? Intent.DANGER : Intent.NONE}
                />
            </FormGroup>

            <FormGroup
                helperText={inputFieldError === InputFieldError.PASSWORD ? inputFieldError : ""}
                label="Password"
                labelFor="password"
                labelInfo="(required)"
                intent={inputFieldError === InputFieldError.PASSWORD ? Intent.DANGER : Intent.NONE}
            >
                <InputGroup
                    {...registerWithInputRef("password")}
                    id="password"
                    name="password"
                    type="password"
                    // onBlur={formik.handleChange}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Enter your password",
                        description: "Placecolder in input for password"
                    })}
                    intent={inputFieldError === InputFieldError.PASSWORD ? Intent.DANGER : Intent.NONE}
                />
            </FormGroup>
            <FormGroup
                helperText={inputFieldError === InputFieldError.BOTH ? inputFieldError : ""}
                intent={inputFieldError === InputFieldError.BOTH ? Intent.DANGER : Intent.NONE}
                >
            </FormGroup>
            <Button type="submit">Submit</Button>
        </form>
    )
}
