import React, { useState } from 'react'

import {
    Classes,
    Menu,
    MenuDivider,
    MenuItem,
} from '@blueprintjs/core';

import { DragDropFile } from '../../DragDropFile/DragDropFile.js';
import { IntlShape } from 'react-intl';

export interface MainDrawerContentProps extends React.JSX.IntrinsicAttributes {
    intl: IntlShape;
}

export const MainDrawerContent = (props: MainDrawerContentProps) => {

    const intl = props.intl;

    return (<>
        <DragDropFile />
        <Menu className={Classes.ELEVATION_3}>
            <MenuDivider title="Edit" />
            <MenuItem icon="cut" text="Cut" label="⌘X" />
            <MenuItem icon="duplicate" text={intl.formatMessage({ defaultMessage: "Copy", description: "Menu action to copy a value to the clipboard" })} label="⌘C" />
            <MenuItem icon="clipboard" text={intl.formatMessage({ defaultMessage: "Paste", description: "Menu action label to insert a value from the clipboard to the applicatoin" })} label="⌘V" disabled={true} />
            <MenuDivider title="Text" />
            <MenuItem disabled={false} icon="align-left" text="Alignment">
                <MenuItem icon="align-left" text="Left" />
                <MenuItem icon="align-center" text="Center" />
                <MenuItem icon="align-right" text="Right" />
                <MenuItem icon="align-justify" text="Justify" />
            </MenuItem>
            <MenuItem icon="style" text="Style">
                <MenuItem icon="bold" text="Bold" />
                <MenuItem icon="italic" text="Italic" />
                <MenuItem icon="underline" text="Underline" />
            </MenuItem>
            <MenuItem icon="asterisk" text="Miscellaneous">
                <MenuItem icon="badge" text="Badge" />
                <MenuItem icon="book" text="Long items will truncate when they reach max-width" />
                <MenuItem icon="more" text="Look in here for even more items">
                    <MenuItem icon="briefcase" text="Briefcase" />
                    <MenuItem icon="calculator" text="Calculator" />
                    <MenuItem icon="dollar" text="Dollar" />
                    <MenuItem icon="dot" text="Shapes">
                        <MenuItem icon="full-circle" text="Full circle" />
                        <MenuItem icon="heart" text="Heart" />
                        <MenuItem icon="ring" text="Ring" />
                        <MenuItem icon="square" text="Square" />
                    </MenuItem>
                </MenuItem>
            </MenuItem>
        </Menu>
    </>)
}
