
import { InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { loginRequest, protectedResources } from './AzureAuthConfig.js';

import { useIsAuthenticated } from "@azure/msal-react";
import { setRequestModificator } from '../../../classes/Request.js';

export class AzureService {

    msalInstance: {instance: any} = useMsal();

    constructor(msalRequest: any) {
        const { result, error: msalError } = useMsalAuthentication(InteractionType.Popup, {
            ...msalRequest,
            account: this.msalInstance.instance.getActiveAccount(),
            redirectUri: '/redirect'
        });
    }
    
    public aquireTokenSilent = async () => {
        const activeAccount = this.msalInstance.instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
        const accounts = this.msalInstance.instance.getAllAccounts();
        
        if (!activeAccount && accounts.length === 0) {
            /*
            * User is not signed in. Throw error or wait for user to login.
            * Do not attempt to log a user in outside of the context of MsalProvider
            */   
        }
        
        const request = {
            scopes: [...protectedResources.apiPMT.scopes.read, ...protectedResources.apiPMT.scopes.write],
            account: activeAccount || accounts[0]
        };
        
        const authResult = await this.msalInstance.instance.acquireTokenSilent(request);
        return authResult.accessToken; 
    };

    public addAzureAuthRequestModifier = () => {
        // removeRequestModificator("AzureAuthToken");
        setRequestModificator("AzureAuthToken", async (reqInit) => {
            let token = await this.aquireTokenSilent();
            reqInit.headers = {
                ...reqInit.headers,
                Authorization: "Bearer " + token,
            };
        });
    }

    public getActiveAccount() {
        if (this.msalInstance) {
            return this.msalInstance.instance.getActiveAccount();
        }
        return null;
    };

    public isAuthenticated = () => {
        return useIsAuthenticated();
    };

    public handleLoginPopup = () => {
        this.msalInstance.instance.loginPopup({
            ...loginRequest,
            redirectUri: '/redirect'
        }).then((response: any) => {
            console.log(response);
        }).catch((error: any) => console.log(error));
    }

    
    public handleLogoutPopup = () => {
        this.msalInstance.instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
            account: this.msalInstance.instance.getActiveAccount(),
        });
    }
    
    public handleLoginRedirect = () => {
        this.msalInstance.instance.loginRedirect(loginRequest)
            .catch((error: any) => console.log(error));
    };

    public handleLogoutRedirect() {
        this.msalInstance.instance.logoutRedirect({
            account: this.msalInstance.instance.getActiveAccount(),
        });
    };
};