import { createMachine, assign, interpret } from "xstate";
import { Variant } from "@insight/common/interface/iVariant.js";

export type VariantsTableContext = {
    variants: Variant[];
    selectedVariantsIndexes: number[];
    onSelect: (parameters: { caseIds: number[], controlId: string, controlState: unknown }) => void;
}

function getUnique<T>(elements: T[]) {
    return [...new Set<T>([...elements])];
}

type VariantsTableEvent =
    | { type: "init", ctx: VariantsTableContext }
    | { type: "clickRow", rowIdx: number, event: React.MouseEvent<HTMLElement> }
    | { type: "discardSelection" }
    | { type: "change_selection", data: number[] }
    | { type: "selectMostCommon" }
    ;
export const variantsTableStateMachine = createMachine(
    {
        /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOgHd1cAXAqAYgOoG0AGAXUVAAcB7Wa3D3ycQAD0QBWFixIBGACwsAbACYJAGhABPRPPkB2EgGZZaiQA4L88y3PmAvvc1oseQqQoD89TABtcmADWAEo8ZKwcSCC8-DRCIuIICjIAnErm+tYp+ipGRvIqmjoIKiopxhIpBSyVmSkpao7OGDgExOSUNN50ELiwmOgAThAAymC+YJhx+BEiMQLxUYnyRhIk5irmsrL6qyrpskpFiCossiSKqhtKsmfm2U0gLq3uHV4+2OjeYAD6sOOTaazKLzaYJRAAWlkEjW93MK3uEkODSMxwQRiURhItkqaVs1lsDicTxabnani69H+EymAFk+FQAMI8VCoITA7h8BbCJaQ+QXer1DYmJHbepoqRKEhSaTKTEw-QsfSOYn4HgQOAiZ5kohzLlg3kICGnLFwhEpJFKFFo7ZYlLKTb7W6ye5Wx7atoeTq0PWxQQ80CJaFHbSIDEqYymGGWeE2Owq+xAA */
        predictableActionArguments: true,
        tsTypes: {} as import("./VariantsTableStateMachine.typegen.d.ts").Typegen0,
        schema: {
            context: {} as VariantsTableContext,
            events: {} as VariantsTableEvent,
        },
        initial: "waiting",
        context: {variants:[],selectedVariantsIndexes:[],onSelect:()=>{return}},
        states: {
            waiting: {
                entry: ["clearSelection"],
                on: {
                    init: {
                        actions: ["setContext"],
                        // target: "waiting",
                    },
                    clickRow: {
                        actions: ["handleRowClick"],
                        // target: "waiting",
                    },
                    discardSelection: {
                        actions: ["clearSelection"],
                        // target: "waiting",
                    },
                    change_selection: {
                        // target: "waiting",
                        actions: assign((context, event) => {
                            const result = {
                                selectedVariantsIndexes: event.data ? event.data : []
                            }
                            return result;
                        })
                    },
                    selectMostCommon: {
                        actions: ["selectInitial"],
                    }
                },
            },
        },
    },
    {
        actions: {

            setContext: assign((context, event) => {
                return event.ctx
            }),

            handleRowClick: assign((context, event) => {
                let selectedVariantsIndexes = context.selectedVariantsIndexes;
                const iPos = selectedVariantsIndexes.indexOf(event.rowIdx);
                if (event.event?.ctrlKey) {
                    if (iPos >= 0) {
                        selectedVariantsIndexes.splice(iPos, 1);
                    } else {
                        selectedVariantsIndexes.push(event.rowIdx);
                    }
                }
                else if (event.event?.shiftKey && selectedVariantsIndexes.length !== 0) {
                    const minRowSelected = Math.min(...selectedVariantsIndexes, event.rowIdx);
                    const maxRowSelected = Math.max(...selectedVariantsIndexes, event.rowIdx);
                    selectedVariantsIndexes = [];
                    for (let r = minRowSelected; r <= maxRowSelected; r++) { selectedVariantsIndexes.push(r); }
                }
                else {
                    if (iPos >= 0) {
                        selectedVariantsIndexes = [];
                    }
                    else {
                        selectedVariantsIndexes = [event.rowIdx];
                    }
                }

                const caseIds: number[] = getUnique(selectedVariantsIndexes.flatMap(variantIdx => context.variants[variantIdx].cases));
                context.onSelect({ caseIds, controlId: "table", controlState: [...selectedVariantsIndexes] });
                return { selectedVariantsIndexes };
            }),

            clearSelection: (context, event) => {
                console.log({ context, event });
                console.log('*** clear Selection')
            },

            selectInitial: (context) => {
                console.log('*** select initial')
                const selectedVariantsIndexes = context.selectedVariantsIndexes;
                if (context.variants != undefined && context.variants.length > 0) {
                    selectedVariantsIndexes.push(0);
                    const caseIds: number[] = getUnique(selectedVariantsIndexes.flatMap(variantIdx => context.variants[variantIdx].cases));
                    context.onSelect({ caseIds, controlId: "table", controlState: [...selectedVariantsIndexes] });
                }
                return { selectedVariantsIndexes };
            }
        },
    }
)
export const variantsTableActor = interpret(variantsTableStateMachine).start();