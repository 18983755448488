import React, { useState } from "react";
import { initRequests } from "../../classes/Request.js";
import { Spinner } from "@blueprintjs/core";

export function Activity() {
    const [active, setActive] = useState(false);

    /**
     * pass the state setting function to the request module once
     */
    useState(() => {
        initRequests(setActive);
    })

    /**
     * render the spinner if acive
     */
    return <>
        {active &&
            <Spinner size={26} intent={"danger"} />
        }
    </>

}