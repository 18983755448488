import React, { createContext, useContext, useState } from "react";
import log from "./components/Logger.js";

import "@blueprintjs/core/lib/css/blueprint.css";
import "./assets/scss/App.scss";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./components/authentication/azure/AzureAuthConfig.js";
import { MsalProvider } from "@azure/msal-react";
import { AuthMethode } from "./authentication/strategy/AuthMethode.js";
import { AppContent } from "./components/AppContent.js";
import { NoneAuthStrategyComponent } from "./components/authentication/NoneAuthStrategyComponent.js";
import { sendRequest } from "./classes/Request.js";
import authProviderService from "./authentication/strategy/AuthProviderService.js";

export default function App() {

    log.setLevel(log.levels.TRACE);

    return  <GetAppComponentRender />

    function GetAppComponentRender() {
        switch (authProviderService.getAuthMethode()) {
            case AuthMethode.JSON:
                return getJsonComponentRender();
            case AuthMethode.AZURE:
                return getAzureComponentRender();
            default: 
                return getFailedAuthMethodeComponentRender();
        }
    }

    function getJsonComponentRender() {
        return <AppContent/>
    }

    function getAzureComponentRender() {
        return (
            <MsalProvider instance={createMsalInstance()}>
                <AppContent/>
            </MsalProvider>
            )
    }

    function getFailedAuthMethodeComponentRender() {
        return <NoneAuthStrategyComponent />
    }

    function createMsalInstance() {
        let msalInstance =  new PublicClientApplication(msalConfig);

        // Default to using the first account if no account is active on page load
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
            // Account selection logic is app dependent. Adjust as needed for different use cases.
            msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }
        // Optional - This will update account state if a user signs in from another tab or window
        msalInstance.enableAccountStorageEvents();

        // Listen for sign-in event and set active account
        msalInstance.addEventCallback((event: any) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                msalInstance.setActiveAccount(account);
            }
        });

        return msalInstance;
    }
}

