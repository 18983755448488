import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import CaseFiltersController from "../../classes/data_provision/CaseFiltersController.js";
import { CaseSelection } from "../View/DataSelectionStateMachine.js";
import Project from "@insight/common/interface/Project.js";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { FilterCreationDialog } from "./FilterCreationDialog/FilterCreationDialog.js";
import { SelectTree } from "./SelectTree2.js";
import { DragPositions } from "./SelectTreeLabel.js";
import { CaseFilterCollection } from "../../classes/case_filter/CaseFilterCollection.js";
import { CaseFilter } from '../../classes/case_filter/CaseFilter.js';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';
import { SetProgressContext } from '../ProgressOverlay.js';
import log from 'loglevel';

export type FilterManagerProps = {
    caseFiltersController: CaseFiltersController;
    onSelect: (parameters: CaseSelection) => void;
    registerSelectionChange: (key: string, cb: (filterIds: number[]) => void) => void;
    graph: DotGraph;
    project: Project;
}

export interface FilterManagerContext {
    createFilter: () => void;
    editFilter: (filter: CaseFilter) => void;
    removeFilter: (filter: CaseFilter) => void;
}

export interface SelectTreeDialogState {
    open: boolean;
    editFilter: CaseFilter | null;
    parentFilter: CaseFilter | null;
}

export let filterManagerContext: React.Context<FilterManagerContext>;

/**
 * Base componnent for case filter management. Selection of filters,
 * opening dialogs for creation, change and deletion of filters are
 * managed by this component.
 *
 * @param props
 * @returns
 */
let prevCaseFilters: CaseFilterCollection | undefined = undefined;
export function FilterManager(props: FilterManagerProps) {

    /** no effect dependencies => run once per render */
    React.useEffect(() => {
        log.debug("*** Render FilterManager")
    })

    const intl = useIntl(); // https://formatjs.io/docs/react-intl/api/#useintl-hook

    /*********************************************************************************************
     * store for current case filters. The function passed to useState is run only once,
     * so caseFilters remains unchanged.
     */
    const [caseFilters, setCaseFilters] = React.useState<CaseFilterCollection>(() => {
        return new CaseFilterCollection(props.caseFiltersController.data);
    })
    React.useEffect(() => {
        setCaseFilters(new CaseFilterCollection(props.caseFiltersController.data, false));
    }, [props.caseFiltersController.data])
    if (caseFilters !== prevCaseFilters) {
        log.debug(`caseFilters changed ${caseFilters.filterRegistry.length} filters`)
        prevCaseFilters = caseFilters;
    }

    /*********************************************************************************************
     * store for selected, i.e. acitve case filters. selected filteres are maintained on this
     * component level because if a selected filter is edited, the case selection needs to be
     * updated.
     */
    const [selectedFiltersIds, setSelectedFiltersIds] = React.useState<number[]>([]);

    /*********************************************************************************************
     * management of creation and change dialog
     */
    const [dialogState, setFilterDialogState] = React.useState<SelectTreeDialogState>({
        open: false,
        editFilter: null,
        parentFilter: null
    });

    /**********************************************************************************************
     * register callback for central selection changes
     */
    React.useEffect(() => {
        props.registerSelectionChange("tree", (selectedFiltersIds: number[]) => {
            setSelectedFiltersIds(selectedFiltersIds ? selectedFiltersIds : []);
        })
        setSelectedFiltersIds([]);
    },[]); //, [props.graph])

    const createFilter = () => {
        openDialog(null, null);
    }

    const editFilter = (filter: CaseFilter) => {
        openDialog(filter, filter.parent)
    }

    /**
     * Store filter. If it is new, add it to the collection of
     * update it if edited an existing one.
     * @param {*} filter : ;
     */
    const storeFilter = (filter: CaseFilter) => {
        if (dialogState.editFilter === null || filter.id !== dialogState.editFilter.id) {
            /** new filter */
            caseFilters.add(filter, dialogState.parentFilter);
        }
        else {
            /* edited an existing filter, update caseIds if this is a
               currently selected filter */
            caseFilters.updateTreeFor(filter);
            const ancestors = filter.ancestors;

            if (ancestors.findIndex(a => selectedFiltersIds.includes(a.id)) >= 0) {
                selectCases(selectedFiltersIds);
            }
        }
        setCaseFilters(new CaseFilterCollection(caseFilters.asArray()));
        closeDialog();
    }

    /**
     * Move a filter within the hierachy (drag & drop operation)
     */
    const moveFilter = React.useCallback((item: CaseFilter, position: DragPositions, target: CaseFilter) => {
        caseFilters.move(item, position, target);
        setCaseFilters(new CaseFilterCollection(caseFilters.asArray()));
    }, [caseFilters])

    /**
     * Remove a filter.
     * @param filter the filter to be removed
     */
    const removeFilter = React.useCallback((filter: CaseFilter) => {
        /* deselect this filter, if it is selected */
        if (selectedFiltersIds.includes(filter.id)) {
            setSelectedFiltersIds(selectedFiltersIds.filter(id => id !== filter.id));
        }
        caseFilters.remove(filter);
        setCaseFilters(new CaseFilterCollection(caseFilters.asArray()));
    }, [caseFilters]);

    filterManagerContext = React.createContext<FilterManagerContext>({
        createFilter,
        editFilter,
        removeFilter,
    });

    /**
     * Open the dialog to edit or create a filter
     * @param {*} event
     * @param {CaseFilter|null} editFilter null for new filter, else filter to be edited
     * @param {CaseFilter|null} parentFilter null for filter on top level, else parent filter
     */
    const openDialog = (editFilter: CaseFilter | null, parentFilter: CaseFilter | null) => {
        setFilterDialogState({
            ...dialogState,
            editFilter, // the filter to edit
            parentFilter,
            open: true,
        });
    }

    /**
     * Close the filter creation/editing filter
     */
    const closeDialog = () => {
        setFilterDialogState({
            ...dialogState,
            open: false,
        });
    }

    /**
     * Select cases based on the selected filters
     * @param selectedFiltersIds
     */
    const selectCases = (selectedFiltersIds: number[]): void => {
        /* determine and communicate selected filters */
        let iIdx = 0;
        let caseIds: number[] = [];
        const addCases: () => Promise<number[]> = function () {
            if (iIdx < selectedFiltersIds.length) {
                const filter = caseFilters.filterRegistry.get({ id: selectedFiltersIds[iIdx++] });
                if (filter) {
                    return filter.execute(props.project.eventsFilePath, props.graph)
                        .then(newCaseIds => {
                            if (newCaseIds !== undefined) {
                                caseIds = [...new Set([...caseIds, ...newCaseIds])];
                            }
                            return addCases();
                        })
                }
                else {
                    return Promise.reject(new Error("No case filter found"));
                }
            }
            else {
                return Promise.resolve(caseIds);
            }
        }
        addCases().then((caseIds) => {
            props.onSelect({ caseIds, controlId: 'tree', controlState: selectedFiltersIds });
        })
    }

    const progressOverlay = React.useContext(SetProgressContext);

    /** render the dialog */
    return (
        <>
            <h2 className="bp5-heading">
                <FormattedMessage
                    id="filter.variants.ttl"
                    defaultMessage="Case selectors"
                    description="Label for case selector tree titel"
                />
            </h2>
            <ButtonGroup>
                <Button
                    text={intl.formatMessage({
                        id: "filter.ui.save_filters",
                        defaultMessage: "Save filters",
                        description: "Button label to save filters.",
                    })}
                    disabled={progressOverlay === null}
                    intent={caseFilters.dirty ? "warning" : undefined}
                    onClick={() => {
                        if (progressOverlay) {
                            progressOverlay.setIsOpen(true);
                            progressOverlay.setLabel("Saving");
                            progressOverlay.setProgress(0);
                            props.caseFiltersController.data = caseFilters.asArray();
                            props.caseFiltersController.store(progressOverlay.setProgress)
                                .then(() => {
                                    progressOverlay?.setIsOpen(false);
                                    setCaseFilters(new CaseFilterCollection(caseFilters.asArray(), false));
                                })
                        }
                    }}
                />
                <Button
                    text={intl.formatMessage({
                        id: "filter.ui.add_filter",
                        defaultMessage: "Add Filter",
                        description: "Menu label to add a filter",
                    })}
                    style={{ marginLeft: "8px" }}
                    onClick={() => { createFilter() }}
                />
            </ButtonGroup>
            <SelectTree
                caseFilters={caseFilters.asArray()}
                handleDrop={moveFilter}
                selectedFiltersIds={selectedFiltersIds}
                selectFiltersIds={React.useCallback((caseFiltersIds: number[]) => {
                    setSelectedFiltersIds(caseFiltersIds);
                    selectCases(caseFiltersIds);
                }, [selectedFiltersIds])}
                project={props.project}
            />
            {dialogState.open &&
                <FilterCreationDialog
                    graph={props.graph}
                    onClose={closeDialog}
                    caseFilter={dialogState.editFilter}
                    storeFilter={storeFilter}
                />}
        </>

    );
}