import Edge, { EdgeData } from "@insight/common/graph/edge.js";
import { EdgeCases, EdgeCasesData } from "@insight/common/edge_cases/edge_cases.js";
import { GraphElementAttributes } from "@insight/common/graph/attributedgraphelement.js";
import format_duration from "../format_duration.js";
import DotVertex from "./dotvertex.js";
import { DotGraph, DotAttributes, Color } from "./dotgraph.js";
import { colord, extend, Plugin } from "colord";
import mixPlugin from "colord/plugins/mix";
extend([mixPlugin as unknown as Plugin]);

export type DotEdgeMode = "avg" | "min" | "max" | "none";
type DotEdgeOptions = { mode: DotEdgeMode, heavy: boolean };
export interface PartialDotEdgeOptions extends Partial<DotEdgeOptions> { }
type DotEdgeData = EdgeData & { cases: EdgeCasesData };

type EdgeAttributes = DotAttributes & {
    labelfloat?: boolean,
    lp?: string,
}



// function interpolate(v: number, a: number, b: number) {
//     let result: number;
//     if (a < b) {
//         result = a + (b - a) * v;
//     } else {
//         result = b + (a - b) * (1 - v);
//     }
//     return result;
// }

export default class DotEdge extends Edge {

    static createFromData(data: unknown, graph: DotGraph) {
        const edge: DotEdge = Object.create(DotEdge.prototype);
        edge.setGraph(graph);
        edge.initFromData(data);
        return edge;
    }

    static validate(data: unknown): data is DotEdgeData {
        return super.validate(data) &&
            "cases" in data &&
            EdgeCases.validate(data.cases);
    }

    /* set types for vertices and graph */
    declare from: DotVertex;
    declare to: DotVertex;
    declare graph: DotGraph;

    cases!: EdgeCases;
    __dotId!: string | null;

    constructor(from: DotVertex, to: DotVertex, attributes: GraphElementAttributes = {}) {
        super(from, to, attributes);
        this._initDotEdge(new EdgeCases());
    }

    _initDotEdge(cases: EdgeCases) {
        this.cases = cases;
        this.__dotId = null;
    }

    initFromData(data: unknown): data is DotEdgeData {
        if (super.initFromData(data) &&
            DotEdge.validate(data)) {
            const cases = new EdgeCases();
            if (cases.initFromData(data.cases)) {
                this._initDotEdge(cases);
                return true;
            }
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject(): DotEdgeData {
        return {
            ...super.__serializableObject(),
            cases: this.cases.__serializableObject(),
        }
    }

    copy(copy: DotEdge) {
        super.copy(copy);
        copy._initDotEdge(this.cases.copy());
        return copy;
    }

    setSource(vertex: DotVertex) {
        super.setSource(vertex);
        this.__dotId = null;
    }

    setDestination(vertex: DotVertex) {
        super.setDestination(vertex);
        this.__dotId = null;
    }

    dotId() {
        if (this.__dotId === null) {
            this.__dotId = this.from.dotId() + " -> " + this.to.dotId();
        }
        return this.__dotId;
    }

    toDot(options: PartialDotEdgeOptions = {}) {
        /** todo analyze svg graph, position nodes on top of edges and
         * make sure ege staring points are completely covered by nodes. Simmply: make a nicer picture
         */
        const graph: DotGraph = this.graph! as DotGraph;

        const properties = graph.getProperties();
        const parameters = graph.parameters;
        options = { mode: "avg", ...options };

        /***
         * compute label, append time if not zero
         */

        let time: number = 0;
        const mode = options.mode;
        let prefix = "";
        let postfix = "";
        switch (mode) {
            case "min":
                time = this.cases.minRunTime;
                postfix = " (min.)";
                break;
            case "max":
                time = this.cases.maxRunTime;
                postfix = " (max.)";
                break;
            case "none":
            case "avg":
                time = this.cases.avgRunTime;
                prefix = "~";
        }
        time = Math.round(time / 1000) * 1000;

        /**
         * @todo position label farther away from edge
         * providing a non-empty label assures the same layout of the graph with
         * or without labels
         */

        let label = " ";
        if (this.graph.showEdgeData) {
            label = `   #: ${this.cases.traversals}`;
            if (time > 0 && options.mode !== "none") {
                label += `\\l   t: ${prefix}${format_duration(time)}${postfix}`;
            }
            if (this.graph?.debug) {
                label += `\\l   id: ${this.__id}\\l`; // for debugging purposes
            }
        }

        /**
         * compute colors depending traversal duration
         */
        let factor: number = 0;
        switch (mode) {
            case "min":
                factor = (time - properties.minEdgeDurationBoundaries[0]) / (properties.minEdgeDurationBoundaries[1] - properties.minEdgeDurationBoundaries[0])
                break;
            case "max":
                factor = (time - properties.maxEdgeDurationBoundaries[0]) / (properties.maxEdgeDurationBoundaries[1] - properties.maxEdgeDurationBoundaries[0])
                break;
            case "avg":
            case "none":
                factor = (time - properties.avgEdgeDurationBoundaries[0]) / (properties.avgEdgeDurationBoundaries[1] - properties.avgEdgeDurationBoundaries[0])
        }

        let color: string;
        if (time > 0) {
            let color1: Color;
            let color2: Color;
            if (factor < 0.3) {
                color1 = parameters.greenColor;
                color2 = parameters.yellowColor;
                factor = factor / 0.3;
            } else {
                color1 = parameters.yellowColor;
                color2 = parameters.redColor;
                factor = (factor - 0.3) / 0.7;
            }
            color = colord(color1).mix(colord(color2), factor).toHex()
            // color = (["r", "g", "b"] as Array<keyof Color>).reduce((color, key) => {
            //     let ch = Math.round(interpolate(factor, color2[key], color1[key])).toString(16);
            //     if (ch.length === 1) {
            //         ch = "0" + ch;
            //     }
            //     color += ch;
            //     return color;
            // }, "#");
        } else {
            color = "#999999";
        }

        /**
         * compute dot edge weight based on edge traversals
         */

        factor = this.cases.traversals / properties.maxEdgeTraversals;

        /**
         * compute penwidth base on edge traversals
         * @todo : make the brute force approach better
         */
        if (factor > 0.75) {
            factor = 1.0;
        } else if (factor > 0.5) {
            factor = 0.9;
        } else if (factor > 0.375) {
            factor = 0.8;
        } else if (factor > 0.25) {
            factor = 0.7;
        } else if (factor > 0.18) {
            factor = 0.5;
        } else if (factor > 0.12) {
            factor = 0.4;
        } else if (factor > 0.09) {
            factor = 0.3;
        } else if (factor > 0.06) {
            factor = 0.2;
        } else if (factor > 0.03) {
            factor = 0.1;
        } else {
            factor = 0.0;
        }

        const penwidth = parameters.minPenWidth + factor * (parameters.maxPenWidth - parameters.minPenWidth);
        /**
         * setup edge attributes. Explicitly not using edge weights as they
         * have proved to create more complex graphs
         */
        const dot_attrs: EdgeAttributes = {
            penwidth: penwidth,
            tooltip: `#: ${this.cases.traversals}\nt: ${prefix}${format_duration(time)}${postfix}`,
            label,
            color,
            URL: `/?__id=${this.__id}`,
            fontname: "Arial",
            fontsize: 12,
            /**
             * as of now, the difference between dashed and solid isn't noticed by d3
             * and a dashed grey edge may appear solid if it was in the previous graph
             * and with a solid stroke. So for the time being, we set all edges to solid.
             */
            style: "solid" // time > 0 ? "solid" : "dashed"
        };
        if (this.cases.traversals === 0) {
            dot_attrs.style = "invis";
        }
        return `${this.from.dotId()} -> ${this.to.dotId()} [${(Object.keys(dot_attrs) as Array<keyof EdgeAttributes>)
            .map((key) => `${key}="${dot_attrs[key]}"`)
            .join(",")}]\n`;
    }
}
