import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.js";
import "./assets/css/index.css";

const container_id = "root";
const container = document.getElementById(container_id);
if (container) {
    const root = createRoot(container);
    root.render(<App/>);
} else {
    document.body.onload = () => {
        // eslint-disable-next-line no-useless-catch
        try {
            /** remove all CSS */
            document.querySelectorAll('style,link[rel="stylesheet"]').forEach((item) => item.remove()); // see https://techstacker.com/how-to-remove-all-css-from-site-with-javascript/

            /** write error message to page */
            const div = document.createElement("div");
            const txt = document.createTextNode(`Cannot start React: no container with id="${container_id}" found.`);
            div.appendChild(txt);
            document.body.insertBefore(div, document.body.firstChild);
        } catch (err) {
            /* if the above fails, throw an error, at least */
            throw err;
        }
    };
}