/* eslint-disable @typescript-eslint/ban-types */
/*
    Decycler
*/
import SortedArray from "collections/sorted-array.js";

interface BoundaryType { id: string }
function isBoundaryType(object: unknown, bt: GetableTypes): object is BoundaryType {
    return typeof object === 'object' &&
        object !== null &&
        object.constructor.name in bt;
}

/**
 * A getable type is a class type which provides a
 * (static) method to get an instance of that type
 * by some id from a static store inside that type.
 * Instances of such classes need to have an "id"
 * property in order for this to work.
 */
interface GettableType extends Function {
    getById: (id: string) => unknown;
}
type GetableTypes = { [key: string]: GettableType };

interface BoundaryDescriptor {
    __type: string;
    __id: string;
    __prop: string
}

interface ObjectMark { $$id?: number }
interface MarkableObject extends ObjectMark { [key: string]: unknown }
function isMarkableObject(value: unknown): value is MarkableObject {
    return typeof value === "object" && value !== null;
}

export type Serializable =
    (
        { $$id: number } &
        (
            { $$arr: (Serializable | null)[] } |
            { $$map: ({ key: string, value: Serializable } | null)[] } |
            {
                $$props: { [key: string]: Serializable | null },
                $$cls: string,
                $b?: BoundaryDescriptor[]
            }
        )
    ) |
    { $$ref: number } |
    { $$class: string } |
    { $$function: string } |
    undefined | boolean | number | string | bigint | string | symbol;

export type Hooks = { [key: string]: (value: object) => object };

/**
 * A decycler decycles and object so that it das not contain any circular
 * references within its structure and can be JSON stringified without
 * problems.
 * It also re-cycles that object from a given de-cycled version.
 * To make a class de- and re-cyclable, it needs to be registered, eiher
 * globally or with a specificy Decycler instance. Also, if an uglifier
 * is being used, the classname of that class needs to be made immmuatable
 * with setClassName().
 * Decycler supports so called "BoundaryClasses" which are Classes who's
 * instances have a static "getById()" method and an "id" property. Their
 * instances are not included in the decycled object but are later re-attached
 * to the data structure by using getById() to get a reference to the
 * respective instance and by storing the id in the de-cyclce structure.
 */
export default class Decycler {

    /**
     * The default types of values, which are serialized / deserialized by this decycler. It can be
     * extended by registering more serializer types - globally or per Decycler
     */
    private static globalSerializableTypes: { [key: string]: Function } = {
        Array: Array,
        Boolean: Boolean,
        Date: Date,
        Number: Number,
        Object: Object,
        RegExp: RegExp,
        String: String,
        SortedArray: SortedArray,
        Map: Map,
    }

    private static globalBoundaryTypes: GetableTypes = {};

    private static saveHooks: Hooks = {};
    private static loadHooks: Hooks = {};

    static setSaveHook<T>(type: Function, hook: (value: T) => object) {
        this.saveHooks[type.name] = hook as (value: object) => object;
    }

    static setLoadHook<T>(type: Function, hook: (value: T) => object) {
        this.loadHooks[type.name] = hook as (value: object) => object;
    }

    static registerBoundaryType(type: GettableType) {
        this.globalBoundaryTypes[type.name] = type;
    }

    static registerBoundaryTypes(types: GettableType[]) {
        types.forEach(type => {
            this.registerBoundaryType(type);
        })
    }

    static registerSerializableType(type: Function) {
        this.globalSerializableTypes[type.name] = type;
    }

    static registerSerializableTypes(types: Function[]) {
        types.forEach(type => {
            this.registerSerializableType(type);
        })
    }

    private static findAncestorConstructorWithHook(value: object, hooks: Hooks) {
        let constructor = value.constructor;
        while (constructor !== null && !(constructor.name in hooks)) {
            constructor = Object.getPrototypeOf(constructor);
        }
        return constructor;
    }

    saveHooks: Hooks // hooks to change objects before they are serialized
    loadHooks: Hooks // hooks to chnage de-serialized objects before they are passed back for usage
    getableTypes: GetableTypes;

    /** The types of values, which are serialized / deserialized by this decycler */
    types: { [key: string]: Function };

    $$id: number;
    values?: MarkableObject[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objects!: SortedArray<any>;

    constructor() {
        this.loadHooks = Decycler.loadHooks;
        this.saveHooks = Decycler.saveHooks;
        this.types = Decycler.globalSerializableTypes;
        this.getableTypes = Decycler.globalBoundaryTypes;
        this.values = [];
        this.$$id = -1;
    }

    registerBoundaryType(type: GettableType) {
        this.getableTypes[type.name] = type;
    }

    registerBoundaryTypes(types: GettableType[]) {
        types.forEach(type => {
            this.registerBoundaryType(type);
        })
    }

    registerSerializableType(type: Function) {
        this.types[type.name] = type;
    }

    registerSerializableTypes(types: Function[]) {
        types.forEach(type => {
            this.registerSerializableType(type);
        })
    }

    setSaveHook<T>(type: Function, hook: (value: T) => object) {
        this.saveHooks[type.name] = hook as (value: object) => object;
    }

    setLoadHook<T>(type: Function, hook: (value: T) => object) {
        this.loadHooks[type.name] = hook as (value: object) => object;
    }


    static _depth = 0;

    /**
     * Dereference a value by recursing through its properties, if any, and
     * store references only once. Any double reference will
     *
     * @param value Value of any type to be dereferenced
     * @returns Dereferenced Value
     */
    private _derez(value: unknown, isValueFromHook: boolean = false) {
        Decycler._depth++;
        if (Decycler._depth > 20) {
            throw new Error('Derez error');
        }
        // The derez function recurses through the object, producing the deep copy.
        let nu: Serializable; // The new object or array

        // typeof null === "object", so go on if this value is really an object but not
        // one of the builtin objects.
        switch (typeof value) {

            case "undefined":
            case "boolean":
            case "number":
            case "bigint":
            case "string":
            case "symbol":
                nu = value;
                break;

            case 'function':
                if (value.name in this.types) {
                    /* reference to a class in the object */
                    nu = {
                        $$class: value.name,
                    }
                } else {
                    /* a function in the object */
                    nu = {
                        $$function: value.toString(),
                    }
                }
                break;

            case 'object':
                if (isMarkableObject(value)) {
                    const cls_name: string = value.constructor.name
                    if (cls_name in this.types) {
                        if (isMarkableObject(value)) {
                            switch (cls_name) {
                                /* Boolean ... Number can be serialized directly and the new value is identical to the source value */
                                case 'Boolean':
                                case 'Date':
                                case 'String':
                                case 'Number':
                                    break;
                                default:
                                    if (value.$$id) {
                                        /* value already has an id, so lets just reference it */
                                        nu = { $$ref: value.$$id };
                                    } else {
                                        value.$$id = this.$$id++; // assign id so the next occurence of this will only be referred to
                                        let new_value = value;
                                        if (!isValueFromHook) {
                                            const constructor = Decycler.findAncestorConstructorWithHook(value, this.saveHooks);
                                            if (constructor !== null) {
                                                new_value = this.saveHooks[constructor.name](value) as MarkableObject; // new_value might be the *same* or a new value
                                            }
                                        }

                                        /** if saveHook returned a new object, then derez that object and give the original value the same id */
                                        if (new_value !== value) {
                                            delete new_value.$$id;
                                            nu = this._derez(new_value, true);
                                            if (typeof nu === "object") {
                                                (nu as MarkableObject).$$id = value.$$id;
                                            }
                                        }
                                        else {
                                            /** saveHook not found or original object modified. continue with orginal object */
                                            value.$$id = this.$$id++; // flag this object as already in map;
                                            if (value instanceof Array) {
                                                /* walk through the array and recurse into dereferencialization */
                                                nu = { $$arr: [], $$id: value.$$id };
                                                for (const element of value) {
                                                    nu.$$arr.push(element != null ? this._derez(element) : null);
                                                }
                                            }
                                            else if (value instanceof Map) {
                                                /* walk through the map and recurse into dereferencialization */
                                                nu = { $$map: [], $$id: value.$$id }
                                                for (const [key,] of value) {
                                                    nu.$$map.push({ key: key, value: this._derez(value) })
                                                }
                                            }
                                            else {
                                                /* value is an object - decycle all properties except $$id */
                                                const $$props: { [key: string]: Serializable | null } = {};
                                                let $b: BoundaryDescriptor[];
                                                Object.keys(value).forEach(propKey => {
                                                    if (propKey !== '$$id') {
                                                        const propValue = (value)[propKey];
                                                        if (isBoundaryType(propValue, this.getableTypes)) {
                                                            if (propValue.id !== undefined) {
                                                                const id = propValue.id;
                                                                const v: BoundaryDescriptor = { __type: propValue.constructor.name, __id: id, __prop: propKey };
                                                                if ($b === undefined) {
                                                                    $b = [v]
                                                                }
                                                                else {
                                                                    $b.push(v);
                                                                }
                                                            }
                                                            else {
                                                                throw new Error(`Boundary class ${propValue.constructor.name} needs an id property.`)
                                                            }
                                                        }
                                                        else {
                                                            $$props[propKey] = propValue !== null ? this._derez(propValue) : null;
                                                        }
                                                    }
                                                    nu = ($b === undefined) ? { $$props, $$cls: cls_name, $$id: value.$$id! } : { $$props, $$cls: cls_name, $$id: value.$$id!, $b }
                                                });
                                            }
                                            this.values!.push(value);
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                    else {
                        throw new Error(`Unknown object class ${cls_name}.`);
                    }
                }
                break;
        }
        Decycler._depth--;
        return nu;
    }

    decycle(object: unknown) {
        this.values = []; // collects all values which get an id
        this.$$id = 1;
        const result = this._derez(object);

        /* cleanup */
        this.values.forEach((value) => {
            delete value.$$id;
        })
        delete this.values;
        return result;
    }

    private _rez(value: unknown) {
        // The rez function walks recursively through the object looking for $ref
        // properties. When it finds one that has a value that is a path, then it
        // replaces the $ref object with a reference to the value that is found by
        // the path.
        if (isMarkableObject(value)) {
            if ('$$id' in value) {
                if (value instanceof Array) {
                    for (let i = 0; i < value.length; i++) {
                        value[i] = this._rez(value[i]);
                    }
                } else {
                    for (const name of Object.keys(value)) {
                        if (name !== '$$id') {
                            value[name] = this._rez(value[name]);
                        }
                    }
                }
            }
            else if ('$$ref' in value) {
                const tmp = this.objects.get({
                    $$id: value.$$ref
                });
                delete value.$$ref; // remove $$ref from value to avoid recursion on this value
                // value = this._rez(tmp);
                value = tmp;
            }
        }
        return value;
    }

    private _makeObject(value: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let nu: any = value; // return value by default;
        if (value && typeof value === "object") {
            if ("$$function" in value && typeof value.$$function === "string") {
                /** replace "function" by "function name" */
                const tmp = 'nu =' + value.$$function.replace(/.+(\(.+)/, 'function $1');
                eval(tmp);
            }
            else if ("$$class" in value && typeof value.$$class === "string") {
                if (value.$$class in this.types) {
                    nu = this.types[value.$$class];
                }
                else {
                    throw new Error(`${value.$$class} is not registered.`)
                }
            }
            else if ("$$id" in value) { // $$id is never 0 but may be undefined

                /***
                 *  value has $$id, so it is an original object that may
                 *  be referenced by many other objects
                 */

                if ("$$arr" in value && value.$$arr instanceof Array) {
                    /* make an array */
                    nu = value.$$arr.map((e: unknown) => {
                        if (e !== null) {
                            return this._makeObject(e);
                        }
                        else {
                            return null;
                        }
                    })
                }
                else if ("$$map" in value && value.$$map instanceof Array) {
                    /* make a map */
                    const m = new Map();
                    value.$$map.forEach((e: unknown) => {
                        if (typeof e === "object" && e !== null && "key" in e && "value" in e) {
                            m.set(e.key, this._makeObject(e.value));
                        }
                    })
                    nu = m;
                }
                else if ("$$props" in value &&
                    "$$cls" in value &&
                    typeof value.$$cls === "string" &&
                    typeof value.$$props === "object" &&
                    value.$$props !== null) {
                    /* make an object of a class */
                    if (value.$$cls in this.types) {
                        const prototype = this.types[value.$$cls].prototype;
                        nu = {};
                        for (const name of Object.keys(value.$$props)) {
                            let tmp = (value.$$props as { [key: string]: unknown })[name];
                            if (tmp !== null) {
                                tmp = this._makeObject(tmp)
                            }
                            nu[name] = tmp;
                        }
                        nu.__proto__ = prototype;
                    }
                    else {
                        throw new Error(`${value.$$cls} is not registered.`)
                    }
                }
                else {
                    throw new Error(`No $$cls and no $$props`)
                }

                if ('$b' in value && value.$b instanceof Array) {
                    /**
                     * reassign connections to boundary objects, i.e. objects
                     * which are not part of the serialized object
                     */
                    value.$b.forEach((b: BoundaryDescriptor) => {
                        const type = this.getableTypes[b.__type];
                        if (type && type['getById']) {
                            const ref = type.getById(b.__id);
                            nu[b.__prop] = ref;
                        }
                        else {
                            throw new Error(`class ${type.name} is expeted to have a static "getById(id)" method.`);
                        }
                    })
                    delete value.$b;
                }

                nu.$$id = value.$$id; // copy id to make the object retrievable from the list of original objects
                this.objects.add(nu); // add to original objects
            }
        }
        return nu;
    }

    retrocycle(obj: Serializable) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.objects = new SortedArray<any>([], (a, b) => a.$$id === b.$$id, (a, b) => Math.sign(a.$$id! - b.$$id!));
        const nu = this._makeObject(obj);

        /* Resolve references */
        const result = this._rez(nu);

        /* Cleanse object from $$ids */
        this.objects.array.forEach(obj => {
            delete obj.$$id;
        });

        /* The object is retrocycle now. Execute load hooks */
        this.objects.array.forEach(e => {
            /* cycle through all types in order to mind possbile
               inheritence */
            for (const name in this.types) {
                if (e instanceof this.types[name] && this.loadHooks[name]) {
                    this.loadHooks[name](e);
                }
            }
        })
        return result;
    }
}
