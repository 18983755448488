// https://github.com/styled-components/styled-components/issues/3601
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from "styled-components";
export const Menuframe = styled.div`
    .bp5-overlay {
        .bp5-drawer {
            max-width:320px;
            .bp5-menu[class*="bp5-elevation-"] {
                    box-shadow: initial;
            }
        }
    }

    &.push-menu {
        &.menu-open {
            padding-left: min(${(props: { menuWidth?: number }) => props.menuWidth || 33}%, 320px);
            transition: padding-left 200ms;
        }

        .bp5-overlay {
            width: ${(props) => props.menuWidth || 33}%;
            max-width:320px;
            .bp5-drawer {
                width: 100%;
                box-shadow: 1px 0px 0px 0px rgb(16 22 26 / 15%);
            }
        }
    }

    .bp5-overlay.dark-drawer {
        position: absolute;
        .bp5-drawer {

                background-color: ${(props) => props.theme.backGroundColor};

                .bp5-drawer-header .bp5-heading {
                    color: white;
                };
                .bp5-menu {
                    background-color: ${(props) => props.theme.backGroundColor};
                    .bp5-menu-item  {
                        &.bp5-disabled {
                            color: ${(props) => props.theme.disabledColor} !important;
                            .bp5-menu-item-label  {
                                color: ${(props) => props.theme.disabledColor} !important;
                            }
                            & > .bp5-icon {
                                color: ${(props) => props.theme.disabledColor} !important;
                            }
                        }

                        color: ${(props) => props.theme.color};
                        .bp5-menu-item-label  {
                            color: ${(props) => props.theme.color} !important;
                        }
                        & > .bp5-icon {
                            color: ${(props) => props.theme.color};
                        }
                    }
                    .bp5-menu-header {
                        &:first-of-type {
                            border-top: none;
                        }

                        border-top: 1px solid ${(props) => props.theme.borderColor};
                        .bp5-heading {
                            color: ${(props) => props.theme.color};
                        }
                    }
                }
            }
        }
    }
`;
