import React, { ReactElement, createContext, useContext } from 'react';

import { interpret } from 'xstate';
import { Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';

import assert from 'assert';

import { maximizeWindowMachine } from './GridStateMachine.js';

export interface IGridElementProps {
    id: string;
    top: number;
    left: number;
    bottom: number;
    right: number;
}

export interface IGridProps extends React.PropsWithChildren {
    rows: number;
    columns: number;
}

interface IGridGroupProps extends IGridElementProps, React.PropsWithChildren {
}

export interface IGridCellProps extends IGridElementProps {
    style?: { [key: string]: string | undefined }
    className?: string;
}

const GridContext = createContext<Grid | null>(null);

/**
 * Grid component which ultimately has GridGroups and GridCells as descendants.
 */
export class Grid extends React.Component<IGridProps> {

    /**
     * state machine definition for maximizing grid cells via a "toggle" event
     */
    maximizeWindowService;
    cellId: number;
    rows = -1;
    columns = -1;
    state;

    constructor(props: IGridProps) {
        super(props);

        /**
         * create and integrate state machine for this grid instance
         */
        this.maximizeWindowService = interpret(maximizeWindowMachine);
        this.maximizeWindowService.onTransition((current) => {
            if (this.state.current.value !== current.value) {
                console.log(`** Gridstate ${current.value}`)
                return this.setState({ current })
            }
        });

        this.state = { current: this.maximizeWindowService.machine.initialState };

        /**
         * initial value for created element ids
         */
        this.cellId = 1;
    }

    componentDidMount() {
        /** start the state machine service */
        this.maximizeWindowService.start();
    }

    componentWillUnmount() {
        /** stop the state machine service */
        this.maximizeWindowService.stop();
    }

    createCellId = () => {
        /** create a new element id */
        console.log(`cellid ${this.cellId}`);
        return this.cellId++;
    }

    /**
     * Convert rows / columns span of this grid into respective CSS
     * @returns void
     */
    getStyle() {
        const colPercent = 100/this.props.columns;
        const rowPercent = 100/this.props.rows;
        const result = {
            display: "grid",
            "gridTemplateColumns": `repeat(${this.props.columns}, calc(${colPercent}% - ${(this.props.columns-1)*8/this.props.columns}px))`,
            "gridTemplateRows": `repeat(${this.props.rows}, calc(${rowPercent}% - ${(this.props.rows-1/this.props.rows)*8/this.props.rows}px))`,
            "gridGap": "8px",
            width: "100%",
            height: "100%",
        }
        return result;
    }

    /**
     * Toggle view of cell with id
     * @param {string|Number} cellId
     */
    toggleMaximized = (cellId: string) => {
        this.maximizeWindowService.send({
            type: 'toggle',
            cellId: cellId,
        })
    }

    /**
     * @returns {boolean} true if current grid view has a cell maximized
     */
    isMaximized = () => {
        return this.state.current.matches('maximized');
    }

    /**
     * @param {string|Number} cellId
     * @returns {boolean} true if the cell is maximized
     */
    isMax = (cellId: string) => {
        return this.state.current.context.maximizedId === cellId;
    }

    render() {
        console.log(`*** Render Grid`);
        this.rows = this.props.rows ? this.props.rows : 1;
        this.columns = this.props.columns ? this.props.columns : 1;
        const children = Array.isArray(this.props.children)
            ? this.props.children as ReactElement<IGridElementProps>[]
            : [this.props.children as ReactElement<IGridElementProps>];

        /** row / column are used if top/left are not specified */
        let row = 1;
        let column = 1;

        /* for every render, start with the same cell id base */
        this.cellId = 1;

        /* clone childs and pass them some additional props */

        let top, left, bottom, right;
        const cells = children.map((child) => {

            /* compute area covered by child */
            top = child.props.top ? child.props.top : row;
            left = child.props.left ? child.props.left : column;
            bottom = child.props.bottom ? child.props.bottom : top;
            right = child.props.right ? child.props.right : left;

            const cell = React.cloneElement<IGridGroupProps | IGridCellProps>(
                child as React.ReactElement<IGridCellProps | IGridGroupProps>,
                {
                    ...child.props,
                    top, left, bottom, right,
                }
            )

            column = right + 1;
            if (column > this.columns) {
                column = 1;
                if (row < this.rows) {
                    row++;
                }
            }
            return cell;
        });

        /* render the grid */
        return (
            <GridContext.Provider value={this}>
                <div style={this.getStyle()} >
                    {cells}
                </div>
            </GridContext.Provider>
        )
    }
}

/**
 * A GridGroup contains a set of GridCells and moves them to a specific area of the grid
 * @param {Object} props Properties of the GridGroup.
 * @returns {JSX} The JSX to render
 */

export function GridGroup(props: IGridGroupProps) {

    /** @type Grid */
    const grid = useContext(GridContext);
    let cells: ReactElement<IGridCellProps>[] = [];

    if (grid !== null) {

        let column = 1;
        let row = 1;

        /***
         *  setup area coved by GridGroup as integer numbers
         */
        const group_top = props.top ? props.top : row;
        const group_left = props.left ? props.left : column;
        const group_bottom = props.bottom ? props.bottom : row;
        const group_right = props.bottom ? props.right : column;

        const children = Array.isArray(props.children) ? props.children as React.ReactElement<IGridCellProps>[] : [props.children as ReactElement<IGridCellProps>];
        let top: number, left: number, bottom: number, right: number;

        /** loop over all children which must be GridCells */
        cells = children.reduce<ReactElement<IGridCellProps>[]>(((cells,
            child,
            child_idx: number) => {

            assert(child.type === GridCell);

            /** create  child id */
            const group_id = props.id ? props.id : 'gc' + grid.createCellId();
            const child_id = child.props.id ? child.props.id : `${group_id}_${child_idx + 1}`;

            let display = false; // true, if anything renderable has been found
            if (!grid.isMaximized()) {

                /**
                 * Not maximized, all children get displayed.
                 * Compute area covered by child, keep within Grid boundaries
                 */
                const element_top = child.props.top ? child.props.top : row;
                const element_left = child.props.left ? child.props.left : column;

                top = group_top + element_top - 1;
                left = group_left + element_left - 1;
                bottom = Math.min(child.props.bottom !== undefined ? child.props.bottom + group_top - 1 : group_bottom, group_bottom);
                right = Math.min(child.props.right !== undefined ? child.props.right + group_left - 1 : group_right, group_right);
                display = true;
            }
            else if (grid.isMax(child_id)) {

                /**
                 * Maximized, only the maximized child gets displayed.
                 * Set area for maximied display
                 */
                top = 1;
                left = 1;
                bottom = -2; // +1 is added when generating the CSS style, -1 is what we want
                right = -2;  // same here
                display = true;
            }

            if (display) {

                /** collect displayable child by cloning it and adding a view props */
                cells.push(React.cloneElement(
                    child,
                    {
                        ...child.props,
                        id: child_id,
                        key: child_id,
                        top, left, bottom, right,
                    })
                );

                /** adjust default row and column from left to right, top to bottom */
                column = right + 1;
                row = bottom;
                if (column > grid.columns) {
                    column = 1;
                    if (row < grid.rows) {
                        row++;
                    }
                }
            }
            return cells;

        }), [])
    }
    return (
        <>{cells}</>
    )
}

/**
 * A GridCell is a div with a grid-area CSS.
 * @param {Object} props The properties of the GridCell. Contains the area of the cell and
 * the Grid component
 * @returns
 */

export function GridCell(props: React.PropsWithChildren<IGridCellProps>) {

    /** @type Grid */
    const grid = useContext(GridContext);
    let result = null;

    if (grid !== null) {

        /**
         * Toggle min/max view.
         * @param {SyntheticBaseEvent} event
         */
        const onMinMaxClick = (event: React.MouseEvent) => {
            const id_attr = event.currentTarget.attributes.getNamedItem('id');
            if (id_attr) {
                const id = id_attr.value.replace('icon_', '');
                grid.toggleMaximized(id);

            }
        }

        const id: string = props.id ? props.id : "gc" + grid.createCellId();

        /** get area based on display mode */
        let top = 0, left = 0, bottom = 0, right = 0;
        let display = false; // true, if anything renderable has been found
        if (!grid.isMaximized()) {
            top = props.top;
            left = props.left;
            bottom = props.bottom;
            right = props.right;
            display = true;
        }
        else if (grid.isMax(id)) {
            /**
             * if maximized, only the maximized child gets displayed
             */
            top = 1;
            left = 1;
            bottom = -2;
            right = -2;
            display = true;
        }

        if (display) {

            const style = {
                ...props.style,
                "gridColumn": `${left} / ${right + 1}`,
                "gridRow": `${top} / ${bottom + 1}`,
            }

            const iconType: IconName = grid.isMaximized() ? "minimize" : "maximize";
            const icon = <Icon
                icon={iconType}
                size={10}
                onClick={onMinMaxClick}
                id={`icon_${id}`}
                className='sizer'
            />

            /** setup cell attributes from props by filtering out some props and adding some others */
            const filter = ['top', 'left', 'bottom', 'right'];
            const filteredKeys: (keyof typeof props)[] = (Object.keys(props) as (keyof typeof props)[]).filter(key => !filter.includes(key));
            let attrs: Partial<React.PropsWithChildren<IGridCellProps>> = {};
            filteredKeys.forEach(fk => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                attrs[fk] = props[fk] as any;
            })

            attrs = {
                ...attrs,
                id,
                className: [props.className, 'grid-cell'].join(' ').trim(),
                style,
            }

            /** render */
            result = (
                <div {...attrs}>
                    {props.children}
                    {icon}
                </div>
            )
        }
    }
    return result;
}
