// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

import setClassName from '../../helpers/setClassName.js';
import { CaseFilterField, CaseFilterFieldValue, CaseFilterFieldValues, CaseFilterFields } from './CaseFilterField.js';
import { CaseFilterType } from './CaseFilterType.js';
import Decycler from '@insight/common/decycler/decycler.js';
import { IntlShape } from 'react-intl';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';
import { CaseFilterFieldDefinitionData } from './CaseFilterFieldDefinition.js';

export type CaseFilterExecutor = (f: CaseFilter, eventsfile: string) => Promise<number[]>;
export type CaseFilterTreeLabelGenerator = (tree: CaseFilter) => string;

export class CaseFilter {
    static nextId: number = Date.now();
    id: number;
    name: string;
    description: string | undefined;
    type: CaseFilterType;
    fields: CaseFilterFields;
    children: Array<CaseFilter>
    parent: CaseFilter | null;
    data!: { graph: DotGraph | undefined }

    /**
     * @param {string} name
     * @param {string} description
     * @param {CaseFilterType} type
     * @param {Object} parameters
     */
    constructor(name: string,
        description: string | undefined,
        type: CaseFilterType,
    ) {
        this.id = CaseFilter.nextId++;
        this.name = name;
        this.description = description;
        this.type = type;

        this.children = [];
        this.parent = null;
        this.fields = this.type.fieldDefinitions.reduce<CaseFilterFields>((cff, def) => {
            cff[def.id] = new CaseFilterField(def.id, this, def);
            return cff;
        }, {})
        this.initData();
    }

    initData() {
        this.data = {
            graph: undefined
        }
    }

    injectGraph(graph: DotGraph) {
        this.data.graph = graph;
        this.onGraphAssigned();
    }

    onGraphAssigned(): void {
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(field: CaseFilterField, value: string): void {
        return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOptions(field: CaseFilterField): string[] | undefined {
        return undefined;
    }

    get canHaveChildren() {
        return this.type.childrenAllowed;
    }

    addChild(childFilter: CaseFilter) {
        if (this.canHaveChildren) {
            this.children.push(childFilter);
            childFilter.parent = this;
        }
    }

    removeChild(childFilter: CaseFilter) {
        if (this.canHaveChildren) {
            const index = this.children.indexOf(childFilter);
            if (index > -1) {
                this.children.splice(index, 1);
                childFilter.parent = null;
            }
        }
    }

    get ancestors() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let current: CaseFilter = this;
        const ancestors = [current];
        while (current.parent) {
            current = current.parent;
            ancestors.push(current);
        }
        return ancestors;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    execute(eventsfile: string, graph: DotGraph): Promise<number[]> {
        return Promise.resolve([]);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    label(intl: IntlShape): string {
        return "null";
    }

    fieldValues(): CaseFilterFieldValues {
        return Object.keys(this.fields).reduce<{ [key: string]: (CaseFilterFieldValue) }>((bare, key) => {
            bare[key] = this.fields[key].value;
            return bare;
        }, {})
    }

    /**
     * Clone node and the children array only.
     * @returns
     */
    cloneShallow(): CaseFilter {
        const result = new (this.constructor as typeof CaseFilter)(this.name, this.description, this.type);
        result.id = this.id;
        result.fields = {...this.fields};
        result.children = [...this.children];
        result.parent = this.parent;
        return result;
    }
}
setClassName(CaseFilter, "CaseFilter"); // for minifying purposes when using constructor.name
Decycler.registerSerializableType(CaseFilter);

type ValueAndDef = { value: CaseFilterFieldValue, defChanges: Partial<CaseFilterFieldDefinitionData> };
Decycler.setSaveHook<CaseFilter>(CaseFilter, (filter: CaseFilter) => {
    const clone = filter.cloneShallow();
    delete (clone as unknown as { [key: string]: unknown }).data;
    (clone.fields as unknown) = filter.type.fieldDefinitions.reduce<{ [key: string]: ValueAndDef }>((vds, def) => {
        vds[def.id] = {
            value: clone.fields[def.id].value,
            defChanges: def.getChangedProperties(clone.fields[def.id].fieldDefinitionCopy) // changed properties in definition
        }
        return vds;
    }, {});
    return clone;
});

Decycler.setLoadHook(CaseFilter, (filter: CaseFilter) => { /** @todo: catch errors */
    filter.fields = filter.type.fieldDefinitions.reduce<CaseFilterFields>((cff, def) => {
        const defChanges = (filter.fields[def.id] as unknown as ValueAndDef).defChanges;
        cff[def.id] = new CaseFilterField(def.id, filter, def);
        Object.assign(cff[def.id].fieldDefinitionCopy, defChanges); // re-assign changed properties in definition
        cff[def.id].set((filter.fields[def.id] as unknown as ValueAndDef).value)
        return cff;
    }, {})
    filter.initData();
    return filter
})

