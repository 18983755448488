import assert from "assert";
import FileSaver from "file-saver";

export function downloadBlob(blob: Blob, filename: string) {
    FileSaver.saveAs(blob, filename);
}

export function downloadSVG(element: SVGSVGElement) {
    element.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    // graphviz generates &nbsp;'s in the SVG code for node titles which are not accepted by other SVG loaders
    const svgData = element.outerHTML.replace(/&nbsp;/g," ")
    const preface = `<?xml version="1.0" standalone="no"?>\r\n`;
    const blob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
    downloadBlob(blob, "export.svg");
}

export function downloadCSV(header: string[], rows: string[][]) {
    rows.forEach((row, index) =>
        assert.equal(row.length, header.length, `row ${index} length ${row.length} != header length ${header.length}`));

    const csv = header.join(',') + '\n' + rows.map(row => row.join(',')).join('\n');
    const blob = new Blob([csv], { type: "text/csv" });
    downloadBlob(blob, "export.csv");
}