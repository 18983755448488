import React from 'react'
import { IntlProvider } from 'react-intl'
import ProgressBarOverlay, { OverlayProvider } from './ProgressOverlay.js'
import Baseplate from './Baseplate.js'

import * as RequestController from "../classes/Request.js";
import { useActor } from "@xstate/react";
import { messages, localizationService } from "../classes/LocaleStateMachine.js";

export const AppContent = () => {

    const [state] = useActor(localizationService);
    const locale = state.context.locale;

    removeAndSetLanguageRequestModifier(locale);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <OverlayProvider>
                <ProgressBarOverlay />
                <Baseplate pushMenu={true} />
            </OverlayProvider>
        </IntlProvider>
    )

    function removeAndSetLanguageRequestModifier(locale: string) {
        RequestController.removeRequestModificator("app");
        RequestController.setRequestModificator("app", (request) => {
            const langs: string[] = [locale];
            let q = 1.0;
            Object.keys(messages).forEach((lang) => {
                if (lang !== locale) {
                    q -= 0.05;
                    langs.push(`${lang};q=${q}`);
                }
            });
            const acceptedLanguages = langs.join(", ");
            request.headers = { ...request.headers, "Accept-Language": acceptedLanguages };
        });
    }
}
