import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuItem, Dialog, Intent, Button, InputGroup, FormGroup, Classes, AnchorButton } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";
import { CaseFilterType } from '../../../classes/case_filter/CaseFilterType.js';
import { CaseFilter } from '../../../classes/case_filter/CaseFilter.js';
import { CaseFilterField } from '../../../classes/case_filter/CaseFilterField.js';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';

export interface FilterCreationDialogProps {
    onClose: () => void;
    caseFilter: CaseFilter | null;
    storeFilter: (filter: CaseFilter) => void;
    graph: DotGraph;
}

export function FilterCreationDialog(props: FilterCreationDialogProps) {

    const intl = useIntl();

    if (props.caseFilter) props.caseFilter.injectGraph(props.graph);

    React.useEffect(()=>{
        console.log(`*** Redraw FilterCreationDialog`);
    })

    /**
     * If type is the same then the passed caseFilter object, then show the field
     * values from the passed object. Otherwise present empty fields. The fields
     * are a different instance than the fields of the casefilter in order
     * to check if changes were made.
     */
    const updateFilter = (newCaseFilterType: CaseFilterType) => {
        const filter = formState.filter;
        if ((filter === undefined || newCaseFilterType !== filter.type)
            && (formState.name !== undefined)) {
            const updatedCaseFilter = new newCaseFilterType.class(formState.name, formState.description, newCaseFilterType)
            updatedCaseFilter.injectGraph(props.graph);
            setFormState({ ...formState, filter: updatedCaseFilter, changed: true })
        }
    }

    const [formState, setFormState] = React.useState((): {
        name: string | undefined,
        description: string | undefined,
        filter: CaseFilter | undefined,
        changed: boolean
    } => {
        const caseFilter = props.caseFilter
            ? props.caseFilter
            : undefined
        return {
            name: caseFilter ? caseFilter.name : undefined,
            description: caseFilter ? caseFilter.description : undefined,
            filter: caseFilter,
            changed: false,
        }
    });

    const setName = (name: string) => {
        setFormState({
            ...formState,
            name,
            changed: formState.changed || name !== formState.name
        })
    }

    const setDescription = (description: string) => {
        setFormState({
            ...formState,
            description,
            changed: formState.changed || description !== formState.description
        })
    }

    const setField = (key: string, field: CaseFilterField) => {
        if (formState.filter !== undefined) {
            const filter = formState.filter.cloneShallow();
            filter.fields[key] = field;
            setFormState({
                ...formState,
                filter,
                changed: formState.changed || field.value !== formState.filter.fields[key].value
            })
        }
    }

    let caseFilterComponents: React.JSX.Element[] = [];
    if (formState.filter !== undefined) {
        const filter = formState.filter;
        caseFilterComponents = Object.values(filter.fields).reduce<React.JSX.Element[]>((elements,field)=>{
            if (field.fieldDefinitionCopy.visible) {
                elements.push(field.fieldDefinitionCopy.getComponent(field, setField, intl));
            }
            return elements;
        },[])
    }

    /* render the dialog */
    return (
        <Dialog
            title={props.caseFilter
                ? intl.formatMessage({
                    id: "selecttree.addfilter",
                    defaultMessage: "Add filter",
                })
                : intl.formatMessage({
                    id: "selecttree.editfilter",
                    defaultMessage: "Edit filter"
                })
            }
            onClose={props.onClose}
            isOpen={true}
        >
            <div className={Classes.DIALOG_BODY}>
                <div style={{ "display": "grid", "gridTemplateColumns": "repeat(2, 1fr)" }}>
                    <div style={{ "gridColumn": 1 }}>
                        <FormGroup
                            helperText={intl.formatMessage({
                                id: "dialog.filter.filter-name.help",
                                defaultMessage: "The name of the filter. It will show up in the list of filters.",
                                description: "Helpertext for filter name field.",
                            })}
                            label={intl.formatMessage({
                                id: "dialog.filter.filter-name.label",
                                defaultMessage: "Name",
                                description: "Labeltext for filter name field.",
                            })}
                            labelFor="filter-name"
                            labelInfo={intl.formatMessage({
                                id: "dialog.label.required",
                                defaultMessage: "(required)",
                                description: "Label info for a required field.",
                            })}
                        >
                            <InputGroup
                                autoComplete="off"
                                id="filter-name"
                                placeholder={intl.formatMessage({
                                    id: "dialog.filter.filter-name.placeholder",
                                    defaultMessage: "Filter name",
                                    description: "Placeholder for filter name.",
                                })}
                                value={formState.name ? formState.name : ""}
                                onChange={event => {
                                    setName(event.target.value);
                                }}
                                intent={formState.name === "" ? "danger" : undefined}
                            />
                        </FormGroup>
                        <FormGroup
                            helperText={intl.formatMessage({
                                id: "dialog.filter.filter-description.help",
                                defaultMessage: "Enter a description of the filter.",
                                description: "Helpertext for filter description field.",
                            })}
                            label={intl.formatMessage({
                                id: "dialog.filter.filter-description.label",
                                defaultMessage: "Description",
                                description: "Label for filter description field.",
                            })}
                            labelFor="filter-description"
                        >
                            <textarea
                                id="filter-description"
                                className={Classes.INPUT}
                                placeholder={intl.formatMessage({
                                    id: "dialog.filter.filter-description.placeholder",
                                    defaultMessage: "Filter description",
                                    description: "Label for filter description placeholder.",
                                })}
                                style={{ fontFamily: "Arial", width: "100%" }}
                                value={formState.description ? formState.description : ""}
                                onChange={event => {
                                    setDescription(event.target.value);
                                }}
                            />
                        </FormGroup>
                    </div >
                    <div style={{ gridColumn: 2, paddingLeft: "15px" }}>
                        <FormGroup
                            helperText={formState.filter ? formState.filter.type.description(intl) : undefined}
                            label={intl.formatMessage({
                                id: "dialog.filter.filter-type.label",
                                defaultMessage: "Filter type",
                                description: "Label for filter type field.",
                            })}
                            labelFor="filter-type"
                        >
                            <Select
                                disabled={formState.name === undefined}
                                activeItem={formState.filter ? formState.filter.type : undefined}
                                filterable={false}
                                items={CaseFilterType.getTypes()}
                                popoverProps={{ minimal: true }}
                                onItemSelect={(filterType) => {
                                    updateFilter(filterType);
                                }}
                                itemRenderer={(item, { handleClick, modifiers }) => {
                                    return (
                                        <MenuItem
                                            active={modifiers.active}
                                            key={item.name(intl)}
                                            label={item.description(intl)}
                                            onClick={handleClick}
                                            text={item.name(intl)}
                                        />
                                    )
                                }}
                            >
                                <Button
                                    text={formState.filter
                                        ? `${formState.filter.type.name(intl)}`
                                        : intl.formatMessage({
                                            id: "ui.unselected",
                                            defaultMessage: "(No selection)",
                                            description: "Label for unset selection.",
                                        })}
                                    rightIcon="caret-down"
                                />
                            </Select>
                        </FormGroup>
                        {caseFilterComponents}
                    </div>
                </div >
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={() => props.onClose()}>
                        <FormattedMessage
                            id="dialog.button.cancel"
                            defaultMessage="Cancel"
                            description="Label for cancel button"
                        />
                    </Button>
                    <AnchorButton
                        intent={Intent.PRIMARY}
                        disabled={!formState.name
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            || formState.filter === undefined
                            || Object.keys(formState.filter.fields).some(key => {
                                let isOK = true;
                                if (formState.filter !== undefined) {
                                    isOK = formState.filter.fields[key].valid || !formState.filter.fields[key].fieldDefinitionCopy.visible
                                }
                                return !isOK;
                            })
                        }
                        onClick={() => {
                            if (formState.changed && formState.filter && formState.name) {
                                formState.filter.name = formState.name;
                                formState.filter.description = formState.description;
                                props.storeFilter(formState.filter)
                            }
                            else {
                                props.onClose();
                            }
                        }}
                    >
                        <FormattedMessage
                            id="dialog.filter.button.save"
                            defaultMessage="Save this filter"
                            description="Label for save filter button"
                        />
                    </AnchorButton>
                </div>
            </div>
        </Dialog >
    )
}
