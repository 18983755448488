import {
    createMachine,
    interpret,
    // assign,
    // Event,
} from 'xstate';

import log from 'loglevel'
import config from '../config.js';

/**
 * The xstate machine to control the file download
 * @todo Set/Reset the data property in context in states where no data
 * is available.
 */
const socketMachine = createMachine(
    {
        predictableActionArguments: true,
        initial: 'disconnected',
        id: 'my',
        context: {
        },
        invoke: {
            id: 'socket',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            src: (context, event) => (callback, onEvent) => {

                /**
                 * Open a web socket
                 * @returns {WebSocket}
                 */
                const open = function () {
                    const ws = new WebSocket(config.apiUrl.replace('http', 'ws') + '/ws/'); // production nginx is set up to proxy websocket connections to /ws/
                    ws.onopen = onOpen as () => void;
                    ws.onclose = onCloseWithoutTransition; // on close before "open" was received
                    ws.onerror = onError;
                    return ws;
                }

                /**
                 * Process "open" event. Put state machine into "CONNECTED" state and
                 * switch response to "close" and "message" events
                 * @param {*} event
                 */
                const onOpen = function (event: CloseEvent) {
                    callback('SOCKET_CONNECTED');
                    const ws = event.currentTarget as WebSocket;
                    ws.onclose = onCloseWithTransition; // con close after "open" was received
                    ws.onmessage = onMessage;
                    //ws.onopen = null;
                }

                /**
                 * Process message on a socket
                 * @param {string} message
                 */
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                const onMessage = function (message: MessageEvent) {
                    console.log(message);
                };

                /**
                 * Process error on a socket
                 * @param {*} event
                 */
                // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
                const onError = function (this: WebSocket, event: Event) {
                    console.error('socket error', event);
                    return;
                }

                /**
                 * Process "close" event without sending a transcation. This processing
                 * is used before an "open" event was received
                 * @param {*} event
                 */
                const onCloseWithoutTransition = function (event: CloseEvent) {
                    const ws = event.currentTarget as WebSocket;
                    ws.onopen = null;
                    ws.onclose = null;
                    ws.onerror = null;

                    // open socket again, if not a normal close
                    if (event.code !== 1000) {
                        open();
                    }
                }

                /**
                 * Process "close" event and put machine into "disconnected" state. This
                 * event processor is used for the "close" event after the "open" event was
                 * received. The purpose is to avoid transactions on the machine which
                 * cause extra rendering.
                 * @param {} event
                 */
                const onCloseWithTransition = function (event: CloseEvent) {
                    callback('SOCKET_DISCONNECTED');
                    onCloseWithoutTransition(event);
                };
                open();

                onEvent(e => {
                    log.trace(JSON.stringify(e.toJSON()));
                })
            }
        },
        states: {
            disconnected: {
                on: {
                    SOCKET_CONNECTED: {
                        target: 'connected',
                    }
                }
            },
            connected: {
                on: {
                    SOCKET_DISCONNECTED: {
                        target: 'disconnected',
                    }
                }
            },
        }
    },
    {
        guards: {
        },
        actions: {
        },
        services: {
        }
    }
)

const socketService = interpret(socketMachine).onTransition(state => {
    log.debug(`+++ socketService: ${state.value}`)
}).start();
export default socketService;
