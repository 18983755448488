import Vertex, { VertexData } from "@insight/common/graph/vertex.js";
import { GraphElementAttributes } from "@insight/common/graph/attributedgraphelement.js";
import { EventGraph } from "./eventgraph.js";
import Edge from "../graph/edge.js";
export type EventContext = Array<number>;
export type EventVertexData = VertexData & { a: number[] };

export default class EventVertex extends Vertex {

    declare graph: EventGraph<EventVertex, Edge>;
    static equals = (v1: EventVertex, v2: EventVertex) => {
        let result;
        if (v1 === null) {
            result = v2 === null;
        } else if (v2 === null) {
            result = false;
        } else {
            let i = 0;
            const a1 = v1.eventContext;
            const a2 = v2.eventContext;
            const len = a1.length;
            while (a1[i] === a2[i] && i < len) {
                i++;
            }
            result = i >= len;
        }
        return result;
    };

    static compare = (v1: EventVertex, v2: EventVertex) => {
        let result;
        if (v1 === null) {
            if (v2 === null) {
                result = 0;
            } else {
                result = -1;
            }
        } else if (v2 === null) {
            result = 1;
        } else {
            let i = 0;
            const a1 = v1.eventContext;
            const a2 = v2.eventContext;
            const len = a1.length;
            while (a1[i] === a2[i] && i < len) {
                i++;
            }
            if (i >= len) {
                result = 0;
            } else {
                result = a1[i] - a2[i];
            }
        }
        return result;
    };

    static validate(data: unknown): data is EventVertexData {
        if (super.validate(data) && "a" in data && Array.isArray(data.a)) {
            return !(data.a as Array<unknown>).find((item: unknown) => {
                return typeof item !== "number";
            })
        }
        else {
            return false;
        }
    }

    eventContext!: EventContext;

    constructor(eventTypeAncestry: EventContext, attributes: GraphElementAttributes = {}) {
        super(attributes);
        this._initEventVertex(eventTypeAncestry)
    }

    _initEventVertex(a: EventContext) {
        this.eventContext = [...a];
    }

    initFromData(data: unknown): data is EventVertexData {
        if (super.initFromData(data) &&
            EventVertex.validate(data)) {
            this._initEventVertex(data.a);
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject(): EventVertexData {
        return { ...super.__serializableObject(), a: this.eventContext }
    }

    copy(copy: EventVertex) {
        super.copy(copy);
        copy._initEventVertex([...this.eventContext]); // call #__init of EventVertex, not any derived class
        return copy;
    }

    /**
     * the id of an eventvertex is the center id of the context
     */
    get eventId() {
        return this.graph !== null?this.eventContext[this.graph!.contextCenterIndex]:-1
    }
}