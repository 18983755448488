import { AuthMethode } from "./AuthMethode.js"
import { JsonAuthStrategy } from "./JsonAuthStrategy.js";
import { AzureAuthStrategy } from "./AzureAuthStrategy.js";
import { AbstractAuthStrategy } from "./AbstractAuthStrategy.js";
import { sendRequest } from "../../classes/Request.js";
import { NoneAuthStrategy } from "./NoneAuthStrategy.js";

class AuthProviderService {

    public authMethode: AuthMethode;

    constructor() {
        this.authMethode = AuthMethode.JSON;
    }

    private async requestAuthentificationMethode(): Promise<any> {
        await sendRequest<any, any>("/authentication_strategy").then((data => {
            if (data?.strategy) {
                if (data.strategy === "Azure") {
                    console.log("Azure")
                } else {
                    console.log("JSON")
                }
            } else {
                console.log("AuthStrategy: Requested data not valid");
            }
        })).catch(err => {
            console.log("AuthStrategy: Error: ", err);
        });
    }

    public getAuthStrategy(): AbstractAuthStrategy {
        switch (this.authMethode) {
            case AuthMethode.JSON:
                return new JsonAuthStrategy(); 
            case AuthMethode.AZURE:
                return new AzureAuthStrategy();
            default: 
                return new NoneAuthStrategy();
        }
    }

    public getAuthMethode(): AuthMethode {
        return this.authMethode;
    }

    public isNoneStrategy(): boolean {
        return this.authMethode === AuthMethode.NONE;
    }

    public isJsonStrategy(): boolean {
        return this.authMethode === AuthMethode.JSON;
    }
    
    public isAzureStrategy(): boolean {
        return this.authMethode === AuthMethode.AZURE;
    }
}

let authProviderService = new AuthProviderService();
export default authProviderService;