/**
 * Three numbers as base info on a ase: id, starttime (ms since 1.1.1970), duration (ms)
 */
export type CaseInfoData = [number, number, number];

/**
 * Base info on a ase: id, starttime (ms since 1.1.1970), duration (ms)
 */
export class CaseInfo {

    static createFromData(data: unknown) {
        const _case: CaseInfo = Object.create(CaseInfo.prototype);
        _case.initFromData(data);
        return _case;
    }

    id: number;
    startTime: number;
    finishTime: number;

    constructor(id: number, startTime: number, finishTime: number) {
        this.id = id;
        this.startTime = startTime;
        this.finishTime = finishTime;
    }

    __serializableObject(): CaseInfoData {
        const diff: number = this.finishTime - this.startTime;
        return [this.id, this.startTime, diff];
    }

    initFromData(data: unknown): data is CaseInfoData {
        let duration: number;
        if (Array.isArray(data) &&
            data.length === 3 &&
            Number.isInteger(data[0]) &&
            Number.isInteger(data[1]) &&
            Number.isInteger(data[2])) {
            [this.id, this.startTime, duration] = data;
            this.finishTime = this.startTime + duration;
            return true
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }
}

