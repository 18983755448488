import DataController, { DataControllerOptions } from './DataController.js';
import Decycler, { Serializable } from "@insight/common/decycler/decycler.js";
import setClassName from '../../helpers/setClassName.js';
import { CaseFilter } from '../case_filter/CaseFilter.js';
import { CaseFilterCaseAttribute } from '../case_filter/CFCaseAttributes.js';
import { CaseFilterEventTypes } from '../case_filter/CFEventTypes.js';
import { CaseFilterEventCount } from '../case_filter/CFEventCount.js';
import { CaseFilterRunTime } from '../case_filter/CFRunTime.js';
import { CaseFilterUnion } from '../case_filter/CFUnion.js';
import { CaseFilterIntersection } from '../case_filter/CFntersection.js';

CaseFilterEventTypes;
CaseFilterCaseAttribute;
CaseFilterEventCount;
CaseFilterRunTime;
CaseFilterUnion;
CaseFilterIntersection;

class CaseFiltersController extends DataController<CaseFilter[]> {

    constructor(options: DataControllerOptions) {
        super([], options);
    }

    static getDecycler() {
        const decycler = new Decycler();
        return decycler;
    }

    toJSON() {
        const decycler = CaseFiltersController.getDecycler();
        const result = decycler.decycle(this.data);
        return result;
    }

    fromJSON(pojso: Serializable): CaseFilter[] {
        const decycler = CaseFiltersController.getDecycler();
        const result: CaseFilter[] = decycler.retrocycle(pojso) as CaseFilter[];
        let id = Date.now();
        function assignIds(filters: CaseFilter[]) {
            filters.forEach(filter => {
                if (filter.id === undefined) {
                    filter.id = id++;
                }
                assignIds(filter.children);
            })
        }
        assignIds(result);
        return result as CaseFilter[];
    }
}
setClassName(CaseFiltersController, "CaseFiltersController"); // for minifying purposes
export default CaseFiltersController;