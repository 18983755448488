import React from "react";
import { DragEvent } from "react";
import { CaseFilterContextMenu } from "./CaseFilterContextMenu.js";
import { CaseFilter } from "../../classes/case_filter/CaseFilter.js";
import { useIntl } from "react-intl";
import { Icon } from "@blueprintjs/core";

export type DropHandler = { (item: CaseFilter, position: DragPositions, target: CaseFilter): void }
export type DragPositions = false | "above" | "below" | "center";

export interface LabelProps {
    filter: CaseFilter;
    handleDrop: DropHandler;
}

interface LabelState {
    dragging: boolean,
    selected: boolean,
    expanded: boolean,
    targeted: DragPositions
}

let draggedFilter: CaseFilter | null = null;

export function SelectTreeLabel(props: LabelProps) {

    const [state, setState] = React.useState<LabelState>({ dragging: false, selected: false, expanded: false, targeted: false })
    const intl = useIntl(); // https://formatjs.io/docs/react-intl/api/#useintl-hook

    function dropAllowed(e: DragEvent) {
        return e.dataTransfer.types.includes("application/casefilter") &&
            draggedFilter !== null &&
            draggedFilter !== props.filter &&
            // draggedFilter.parent !== props.filter &&
            !draggedFilter.children.includes(props.filter)
    }

    function positionToTarget(e: DragEvent): DragPositions {
        let position: DragPositions = false;
        if (e.nativeEvent.target !== null) {
            const r = (e.nativeEvent.target as Element).getBoundingClientRect();
            if (props.filter.type.childrenAllowed && draggedFilter !== null && draggedFilter.parent !== props.filter) {
                position = e.nativeEvent.y > r.y + (2 * r.height / 3) ?
                    "below" :
                    e.nativeEvent.y > r.y + r.height / 3 ?
                        "center" :
                        "above"
            }
            else if (props.filter.parent && props.filter.parent.children.findIndex(f => props.filter === f) == 0) {
                position = r.y + r.height / 2 < e.nativeEvent.y ?
                    "below" :
                    "above"
            }
            else {
                position = "below";
            }
        }
        return position;
    }

    const handleDragStart: React.DragEventHandler = (e) => {
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("application/casefilter", "xxx")
        draggedFilter = props.filter;
        setState({ ...state, dragging: true });
    }

    const handleDragEnd: React.DragEventHandler = () => {
        setState({ ...state, dragging: false });
    }

    const handleDragOver: React.DragEventHandler = (e: DragEvent & MouseEvent) => {
        if (dropAllowed(e)) {
            e.preventDefault();
            // let r = (e.nativeEvent.target! as Element).getBoundingClientRect();
            setState({ ...state, targeted: positionToTarget(e) });
        }
    }

    const handleDragLeave: React.DragEventHandler = () => {
        setState({ ...state, targeted: false })
    }

    const handleDrop: React.DragEventHandler = (e) => {
        setState({ ...state, targeted: false })
        if (draggedFilter !== null) {
            props.handleDrop(draggedFilter, positionToTarget(e), props.filter)
            draggedFilter = null;
        }
    }

    return <div
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
            opacity: state.dragging ? 0.4 : 1.0,
            borderTop: state.targeted === "above" ? "dashed 1px gray" : undefined,
            borderBottom: state.targeted === "below" ? "dashed 1px gray" : undefined,
            backgroundColor: state.targeted === "center" ? "lightgrey" : undefined,
        }}
    >
        <CaseFilterContextMenu filter={props.filter}>
            {props.filter.type.icon}
            <span className="filter-name">{props.filter.name}</span>
            <span className="filter-description">{props.filter.description}</span>
            <span className="filter-label">({props.filter.label(intl)})</span>
        </CaseFilterContextMenu>
    </div>
}
