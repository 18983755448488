import React from 'react'
import * as Flags from 'country-flag-icons/react/3x2';
import { useActor } from '@xstate/react';
import { localizationService } from '../../../classes/LocaleStateMachine.js';

import {
    Button,
    Classes,
    Icon,
    Menu,
    MenuItem,
    Popover,
    Position
} from '@blueprintjs/core';

export const LanguagePopover = () => {

    const [locale_state, send] = useActor(localizationService);

    const locale = locale_state.context.locale;

    const localeFlags = {
        'de': <Flags.DE className="flag-icon" />,
        'en': <Flags.GB className="flag-icon" />,
    }

    const langItems = Object.keys(localeFlags).map((locale) => {
        return (
            <MenuItem text="" key={locale} icon={localeFlags[locale as 'de' | 'en']} onClick={() => {
                send({ type: 'select', locale })
            }} />
        )
    })

    return (
        <Popover
            content={<Menu>{langItems}</Menu>} minimal={true} position={Position.TOP_LEFT}>
            <Button
                className={Classes.MINIMAL}
            >
                <Icon icon="translate" />
                <Icon icon={localeFlags[locale]} />
            </Button>
        </Popover>
    )
}
