import React from "react";
import { ContextMenu, Menu, MenuItem } from "@blueprintjs/core";
import { useIntl } from "react-intl";
import { filterManagerContext } from "./FilterManager.js";
import { CaseFilter } from "../../classes/case_filter/CaseFilter.js";

export type CaseFilterContextMenuProps = {
    filter: CaseFilter
} & React.PropsWithChildren;

export function CaseFilterContextMenu(props: CaseFilterContextMenuProps) {
    const intl = useIntl();
    const filterManager = React.useContext(filterManagerContext);

    return <ContextMenu
        style={{ display: "flex" }}
        onContextMenu={event => {
            event.stopPropagation();
        }}
        content={
            <Menu>
                <MenuItem
                    text={intl.formatMessage({
                        id: "filter.ui.add_filter",
                        defaultMessage: "Add Filter",
                        description: "Menu label to add a filter",
                    })}
                    onClick={() => {
                        filterManager.createFilter();
                    }}
                    disabled={!props.filter.type.childrenAllowed}
                />
                <MenuItem
                    text={intl.formatMessage({
                        id: "filter.ui.edit_filter",
                        defaultMessage: "Edit...",
                        description: "Menu label to edit a filter",
                    })}
                    onClick={() => {
                        filterManager.editFilter(props.filter);
                    }}
                />
                <MenuItem
                    text={intl.formatMessage({
                        id: "filter.ui.delete_filter",
                        defaultMessage: "Delete. ...",
                        description: "Menu label to add a filter",
                    })}
                    intent="danger"
                    onClick={() => {
                        filterManager.removeFilter(props.filter)
                    }}
                    disabled={props.filter.children.length > 0}
                />
            </Menu>
        }
    >
        {props.children}
    </ContextMenu>

}