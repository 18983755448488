import DataController from './DataController.js';
import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';
import setClassName from '../../helpers/setClassName.js';
import { Serializable } from '@insight/common/decycler/decycler.js';

/**
 * Reminder: the class name determines the index into the array of filenames in the DataProvider class
 * which are requested first.
 */
class GraphController extends DataController<DotGraph> {
    constructor() {
        super(new DotGraph());
    }

    fromJSON(pojso: Serializable): DotGraph {
        return DotGraph.createFromData(pojso)
    }
}
setClassName(GraphController, "GraphController");
export default GraphController;
