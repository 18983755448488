import React, { useState } from 'react';
import * as Flags from 'country-flag-icons/react/3x2';
import { useIntl } from 'react-intl';

import { useActor } from '@xstate/react';
import log from "./Logger.js";

import {
    Alignment,
    Button,
    Classes,
    Drawer,
    Navbar,
    NavbarGroup,
    Position,
} from '@blueprintjs/core';

import CaseFiltersController from '../classes/data_provision/CaseFiltersController.js';
import GraphController from '../classes/data_provision/GraphController.js';
import VariantsController from '../classes/data_provision/VariantsController.js';

import DataProvider from '../classes/data_provision/DataProvider.js';
import { Menuframe } from './Base/Menuframe.js';
import { ThemeProvider } from "styled-components";
import displayModeService from '../classes/displayModeService.js';
import { sendRequest } from '../classes/Request.js';
import socketService from '../classes/socketService.js';
import { DatasetSelectionDialog } from './DatasetSelectionDialog/DatasetSelectionDialog.js';
import Project from '@insight/common/interface/Project.js';
import { Activity } from './Base/Activity.js';

import { CaseFilterRunTime } from '../classes/case_filter/CFRunTime.js';
import { CaseFilterEventCount } from '../classes/case_filter/CFEventCount.js';
import { SetProgressContext } from './ProgressOverlay.js';
import { LanguagePopover } from './Base/language_popover/LanguagePopover.js';
import { MainDrawerContent } from './Base/main-drawer/MainDrawerContent.js';
import authProviderService from '../authentication/strategy/AuthProviderService.js';
import { BaseplateAuthStrategyComponentProvider } from './authentication/BaseplateAuthStrategyComponentProvider.js';


/* eslint-disable @typescript-eslint/no-unused-vars */
CaseFilterRunTime;
CaseFilterEventCount;
/* eslint-enable @typescript-eslint/no-unused-vars */


/**
 * intermediate fixed definition of the project.
 */
const dataProvider = new DataProvider(
    {
        graphController: new GraphController(),
        variantsController: new VariantsController(),
        caseFiltersController: new CaseFiltersController({ saveable: true })
    }
);

export interface BaseplateProperties {
    pushMenu: boolean;
}

export default function Baseplate(props: BaseplateProperties) {

    log.debug(`*** *** Render Baseplate`);

    const [drawerOpen, setDrawer] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [socketState] = useActor(socketService);

    const authStrategy = authProviderService.getAuthStrategy();
    const isAuthenticatedAzure = authStrategy.isAuthenticated()

    const toggleDrawer = () => {
        setDrawer(!drawerOpen);
    }

    const closeDrawer = () => {
        setDrawer(false);
    }

    const theme = {
        color: '#FFFFFF',
        disabledColor: '#909090',
        backGroundColor: "#242424",
        borderColor: '#D0D0D0',
    };

    const rtl = false;
    const intl = useIntl();


    const toggleDisplay = () => {
        displayModeService.send('TOGGLE');
    }

    const closeServer = () => {
        sendRequest("/close", {
            method: "post",
        }).catch((err) => {
            log.error(err); /** @tody: improve error handling */
        })
    }

    const logout = () => {
        authStrategy.logout();
    }

    const login = () => {
        authStrategy.login();
    }

    const progressOverlay = React.useContext(SetProgressContext);
    const handleSelectDataset = (p: Project) => {
        setDialogOpen(false);
        dataProvider.service.send('load', { project: p, setProgress: progressOverlay?.setProgress });
    }

    return (
        <ThemeProvider theme={theme}>
            {dialogOpen && <DatasetSelectionDialog
                onClose={() => setDialogOpen(false)}
                onSelectDataset={handleSelectDataset}
            />}
            <Menuframe id="app" className={`${rtl ? 'rtl' : ''} ${drawerOpen ? 'menu-open' : ''} ${props.pushMenu ? 'push-menu' : ''}`}>
                <Navbar>
                    <NavbarGroup align={Alignment.LEFT}>
                        <Button
                            className={Classes.MINIMAL}
                            icon="menu"
                            onClick={toggleDrawer}
                            disabled={!isAuthenticatedAzure}
                        />
                        <Button
                            disabled={progressOverlay === null || socketState.matches('disconnected') || !isAuthenticatedAzure}
                            className={Classes.MINIMAL}
                            onClick={() => setDialogOpen(true)}
                            icon="document-open"
                            text={intl.formatMessage({
                                defaultMessage: "Load Processes",
                                description: "Menu action label to load processes from server."
                            })}
                        />
                        <Button
                            className={Classes.MINIMAL}
                            onClick={toggleDisplay}
                            icon="modal"
                            text={intl.formatMessage({
                                defaultMessage: "Toggle single/dual view",
                                description: "Menu action label to toggle single/dual view.",
                            })}
                            disabled={!isAuthenticatedAzure}
                        />
                        {/* <Button
                            className={Classes.MINIMAL}
                            onClick={closeServer}
                            icon="power"
                            text={intl.formatMessage({
                                defaultMessage: "Close Server",
                                description: "Menu action to shut down view server."
                            })}
                            disabled={socketState.matches('disconnected')}
                        /> */}
                    </NavbarGroup>
                    <NavbarGroup className="bp5-align-center app-title">
                        InSight V1.5
                    </NavbarGroup>

                    <NavbarGroup align={Alignment.RIGHT}>
                        <Activity />
                        {isAuthenticatedAzure ? (
                        <Button
                            className={Classes.MINIMAL}
                            onClick={logout}
                            icon="log-out"
                            text={intl.formatMessage({
                                defaultMessage: "Logout",
                                description: "Menu action label to log out the user from the server."
                            })}
                        />
                        ) : (
                            <Button
                                className={Classes.MINIMAL}
                                onClick={login}
                                icon="log-in"
                                text={intl.formatMessage({
                                    defaultMessage: "Login",
                                    description: "Menu action label to log out the user from the server."
                                })}
                            />
                        )}
                        <LanguagePopover/>
                    </NavbarGroup>
                </Navbar>
                <Drawer
                    title="My Drawer"
                    hasBackdrop={!props.pushMenu}
                    isOpen={drawerOpen}
                    onClose={closeDrawer}
                    canOutsideClickClose={false}
                    position={Position.LEFT}
                    enforceFocus={!props.pushMenu}
                    usePortal={false}
                    canEscapeKeyClose={!props.pushMenu}
                    transitionDuration={500}>

                    <MainDrawerContent intl={intl}/>
                </Drawer>
                    <BaseplateAuthStrategyComponentProvider intl={intl} dataProvider={dataProvider}/>
            </Menuframe>
        </ThemeProvider>
    );

}
