import Graph, { GraphData } from "@insight/common/graph/graph.js";
import EventVertex from "./eventvertex.js";
import Edge from "../graph/edge.js";
export type EventGraphData = GraphData & { ci: number };

export class EventGraph<V extends EventVertex, E extends Edge> extends Graph<V, E> {
    static validate(data: unknown): data is EventGraphData {
        return super.validate(data) &&
            "ci" in data &&
            Number.isInteger(data.ci)
    }
    contextCenterIndex!: number; // the index of the center vertex in a vertex context

    constructor(ci: number) {
        super();
        this._initEventGraph(ci)
    }

    _initEventGraph(ci: number) {
        this.contextCenterIndex = ci;
    }

    __serializableObject(): EventGraphData {
        return {
            ...super.__serializableObject(),
            ci: this.contextCenterIndex
        }
    }

    initFromData(data: unknown): data is EventGraphData {
        if (super.initFromData(data) && EventGraph.validate(data)) {
            this.contextCenterIndex = data.ci;
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    copy(copy: EventGraph<V,E> | null = null) {
        if (copy === null) {
            copy = Object.create(this) as EventGraph<V,E>;
        }
        super.copy(copy);
        copy.contextCenterIndex = this.contextCenterIndex
    }

}
