import * as React from 'react';
import * as d3 from "d3";
import * as d3GV from 'd3-graphviz';

import { useEffect, useRef } from 'react';

import { DotGraph } from '@insight/common/dot_graph/dotgraph.js';
import { DotEdgeMode } from "@insight/common/dot_graph/dotedge.js";

// see https://github.com/magjac/d3-graphviz/issues/94 - this makes sure webpack bundles d3-graphviz
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _ = d3GV.graphviz //Preload d3Graphiz so it registers itself in d3 as a plugin

interface IGraphvizProps {
    /**
     * A string containing a graph representation using the Graphviz DOT language.
     * @see https://graphviz.org/doc/info/lang.html
     */
    graph: DotGraph;
    /**
     * Options to pass to the Graphviz renderer.
     */
    options?: d3GV.GraphvizOptions
    /**
     * The classname to attach to this component for styling purposes.
     */
    className?: string;

    nodeMouseEnter: EventListener;
    nodeMouseLeave: EventListener;
    nodeMouseClick?: EventListener;
    edgeMouseEnter?: EventListener;
    edgeMouseLeave?: EventListener;
    edgeMouseClick: EventListener;
    mode: DotEdgeMode;
}

const Graphviz = (props: IGraphvizProps) => {
    const firstRender = useRef(true);
    const graphElement = useRef<HTMLDivElement | null>(null);

    /**
     * run this when props.graph or props options change
     * @todo: determine types
     */
    useEffect(() => {
        console.log("*** do Layout");
        const elem = (d3
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .select(graphElement.current) as any)
            .graphviz(props.options);

        if (!firstRender.current) {
            elem.resetZoom();
            // apply asynchronously according to https://github.com/magjac/d3-graphviz#creating-transitions
            // as a workaround for https://github.com/magjac/d3-graphviz/issues/201 triggering "transition 8 not found"
            elem.transition(() => d3.transition().duration(1500));
        }

        /**
         * run the following when the Graphviz renderer has finished all actions.
         */
        elem.on('end', () => {
            if (graphElement.current) {
                const eSVG = graphElement.current.getElementsByTagName("svg")[0];
                if (eSVG) {
                    // const div: HTMLDivElement | null = eSVG.closest('div');
                    const eG = eSVG.getElementsByTagName("g")[0];
                    if (eG) {
                        /**
                         * setup mouse events for interacting with vertices and edges
                         */
                        const nodeEnter = props.nodeMouseEnter;
                        const nodeLeave = props.nodeMouseLeave;
                        const nodeClick = props.nodeMouseClick;
                        if (nodeEnter || nodeLeave || nodeClick) {
                            const nodes = graphElement.current.getElementsByClassName("node");
                            for (const node of nodes) {
                                if (nodeEnter) {
                                    node.removeEventListener("mouseenter", nodeEnter);
                                    node.addEventListener("mouseenter", nodeEnter);
                                }
                                if (nodeLeave) {
                                    node.removeEventListener("mouseleave", nodeLeave);
                                    node.addEventListener("mouseleave", nodeLeave);
                                }
                                if (nodeClick) {
                                    node.removeEventListener("click", nodeClick);
                                    node.addEventListener("click", nodeClick);
                                }
                            }
                        }

                        const edgeEnter = props.edgeMouseEnter;
                        const edgeLeave = props.edgeMouseLeave;
                        const edgeClick = props.edgeMouseClick;
                        if (edgeEnter || edgeLeave || edgeClick) {
                            const edges = graphElement.current.getElementsByClassName("edge");
                            for (const edge of edges) {
                                if (edgeEnter) {
                                    edge.addEventListener("mouseenter", edgeEnter);
                                    edge.addEventListener("mouseenter", edgeEnter);
                                }
                                if (edgeLeave) {
                                    edge.removeEventListener("mouseleave", edgeLeave);
                                    edge.addEventListener("mouseleave", edgeLeave);

                                }
                                if (edgeClick) {
                                    edge.removeEventListener('click', edgeClick);
                                    edge.addEventListener('click', edgeClick);
                                }
                            }
                        }
                    }
                }
            }
            firstRender.current = false;
        })
        elem.renderDot(props.graph.toDot({}, { mode: props.mode }));
    }, [props.graph, props.options]);

    return <div className={props.className} ref={graphElement} />;
};

export { Graphviz, IGraphvizProps };
export default Graphviz;

