/**
 * Authentication requests
 */

export interface Credentials {
    userid: string;
    pw: string;
}

export function isCredentials(obj: unknown): obj is Credentials {
    return typeof obj === "object" &&
        obj !== null &&
        "userid" in obj && typeof obj.userid === "string" &&
        "pw" in obj && typeof obj.pw === "string";
}

/**
 * general types used in authentication responses
 */

export interface InvalidityInformation {
    _msg: string;
    value: string;
}

export function isInvalidityInformation(obj: unknown): obj is InvalidityInformation {
    return typeof obj === "object" &&
        obj !== null &&
        "_msg" in obj && typeof obj._msg === "string" &&
        "value" in obj && typeof obj.value === "string";
}

export interface InvalidInput {
    _msg: string;
    userid?: InvalidityInformation;
    pw?: InvalidityInformation;
}

export function isInvalidInput(obj: unknown): obj is InvalidInput {
    return typeof obj === "object" &&
        obj !== null &&
        "msg" in obj &&
        (("userid" in obj) ? isInvalidityInformation(obj.userid) : true) &&
        (("pw" in obj) ? isInvalidityInformation(obj.pw) : true)
}

/**
 * Authentication responses
 */
export type AuthError500Response = {
    error: string;
}

export function isAuthError500Response(obj: unknown): obj is AuthError500Response {
    return typeof obj === "object" &&
        obj !== null &&
        'error' in obj &&
        typeof obj.error === 'string';
}

export type AuthError400Response = {
    error: true;
    invalidInput: InvalidInput;
}

export function isAuthError400Response(obj: unknown): obj is AuthError400Response {
    return typeof obj === "object" &&
        obj !== null &&
        'error' in obj &&
        typeof obj.error === 'boolean' &&
        obj.error === true;
}

export type Auth200ResponseData = { jwt: string };
export type Auth200Response = {
    status: 200;
    data: Auth200ResponseData;
}


export function isAuth200Response(obj: unknown): obj is Auth200Response {
    return typeof obj === "object" &&
        obj !== null &&
        "data" in obj &&
        typeof obj.data === "object" &&
        obj.data !== null &&
        'jwt' in obj.data &&
        typeof obj.data.jwt === 'string' &&
        "status" in obj &&
        obj.status === 200;
}

export type AuthResponse =
    | AuthError500Response
    | AuthError400Response
    | Auth200Response
    | void;

export function isAuthenticationResponse(res: unknown): res is AuthResponse {
    return isAuthError500Response(res) ||
        isAuthError400Response(res) ||
        isAuth200Response(res);
}