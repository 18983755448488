import React from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import GraphDisplay from '../graph/GraphDisplay2.js';
import { GraphvizOptions } from 'd3-graphviz';
import { GraphInfo } from './GraphInfo.js'
import {
    Alignment,
    ButtonGroup,
    Button,
    Card,
    Checkbox,
    Classes,
    Dialog,
    Divider,
    Icon,
    Label,
    Slider,
    Menu,
    Navbar,
    NavbarGroup,
    MenuItem,
    Position,
    Popover,
    H5,
    Callout,
    PopoverInteractionKind,
} from '@blueprintjs/core';

import socketService from '../../classes/socketService.js';
import { DotGraph, SimplificationParameters } from '@insight/common/dot_graph/dotgraph.js';
import { downloadBlob, downloadSVG } from '../../classes/download.js';
import { sendRequest } from '../../classes/Request.js';
import Project from '@insight/common/interface/Project.js';
import { CaseSelection } from '../View/DataSelectionStateMachine.js';
import { ISEEventCategory } from '@insight/common/ISECase/ISEEventCategory.js';

export interface InteractiveGraphProps {
    project: Project;
    graph: DotGraph,
    onSelect: (parameters: CaseSelection) => void;
    invertCaseSelection: () => void;
    onBack: () => void,
    onFwd: () => void,
    backPossible: boolean,
    fwdPossible: boolean,
    intl: IntlShape
}

interface GraphOptions extends SimplificationParameters {
    integrate: boolean; // if true, integrate events with same destinations event types
    eventCategories: ISEEventCategory[]; // shown event categories
    debug: boolean; // if true, show debug information on edges and vertices
}

interface DisplayOptions {
    mode: 'avg' | 'min' | 'max';
    serverAvailable: boolean;
    showEdgeData: boolean;
    showVertexData: boolean;
}

interface InteractiveGraphState {
    graph: DotGraph; // the graph passed from View component
    simpleGraph: DotGraph; // the simplified graph
    options: GraphOptions & DisplayOptions;
    optionsOpen?: boolean;
    popover: {
        eventId: number,
        isOpen: boolean,
        top: number,
        left: number,
    }
}

const defaults: GraphvizOptions = {
    scale: 1,
    tweenPrecision: 5,
    engine: 'dot',
    keyMode: 'title',
    convertEqualSidedPolygons: false,
    fade: true,
    growEnteringEdges: true,
    fit: true,
    tweenPaths: true,
    tweenShapes: true,
    useWorker: false,
    zoom: true,
};

function maxSliderValue(graph:DotGraph) {
    return Math.max(graph.properties.maxEdgeTraversals * 0.20, 1);
}

class InteractiveGraph extends React.Component<InteractiveGraphProps, InteractiveGraphState> {
    static iag_id = 1;

    id: number;
    graphRef: React.RefObject<HTMLDivElement>
    popOverRef: React.RefObject<HTMLDivElement>

    constructor(props: InteractiveGraphProps) {
        super(props)
        this.state = InteractiveGraph.updateGraph({
            graph: props.graph,
            simpleGraph: props.graph.copy(),
            options: {
                minVertexTraversals: 1,
                minEdgeTraversals: 1,
                singleFinish: false,
                integrate: true,
                eventCategories: ["i", "m"],
                mode: 'avg',
                debug: false,
                serverAvailable: false,
                showEdgeData: false,
                showVertexData: false,
                removeEmptyElements: true,
            },
            popover: {
                eventId: -1,
                isOpen: false,
                top: 0,
                left: 0,
            }
        });
        this.id = InteractiveGraph.iag_id++;
        this.graphRef = React.createRef<HTMLDivElement>();
        this.popOverRef = React.createRef<HTMLDivElement>();
    }

    static updateGraph(state: InteractiveGraphState) {
        const graph = state.graph;
        if (graph) {
            const graphCopy = graph.copy();
            graphCopy.simplify(state.options); // remove edges and vertices according to sliders
            graphCopy.debug = state.options.debug;
            if (state.options.integrate) graphCopy.integrate({ singleFinish: state.options.singleFinish });
            graphCopy.filterToCategory(state.options.eventCategories);
            graphCopy.showEdgeData = state.options.showEdgeData;
            graphCopy.showVertexData = state.options.showVertexData;
            graphCopy.updateProperties();
            return {
                ...state,
                simpleGraph: graphCopy,
            };
        }
        else {
            return {
                ...state,
                simpleGraph: graph,
            };
        }
    }

    static getDerivedStateFromProps(nextProps: InteractiveGraphProps, currentState: InteractiveGraphState) {
        let result = null;
        if (nextProps.graph !== currentState.graph) {

            const label_max = maxSliderValue(nextProps.graph);

            result = InteractiveGraph.updateGraph({
                ...currentState,
                options: {
                    ...currentState.options,
                    minEdgeTraversals: currentState.options.minEdgeTraversals > label_max
                        ? 1
                        : currentState.options.minEdgeTraversals,
                    minVertexTraversals: currentState.options.minVertexTraversals > label_max
                        ? 1
                        : currentState.options.minVertexTraversals
                },
                graph: nextProps.graph,
            })
        }
        return result;
    }

    /** @todo: find out correct types */
    // socketListener: StateListener<unknown,AnyEventObject> = (sockState:any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socketListener = (sockState: any) => {
        const newState = { ...this.state };
        newState.options.serverAvailable = sockState.matches('connected');
        this.setState(newState)
    }

    componentDidMount() {
        socketService.onTransition(this.socketListener)
    }

    componentWillUnmount() {
        socketService.off(this.socketListener);
    }

    /* --------------------------------------------------------------------------------
       E V E N T   H A N D L E R S
       -------------------------------------------------------------------------------- */

    releaseSliderButton = () => {
        this.setState(InteractiveGraph.updateGraph(this.state));
    }

    updateMinVertexTraversals = (value: number) => {
        /* see comment for updateMinEdgeTraversals */
        if (this.state.options.minVertexTraversals === 1 && value > 1) {
            value--;
        }
        const newState = { ...this.state };
        newState.options.minVertexTraversals = value;
        this.setState(newState);
    }

    updateMinEdgeTraversals = (value: number) => {
        /* if previous value was one and now the value is > 1, we subtract
        1 from the value as the slider adds the step size which would walk it
        from, for example, 1 to 101 at a step size of 100 and we would want it
        to go to 100 instead. After the first step, all is allright. */
        if (this.state.options.minEdgeTraversals === 1 && value > 1) {
            value--;
        }
        const newState = { ...this.state };
        newState.options.minEdgeTraversals = value;
        this.setState(newState);
    }

    visitGraphNodes = (svgElement: EventTarget | null, visitCB: (node: Element) => void) => {
        if (svgElement instanceof SVGElement) {
            const elems = svgElement.getElementsByTagName('title');
            if (elems !== null && elems[0].textContent) {
                const markerTitle = elems[0].textContent.split('_')[0];
                const graphElement = elems[0].closest('.graph-display');
                if (graphElement !== null) {
                    InteractiveGraph.styleNodes(graphElement, markerTitle, visitCB)
                }
            }
            else {
                throw new Error(`Node not found`);
            }
        }
    }

    /**
     * Set 'hoover' class on nodes with the same id than that which has been entered by the mouse
     * @param {Event} event
     * @param {DOMElement} node
     * @param {DOMElement} graphElement
     * @param {DotGraph} graph
     */
    mouseEntersNode: EventListener = (event: Event) => {
        this.visitGraphNodes(event.target, (node) => {
            const _class = node.getAttribute('class');
            const mouseEvent = (event as unknown as MouseEvent);
            if (_class !== null) {
                node.setAttribute('class', (_class ? _class : '') + ' hoover');
                if (mouseEvent.target instanceof SVGGElement) {
                    const g = mouseEvent.target;
                    const svg = g.ownerSVGElement;
                    if (svg !== null) {
                        const point = svg.createSVGPoint();
                        const matrix = g.getScreenCTM();
                        if (matrix !== null) {
                            const bbox = g.getBBox();
                            point.x = bbox.x + bbox.width + 10;
                            point.y = bbox.y + bbox.height / 2;
                            const pointInDiv = point?.matrixTransform(matrix);
                            if (pointInDiv !== undefined) {
                                const div = svg?.parentElement as HTMLDivElement;
                                const divRect = div.getBoundingClientRect();
                                const { x, y } = { x: pointInDiv.x - divRect.left, y: pointInDiv.y - divRect.top }

                                const elements = mouseEvent.target.getElementsByTagName("a")
                                if (elements.length === 1) {
                                    const a = elements.item(0);
                                    if (a !== null) {
                                        const __id = this.getGraphElementId(a);
                                        if (__id !== null) {
                                            const simpleGraphVertex = this.state.simpleGraph.vertices.array.find(v => v.__id === __id);
                                            if (simpleGraphVertex) {
                                                this.setState({
                                                    ...this.state,
                                                    popover: {
                                                        isOpen: true,
                                                        eventId: simpleGraphVertex.eventId,
                                                        top: y,
                                                        left: x
                                                    }
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else {
                throw new Error(`class attribute not found`);
            }
        })
    }

    mouseLeavesNode: EventListener = (event: Event) => {
        this.visitGraphNodes(event.target, (node) => {
            const _class = node.getAttribute('class');
            if (_class !== null) {
                node.setAttribute('class', _class.replace('hoover', '').trim());
                this.setState({ ...this.state, popover: { ...this.state.popover, isOpen: false } })
            }
            else {
                throw new Error(`class attribute not found`);
            }
        })
    }

    private getGraphElementId(element: EventTarget | null): number | null {
        let id: number | null = null;
        let sId: string | null = null;
        if (element instanceof SVGElement) {
            const a: Element | null = element.closest('a');
            if (a !== null) {
                let href = a.getAttribute('xlink:href'); // Graphviz 8.05
                if (href === null) {
                    href = a.getAttribute('href'); // Graphviz 7.05
                }
                sId = (new URL("http://a.b" + href)).searchParams.get('__id');
            }
            if (sId !== null) {
                id = parseInt(sId);
            }
        }
        return id;
    }

    clickOnEdge: EventListener = (event: Event) => {
        const __id = this.getGraphElementId(event.target);
        if (__id !== null) {
            const graphEdge = this.state.simpleGraph.edges.array.find(edge => edge.__id === __id);
            if (graphEdge !== undefined) {
                const caseIds = graphEdge.cases.array.map(c => c.id);
                this.props.onSelect({ caseIds, controlId: "interactive_graph", controlState: null });
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    clickOnNode: EventListener = (event: Event) => {
        /** the target will be a visible child of the group node, i.e. text or path */
        const __id = this.getGraphElementId(event.target);
        if (__id !== null) {
            const graphVertex = this.state.simpleGraph.vertices.array.find(v => v.__id === __id);
            if (graphVertex !== undefined) {
                let edges = graphVertex.incoming;
                if (edges.length === 0) edges = graphVertex.outgoing; // for start nodes
                const caseIds = edges.reduce<number[]>((r, edge) => {
                    r.push(...edge.cases.array.map(c => c.id));
                    return r;
                }, [])
                this.props.onSelect({ caseIds, controlId: "interactive_graph", controlState: null });
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    /**
     * A helper function to find all SVG nodes (representing vertexes of the process graph) which
     * have the same node id but different sources.
     * @param {DOMElement} graphElement The element selected in the SVG
     * @param {string} markerTitle The first part of the element title to search for (before '_')
     * @param {Function} styleFunction The function to style the element
     */
    static styleNodes(graphElement: Element, markerTitle: string, styleFunction: (node: Element) => void) {
        const nodes = graphElement.getElementsByClassName('node');
        for (const node of nodes) {
            const elems = node.getElementsByTagName('title');
            if (elems !== null && elems[0].textContent) {
                const title = elems[0].textContent.split('_')[0];
                if (title === markerTitle) {
                    styleFunction(node);
                }
            }
            else {
                throw new Error(`<title> Element not found`);
            }
        }
    }

    openOptions = () => {
        this.setState({
            ...this.state,
            optionsOpen: true,
        })
    }

    closeOptions = () => {
        this.setState({
            ...this.state,
            optionsOpen: false,
        })
    }

    handleSingleFinishChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const state = {
            ...this.state,
        }
        state.options.singleFinish = event.target.checked;
        this.setState(InteractiveGraph.updateGraph(state));
    }

    handleModeChange = (event: React.FormEvent<HTMLInputElement>) => {
        const state = {
            ...this.state,
        }
        state.options.mode = event.currentTarget.value as "avg" | "min" | "max";
        this.setState(InteractiveGraph.updateGraph(state));
    }

    handleRemoveEmptyElements = (event: React.ChangeEvent<HTMLInputElement>) => {
        const state = {
            ...this.state,
        }
        state.options.removeEmptyElements = event.target.checked;
        this.setState(InteractiveGraph.updateGraph(state));
    }

    handleIntegrateBeforeSimplification = (event: React.ChangeEvent<HTMLInputElement>) => {
        const state = {
            ...this.state,
        }
        state.options.integrate = event.target.checked;
        this.setState(InteractiveGraph.updateGraph(state));
    }

    showEventCategory(cat: ISEEventCategory, show: boolean) {
        const state = {
            ...this.state,
        }
        const s = new Set<ISEEventCategory>(state.options.eventCategories);
        if (show) s.add(cat); else s.delete(cat);
        state.options.eventCategories = Array.from(s);
        this.setState(InteractiveGraph.updateGraph(state));
    }

    handleDebugDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
        const state = {
            ...this.state,
        }
        state.options.debug = event.target.checked;
        this.setState(InteractiveGraph.updateGraph(state));
    }

    handleEdgeDataDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
        const state = {
            ...this.state,
        }
        state.options.showEdgeData = event.target.checked;
        this.setState(InteractiveGraph.updateGraph(state));
    }

    handleVertexDataDisplay = (event: React.ChangeEvent<HTMLInputElement>) => {
        const state = {
            ...this.state,
        }
        state.options.showVertexData = event.target.checked;
        this.setState(InteractiveGraph.updateGraph(state));
    }

    downloadSelectedCases = () => {
        if (this.state.simpleGraph) {
            const caseIds = this.state.simpleGraph.casesInfo.array.flatMap(_case => [_case.id]);

            const query = new URLSearchParams({
                eventsfile: this.props.project.eventsFilePath,
            });

            sendRequest<Blob, number[]>("cases_csv?" + query.toString(), {
                method: "post",
                data: caseIds,
                responseType: "blob"
            }).then(blob => downloadBlob(blob, "cases.csv"));
        }
        else {
            throw new Error(`simplegraph is null`);
        }
    }

    downloadGraphSVG = () => {
        if (this.graphRef && this.graphRef.current) {
            let graph: SVGSVGElement = this.graphRef.current.getElementsByClassName('graph-display')[0].getElementsByTagName('svg')[0];
            graph = graph.cloneNode(true) as SVGSVGElement;

            // reset pan/zoom to initial graphviz default
            const vb = graph.getAttribute('viewBox');
            if (vb !== null) {
                const height = parseFloat(vb.split(' ')[3])
                graph.querySelector('#graph0')?.setAttribute('transform', `translate(4, ${height - 4})`) // 4pt margin

                // try to find and remove the white background which GraphViz generates
                graph.querySelector('#graph0 polygon')?.remove();
                downloadSVG(graph);
            }
        }
        else {
            throw new Error(`ref element is null or has no current element`);
        }
        return;
    }

    back = () => {
        this.props.onBack();
    }

    fwd = () => {
        this.props.onFwd();
        return;
    }

    render() {
        console.log('*** Render InteractiveGrph')
        const intl = this.props.intl;
        const options = this.state.options;


        /**
         * compute label values and stepsize for sliders
         */
        let label_max = maxSliderValue(this.state.simpleGraph);

        const magnitude = Math.pow(10, Math.floor(Math.log10(label_max)));
        label_max = Math.ceil(label_max / magnitude) * magnitude;
        const tick = Math.max((label_max / (magnitude / 2) <= 10 ? magnitude / 2 : magnitude), 1);
        const labelValues = [1];
        let labelValue = tick;
        while (labelValue <= label_max) {
            labelValues.push(labelValue);
            labelValue += tick;
        }

        let stepSize = Math.max(1, tick / 5);
        if (labelValues.length <= 8) {
            stepSize = Math.max(1, tick / 10);
        }

        /**
         * compute amount of cases filtered
         */
        const minEdgeTraversals: number = this.state.options.minEdgeTraversals;
        const minVertexTraversals: number = this.state.options.minVertexTraversals;

        /**
         * set graph display
         */
        let display: React.ReactElement;
        if (this.state.simpleGraph.edges.length > 512) {
            display = <div id="complex">
                <Callout
                    intent="warning"
                    title={intl.formatMessage({
                        id: "view.complexity_warning.title",
                        defaultMessage: "Warning",
                        description: "Whatever translates to 'Warning'"
                    })}>
                    {intl.formatMessage({
                        id: "view.complexity_warning",
                        defaultMessage: "Data too complex. Choose variant(s) or apply more filtering.",
                        description: "Complexity warning if data is too complex"
                    })}
                </Callout>
            </div>
        }
        else {
            display = <GraphDisplay
                graph={this.state.simpleGraph}
                options={defaults}
                className="graph-display"
                nodeMouseEnter={this.mouseEntersNode}
                nodeMouseLeave={this.mouseLeavesNode}
                edgeMouseClick={this.clickOnEdge}
                nodeMouseClick={this.clickOnNode}
                mode={this.state.options.mode}
            />
        }

        let nodePopOverContent: React.ReactElement = <></>
        if (this.state.popover.isOpen) {
            const simpleGraph = this.state.simpleGraph;
            const filteredGraph = simpleGraph.source;
            if (filteredGraph !== undefined) {
                const baseGraph = filteredGraph.source;
                const simpleOccurence = simpleGraph.eventOccurances(this.state.popover.eventId);
                const filteredOccurence = filteredGraph.eventOccurances(this.state.popover.eventId);
                const baseOccurence = baseGraph?.eventOccurances(this.state.popover.eventId);

                let msg: string;
                if (simpleOccurence === filteredOccurence) {
                    if (filteredOccurence === baseOccurence) {
                        msg = intl.formatMessage({
                            id: "interactgr.nodepopup.nochange",
                            defaultMessage: "Occurs {baseOccurence} times. No filters or simplification applied.",
                        }, { baseOccurence })
                    }
                    else {
                        msg = intl.formatMessage({
                            id: "interactgr.nodepopup.filtered",
                            defaultMessage: "Filtered to {filteredOccurence} times, all occurences shown. Unfiltered occurence is {baseOccurence}."
                        }, { filteredOccurence, baseOccurence })
                    }
                }
                else if (filteredOccurence === baseOccurence) {
                    msg = intl.formatMessage({
                        id: "interactgr.nodepopup.simplified",
                        defaultMessage: "Simplified to {simpleOccurence} occurences, no filters applied. Unfiltered occurence ist {baseOccurence}.",
                    }, { simpleOccurence, baseOccurence })
                }
                else {
                    msg = intl.formatMessage({
                        id: "interactgr.nodepopup.simplifiedAndFiltered",
                        defaultMessage: "Simplified to {simpleOccurence} of {filteredOccurence} filtered occurences. Unfiltered occurence ist {baseOccurence}.",
                    }, { simpleOccurence, filteredOccurence, baseOccurence })
                }
                if (baseGraph !== undefined) {
                    nodePopOverContent =
                        <Card style={{ maxWidth: "24em" }} compact={true}>
                            <p>{msg}</p>
                        </Card>
                }

            }
        }




        const numberOfFilteredCases = this.state.graph.casesInfo.length - this.state.simpleGraph.casesInfo.length;
        return <div
            className="interactive-graph"
            ref={this.graphRef}
        >
            <Navbar>
                <NavbarGroup>
                    <div>
                        <FormattedMessage
                            id="interactgr.history"
                            defaultMessage="Selection History"
                            description="Label for case selection history before the back / fwd buttons."
                        />
                    </div>
                    <ButtonGroup >
                        <Button
                            disabled={!this.props.backPossible}
                            onClick={this.back}
                            minimal={true}
                            icon="undo"
                        />
                        <Button
                            disabled={!this.props.fwdPossible}
                            onClick={this.fwd}
                            minimal={true}
                            icon="redo"
                        />
                        <Divider />
                    </ButtonGroup>
                    <Popover
                        minimal={true}
                        position={Position.BOTTOM_LEFT}
                        hasBackdrop={true}
                        content={
                            <Menu>
                                <MenuItem
                                    className={Classes.MINIMAL}
                                    text={intl.formatMessage({
                                        id: "intactgr.dl_svg",
                                        defaultMessage: "Download Graph SVG",
                                        description: "Label of a menu button to download the SVG file of a shown graph."
                                    })}
                                    onClick={this.downloadGraphSVG.bind(this)}
                                    disabled={!options.serverAvailable}
                                />
                                <MenuItem
                                    className={Classes.MINIMAL}
                                    text={intl.formatMessage({
                                        id: "intactgr.dl_cases",
                                        defaultMessage: "Download selected cases .CSV",
                                        description: "Label of a menu button to download a CSV of the selected cases."
                                    })}
                                    onClick={this.downloadSelectedCases}
                                    disabled={!options.serverAvailable}
                                />
                            </Menu>
                        }
                    >
                        <Button
                            className={Classes.MINIMAL}
                            icon="import"
                            text={intl.formatMessage({
                                id: "intactgr.dl",
                                defaultMessage: "Downloads",
                                description: "Label of a menu button for a Downloads dropdown menu."
                            })}
                            disabled={!options.serverAvailable}
                        />
                    </Popover>
                    <Button
                        className={Classes.MINIMAL}
                        onClick={this.props.invertCaseSelection}
                        minimal={true}
                        text={intl.formatMessage({
                            id: "intactgr.invert_select",
                            defaultMessage: "Invert selection",
                            description: "Label of a menu button to invert case selection."
                        })}
                    />
                </NavbarGroup>
                <NavbarGroup className="bp5-align-center">
                    <h4>
                        <FormattedMessage
                            id="intactgr.datasize"
                            defaultMessage="{cases} cases with {events} events selected."
                            description="Message how many cases and events are selected."
                            values={{
                                cases: this.state.graph.casesInfo.length.toLocaleString(intl.locale),
                                events: (this.state.graph.properties.eventCount).toLocaleString(intl.locale),
                            }}
                        />
                        {numberOfFilteredCases > 0 &&
                            <span className='filtered'>
                                <FormattedMessage
                                    id="intactgr.filteredsize"
                                    defaultMessage=" {filtered} cases hidden, {shown} shown."
                                    description="Message how many cases and events are selected."
                                    values={{
                                        filtered: (this.state.graph.casesInfo.length - this.state.simpleGraph.casesInfo.length).toLocaleString(intl.locale),
                                        shown: this.state.simpleGraph.casesInfo.length.toLocaleString(intl.locale)
                                    }}
                                />
                            </span>
                        }
                    </h4>
                </NavbarGroup>
                <NavbarGroup align={Alignment.RIGHT}>
                    <GraphInfo
                        graph={this.state.simpleGraph}
                        mode={this.state.options.mode}
                        handleModeChange={this.handleModeChange}
                        handleEdgeDataDisplay={this.handleEdgeDataDisplay}
                        handleVertexDataDisplay={this.handleVertexDataDisplay}
                    />
                </NavbarGroup>
            </Navbar>
            <div className="graph-wrapper">
                <div className="sliders" style={{ zIndex: 10 }}>
                    {label_max > 4 &&
                        <Card className="slider-wrapper min-edge-slider" elevation={3}>
                            <Label>
                                <Icon icon="arrow-right" iconSize={16} style={{ "color": "#0B91DA" }} />
                                <FormattedMessage
                                    id="intactgr.avt"
                                    defaultMessage="Activities"
                                    description="Label for 'Activities' label for filter slider."
                                />
                            </Label>
                            <Slider
                                min={1}
                                max={label_max}
                                stepSize={stepSize}
                                labelValues={labelValues}
                                onChange={this.updateMinEdgeTraversals}
                                onRelease={this.releaseSliderButton}
                                value={Math.min(label_max, minEdgeTraversals)}
                                vertical={true}
                            />
                        </Card>
                    }
                    {label_max > 4 &&
                        <Card className="slider-wrapper min-vtx-slider" elevation={3}>
                            <Label>
                                <Icon icon="symbol-rectangle" size={16} style={{ color: '#008971' }} />
                                <FormattedMessage
                                    id="intactgr.evt"
                                    defaultMessage="Events"
                                    description="Label for Events filter slider."
                                />
                            </Label>
                            <Slider
                                min={1}
                                max={label_max}
                                stepSize={stepSize}
                                labelValues={labelValues}
                                onChange={this.updateMinVertexTraversals}
                                onRelease={this.releaseSliderButton}
                                value={Math.min(label_max, minVertexTraversals)}
                                vertical={true}
                            />
                        </Card>
                    }
                </div>
                {display}
                <div ref={this.popOverRef}
                    style={{
                        position: "absolute",
                        height: `0px`,
                        width: `0px`,
                        top: `${this.state.popover.top}px`,
                        left: `${this.state.popover.left}px`,
                    }}
                >
                    <Popover
                        content={nodePopOverContent}
                        isOpen={this.state.popover.isOpen}
                        interactionKind={PopoverInteractionKind.HOVER}
                        position={Position.RIGHT}
                        usePortal={false} // avoids slow closing of Popover
                        modifiers={{ arrow: { enabled: false } }}
                    >
                        <div
                            style={{
                                height: `0px`,
                                width: `0px`,
                            }}
                        />
                    </Popover>
                </div>
            </div>
            <Button
                className={"btn-options"}
                icon="cog"
                onClick={this.openOptions}
            />

            <Dialog
                icon="cog"
                title={intl.formatMessage({
                    id: "intactgr.options",
                    defaultMessage: "Options",
                    description: "Label of a menu button that openes an options dialog and the title of that dialog."
                })}
                onClose={this.closeOptions}
                isOpen={this.state.optionsOpen}
            >
                <Card>
                    <Checkbox
                        checked={options.integrate}
                        onChange={this.handleIntegrateBeforeSimplification}
                        label={intl.formatMessage({
                            id: "intactgr.intgr_bfr_simplf",
                            defaultMessage: "Integrate before simplification.",
                            description: "Option label to control graph integration before simplification."
                        })}
                    />
                    <Checkbox
                        checked={options.singleFinish && this.state.options.integrate}
                        onChange={this.handleSingleFinishChange}
                        disabled={!this.state.options.integrate}
                        label={intl.formatMessage({
                            id: "intactgr.intgr_fnsh_nds",
                            defaultMessage: "Integrate finish nodes to one.",
                            description: "Option label to control integration of all finish nodes"
                        })}
                    />
                    <Checkbox
                        checked={options.removeEmptyElements}
                        onChange={this.handleRemoveEmptyElements}
                        label={intl.formatMessage({
                            id: "intactgr.remove_empty_elements",
                            defaultMessage: "Remove empty elements.",
                            description: "Option label to control removal of empty elements after simplification."
                        })}
                    />
                    <Checkbox
                        checked={options.debug}
                        onChange={this.handleDebugDisplay}
                        label={intl.formatMessage({
                            id: "intactgr.debug",
                            defaultMessage: "Show verbose graph labels.",
                            description: "Option label to show verbose graph labels for debugging purposes."
                        })}
                    />

                    <H5><FormattedMessage
                        id="intactgr.event_selection"
                        defaultMessage="Select shown events"
                        description="Title for Dialog section to select shown events."
                    /></H5>

                    <Checkbox
                        checked={options.eventCategories.includes("i")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("i", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.inf_flw",
                            defaultMessage: "Show information events.",
                            description: "Option label to show information events"
                        })}
                    />
                    <Checkbox
                        checked={options.eventCategories.includes("m")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("m", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.mt_flw",
                            defaultMessage: "Show material events.",
                            description: "Option label to show material events"
                        })}
                    />
                    <Checkbox
                        checked={options.eventCategories.includes("meta")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("meta", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.meta_flw",
                            defaultMessage: "Show meta events.",
                            description: "Option label to show meta events"
                        })}
                    />
                    <Checkbox
                        checked={options.eventCategories.includes("aux")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.showEventCategory("aux", event.target.checked);
                        }}
                        label={intl.formatMessage({
                            id: "intactgr.aux_flw",
                            defaultMessage: "Show auxilary events.",
                            description: "Option label to show auxiliary events"
                        })}
                    />
                </Card>
            </Dialog>
        </div >
    }
}
export default injectIntl(InteractiveGraph); // inject 'intl' prop into component