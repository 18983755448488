import SortedArray from "collections/sorted-array.js";
import assert from "assert";
import GraphElement from "./graphelement.js";
import Vertex, { VertexData } from "./vertex.js";
import Edge, { EdgeData } from "./edge.js";

export type GraphData = { vertices: VertexData[], edges: EdgeData[] };

export default class Graph<V extends Vertex = Vertex, E extends Edge = Edge> {
    declare ["constructor"]: typeof Graph;

    static edgesEqual = Edge.equals;
    static compareEdges = Edge.compare;
    static createEdge = Edge.createFromData;

    static verticesEqual = Vertex.equals;
    static compareVertices = Vertex.compare;
    static createVertex = Vertex.createFromData;

    static validate(data: unknown): data is GraphData {
        return typeof data === "object" &&
            data !== null &&
            "vertices" in data &&
            Array.isArray(data.vertices) &&
            data.vertices.every((v: unknown) => Vertex.validate(v)) &&
            "edges" in data &&
            Array.isArray(data.edges) &&
            data.edges.every((e: unknown) => Edge.validate(e))
    }

    edges!: SortedArray<E>;
    vertices!: SortedArray<V>;
    verticesById!: SortedArray<V>;
    debug!: boolean;

    constructor() {
        this._initGraph();
    }

    _initGraph() {
        this.edges = new SortedArray<E>([], this.constructor.edgesEqual, this.constructor.compareEdges);
        this.vertices = new SortedArray<V>([], this.constructor.verticesEqual, this.constructor.compareVertices);
        this.verticesById = new SortedArray<V>([], GraphElement.equals, GraphElement.compare);
        this.debug = false; // set to true for debugging edges & vertices (more verbose labels)
    }

    initFromData(data: unknown): data is GraphData {
        if (Graph.validate(data)) {
            this._initGraph();
            data.vertices.forEach((vertex_data) => {
                const vertex = this.constructor.createVertex(vertex_data, this); // create with same id, don't use new() therefore!
                this.addVertex(vertex as V);
            });
            data.edges.forEach((edge_data) => {
                const edge = this.constructor.createEdge(edge_data, this);
                this.addEdge(edge as E);
            });
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject(): GraphData {
        return {
            vertices: this.vertices.map<VertexData>((vertex) => vertex.__serializableObject()),
            edges: this.edges.map<EdgeData>((edge) => edge.__serializableObject())
        }
    }

    copy(copy: Graph | null = null) {
        if (copy === null) {
            copy = Object.create(this) as Graph;
        }
        copy._initGraph();

        this.vertices.forEach((vertex) => {
            copy!.addVertex(vertex.copy(Object.create(vertex) as Vertex));
        });

        this.edges.forEach((edge) => {
            const edgeCopy = Object.create(edge);
            edgeCopy.graph = copy;
            copy!.addEdge(edge.copy(edgeCopy as Edge));
        });
    }

    toJSON(bReadable: boolean = false) {
        const result = JSON.stringify(this.__serializableObject(), undefined, bReadable ? 2 : undefined);
        return result;
    }

    addVertex(vertex: V) {
        vertex.setGraph(this);
        this.vertices.add(vertex);
        this.verticesById.add(vertex);
    }

    removeVertex(vertex: V) {
        let result: boolean;
        if (vertex.incoming.length + vertex.outgoing.length === 0) {
            this.verticesById.delete(vertex);
            result = this.vertices.delete(vertex);
            vertex.removeGraph();
        } else {
            result = false;
        }
        assert(
            result !== false,
            `Cannot remove vertex ${vertex.__id}, it still has edges: incoming:${vertex.incoming.length} outgoing:${vertex.outgoing.length}.`
        );
        return result;
    }

    addEdge(edge: E) {
        this.edges.add(edge);
        edge.setGraph(this);
        edge.from.addOutgoing(edge);
        edge.to.addIncoming(edge);
        return true;
    }

    removeEdge(edge: E) {
        edge.from.removeOutgoing(edge);
        edge.to.removeIncoming(edge);
        const result = this.edges.delete(edge);
        edge.removeGraph();
        assert(result, `Cannot remove edge ${edge.__id}.`);
        return result;
    }

    /**
     *
     * @param {Array|SortedArray} vtxs
     * @todo The chosen module structure loads multiple instances
     * of SortedArray (as an example). We need to find a way to
     * avoid that and make sure that vtxs instanceof SortedArray here
     * would return true even if vtxs were created in a different
     * module.
     * For now comparing against Array.
     */
    makeSortedVtxArray(vtxs: Array<Vertex> | SortedArray<Vertex>): SortedArray<Vertex> {
        if (vtxs instanceof Array) {
            vtxs = new SortedArray<Vertex>(
                [...vtxs], // copy array as SortedArray uses the original one and re-orders it
                this.constructor.verticesEqual,
                this.constructor.compareVertices
            );
        }
        return vtxs;
    }

    makeSortedEdgeArray(edges: Array<Edge> | SortedArray<Edge>): SortedArray<Edge> {
        if (edges instanceof Array) {
            edges = new SortedArray([...edges],
                this.constructor.edgesEqual,
                this.constructor.compareEdges
            );
        }
        return edges;
    }

    visit(
        startVtxs: Array<Vertex>,
        forward = true,
        vtxIgn: Array<Vertex> | SortedArray<Vertex> = [],
        edgeIgn: Array<Edge> | SortedArray<Edge> = []
    ) {
        if (!(startVtxs instanceof Array)) {
            startVtxs = [startVtxs];
        }
        const vtxIgnSorted: SortedArray<Vertex> = this.makeSortedVtxArray(vtxIgn);
        const edgeIgnSorted: SortedArray<Edge> = this.makeSortedEdgeArray(edgeIgn);

        const workStack: Array<Vertex> = startVtxs.filter((vtx) => vtx.graph === this && !vtxIgnSorted.has(vtx));
        /**
         * loop until no more visitations to make
         */
        const visited = [];
        while (workStack.length) {
            const vertex = workStack.pop()!;
            delete vertex.attributes._onStack;
            vertex.attributes._visited = true;
            visited.push(vertex);
            (forward ? vertex.outgoing : vertex.incoming).forEach((e) => {
                if (!edgeIgnSorted.has(e)) {
                    const examinedVtx = forward ? e.to : e.from;
                    if (
                        !examinedVtx.attributes._visited &&
                        !vtxIgnSorted.has(examinedVtx) &&
                        !examinedVtx.attributes._onStack
                    ) {
                        workStack.push(examinedVtx);
                        examinedVtx.attributes._onStack = true;
                    }
                }
            });
        }
        visited.forEach((v) => delete v.attributes._visited);
        return visited;
    }

    // isConnected(vtxIgn: Array<Vertex> | SortedArray<Vertex> = [], edgeIgn: Array<Edge> | SortedArray<Edge> = []) {
    //     let vtxIgnSorted: SortedArray<Vertex> = this.makeSortedVtxArray(vtxIgn);
    //     let edgeIgnSorted: SortedArray<Edge> = this.makeSortedEdgeArray(edgeIgn);

    //     /**
    //      * determine start vertices, reset visitation marks and initialize
    //      * working stack
    //      */
    //     let startVertices: SortedArray<V> = this.vertices.filter((v) => v.incoming.length === 0);
    //     let visited = this.visit(startVertices.array, true, vtxIgnSorted, edgeIgn);
    //     if (visited.length + vtxIgn.length !== this.vertices.length) {
    //         return false;
    //     }

    //     /**
    //      * determine finish vertices, reset visitation marks and initialize
    //      * working stack
    //      */
    //     var finishVertices = this.vertices.filter((v) => v.outgoing.length === 0);
    //     visited = this.visit(finishVertices.array, false, vtxIgn, edgeIgn);
    //     if (visited.length + vtxIgn.length !== this.vertices.length) {
    //         return false;
    //     }
    //     return true;
    // }
}
