import GraphElement, { GraphElementData } from "./graphelement.js";

export type GraphElementAttributes = { [key: string]: string | number | boolean };
export type AttributedGraphElementData = GraphElementData & { attributes: GraphElementAttributes }

export default abstract class AttributedGraphElement extends GraphElement {

    static validate(data: unknown): data is AttributedGraphElementData {
        return (typeof data === "object") &&
            (data !== null) &&
            ("attributes" in data) &&
            (typeof data.attributes === "object") &&
            (data.attributes !== null) &&
            Object.values(data.attributes).every((value:unknown) => {
                ['string','number','boolean'].includes(typeof value);
            });
    }

    attributes!: GraphElementAttributes;

    constructor(attributes: GraphElementAttributes) {
        super();
        this._initAttributedGraphElement(attributes);
    }

    _initAttributedGraphElement(attributes: GraphElementAttributes) {
        this.attributes = attributes;
    }

    initFromData(data: unknown): data is AttributedGraphElementData {
        if (super.initFromData(data) &&
            AttributedGraphElement.validate(data)) {
            this._initAttributedGraphElement(data.attributes);
            return true;
        }
        throw new Error(`${JSON.stringify(data)} does not comply with ${this.constructor.name}`);
    }

    __serializableObject(): AttributedGraphElementData {
        return { ...super.__serializableObject(), attributes: this.attributes };
    }

    copy(copy: AttributedGraphElement) {
        super.copy(copy);
        copy._initAttributedGraphElement({ ...this.attributes }); // call #__init for AttributedGraphElement, not any derived class
        return copy;
    }
}
