import React, { useEffect, useState } from 'react';
import { Dialog, Classes, Card } from '@blueprintjs/core';
import Project from '@insight/common/interface/Project.js';
import { sendRequest } from '../../classes/Request.js';

export interface DatasetSelectionDialogProps {
    onClose: () => void;
    onSelectDataset: (p: Project) => void;
}

export function DatasetSelectionDialog(props: DatasetSelectionDialogProps) {
    const [projects, setProjects] = useState<Project[] | null>(null);
    useEffect(() => {
        console.log(`*** Data Selection loader`)
        sendRequest<Project[], void>("/projects").then((p => {
            setProjects(p);
        })).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <Dialog
            title="Datensatz wählen"
            onClose={props.onClose}
            isOpen={true}
        >
            <div className={Classes.DIALOG_BODY}>
                {projects === null
                    ? <div>Loading projects ... </div>
                    : <div style={{ "display": "grid", "gridTemplateColumns": "repeat(2, 1fr)", "gap": "0.75rem 1rem" }}>
                        {projects.map(p =>
                            <Card key={p.name} interactive={true} compact={true} onClick={() => props.onSelectDataset(p)}>
                                <h3>{p.name}</h3>
                            </Card>
                        )}
                    </div>
                }
            </div>
        </Dialog>
    )
}
