import React, { useContext, PropsWithChildren } from "react";
import { useActor } from "@xstate/react";
import LoginDialog from "./LoginDialog.js";


import { authService } from "./AuthenticationStateMachine.js";
import { MainEntranceProps } from "../../MainEntrance.js";

interface AppProps extends PropsWithChildren {
    loginComponent: React.ComponentType<MainEntranceProps>
}
/** @todo: understand the involved types bettter */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContext = React.createContext<React.ComponentType<any> | null>(null);

export const AuthProvider = (props: AppProps) => {
    return <AuthContext.Provider value={props.loginComponent}>{props.children}</AuthContext.Provider>;
};

/**
 * A PrivateRoute shows the target component if a location has logged in,
 * i.e. if the localStorage has a location. The localStorage is dependend
 * on the domain and the protocol, so it will not be easily accessible by others,
 * however it is NOT 100% save (XSS might be used to tackle it).
 *
 * If the location is not found in the localStorage, the PrivateRoute redirects
 * to the /login path.
 */
export const Protector = <PropType extends React.JSX.IntrinsicAttributes>({ /** @todo: understand why PropType needs to extend IntrisicAttributes */
    component: Component,
    props,
}: {
    component: React.ComponentType<PropType>;
    props: PropType;
}) => {
    const [state, send] = useActor(authService);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const LoginComponent = useContext(AuthContext)!

    return (
        <>
            <Component {...props} />
            {!state.matches("confirmed") && (
                <LoginDialog isOpen="true">
                    <LoginComponent send={send} state={state}></LoginComponent>
                </LoginDialog>
            )}
        </>
    );
};
