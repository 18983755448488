import {
    AuthError400Response,
    AuthResponse,
    Credentials,
    isAuth200Response,
    isAuthError400Response,
    isAuthenticationResponse
} from "@insight/common/interface/authentication.js";
import { assign, createMachine, interpret } from "xstate";
import {
    removeRequestModificator,
    removeResponseHandler,
    sendJSONRequest,
    setRequestModificator,
} from "../../../classes/Request.js";

import log from '../../Logger.js';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AuthenticationContext extends Partial<AuthError400Response> {
    response: any;
}

export type AuthenticationEvent =
    | ({ type: "CREDENTIALS_PROVIDED"; } & Credentials)
    | { type: "LOG_OUT" }
    | {
        type: "done.invoke.postCredentials";
        data: AuthResponse;
    };

const requestHookName = "authentication";

const machine = createMachine(
    {
        predictableActionArguments: true,
        id: "authentication",
        tsTypes: {} as import("./AuthenticationStateMachine.typegen.d.ts").Typegen0,
        schema: {
            context: {} as AuthenticationContext,
            events: {} as AuthenticationEvent,
        },
        initial: "initial",
        context: {response: null},
        states: {
            /** initial transient state to check existance of jwt */
            initial: {
                always: [{ target: "confirmed", cond: "jwtAvailable" }, { target: "none" }],
            },

            /** not authenticated */
            none: {
                on: {
                    CREDENTIALS_PROVIDED: "requesting",
                },
            },

            /** request sent and waiting for confimation */
            requesting: {
                invoke: {
                    id: "postCredentials",
                    src: "postCredentials",
                    onDone: [
                        {
                            target: "confirmed",
                            cond: "checkJwt",
                            actions: "storeJwt",
                        },
                        {
                            target: "rejected",
                        },
                    ],
                    onError: {
                        target: "error",
                        actions: assign(
                            (_, event) => {
                                console.log(event);
                                return event.data;
                            },
                        ),
                    },
                },
            },

            /** authentication confirmed */
            confirmed: {
                on: {
                    LOG_OUT: {
                        actions: "removeJwt",
                        target: "none",
                    },
                },
            },

            /** authentication credentials rejected */
            rejected: {
                entry: "rejectCredentials",
                on: {
                    CREDENTIALS_PROVIDED: "requesting",
                },
            },

            /** error while requesting authentication */
            error: {
                on: {
                    CREDENTIALS_PROVIDED: "requesting",
                },
            },
        },
    },
    {
        guards: {
            /**
             * Check if a JWT is available in the local storage
             * @param {*} context
             * @param {*} event
             * @returns
             */
            jwtAvailable: () => {
                let result = false;
                const jwt = localStorage.getItem("jwt");
                if (jwt) {
                    setRequestHooks(jwt);
                    result = true;
                }
                return result;
            },

            /**
             * Check if a JWT was sent with the "event"
             * @param {*} context
             * @param {*} event
             * @returns
             */
            checkJwt: (context, event) => {
                return isAuth200Response(event.data);
            },
        },

        actions: {
            storeJwt: (context, event) => {
                const response: AuthResponse = event.data;
                if (isAuth200Response(response)) {
                    localStorage.setItem("jwt", response.data.jwt);
                    setRequestHooks(response.data.jwt);
                }
            },

            removeJwt: () => {
                localStorage.removeItem("jwt");
                removerequestHooks();
            },

            rejectCredentials: assign(
                (context, event) => {
                    console.log(event);
                    if (isAuthError400Response(event.data)) {
                        return event.data;
                    }
                    else {
                        throw new Error("Invalid response data");
                    }
                },
            ),
        },

        services: {
            postCredentials: (context, event) => {
                const credentials = { userid: event.userid, pw: event.pw }
                // const { ...credentials } = event;
                return sendJSONRequest("login", credentials, { method: "POST" })
                    .then(result => {
                        if (isAuthenticationResponse(result)) {
                            return result;
                        }
                        else {
                            throw new Error(`Authentication response expected`);
                        }
                    })
            },
        },
    }
);

export const authService = interpret(machine).onTransition(state => {
    log.debug(`+++ authentication: ${state.value}`);
}).onEvent(event => {
    log.debug(`+++ auth event: ${JSON.stringify(event)}`);
})
    .start();

function setRequestHooks(jwt: string) {
    setRequestModificator(requestHookName, (reqInit) => {
        reqInit.headers = {
            ...reqInit.headers,
            Authorization: "Bearer " + jwt,
        };
    });
}

function removerequestHooks() {
    removeRequestModificator(requestHookName);
    removeResponseHandler(requestHookName);
}


